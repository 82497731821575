import React, { Component } from 'react';

class ViajesEnCurso extends Component {
    render() {
        return  (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Viajes En Curso</h1>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus earum corporis obcaecati, illo ducimus sunt quaerat nemo aliquid temporibus voluptate sapiente ipsum. Nostrum vel ut adipisci, omnis fugit quisquam? Eius.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViajesEnCurso;
