import React, { createRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Growl } from 'primereact/growl';
import { Card}  from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';

import { ROUTES, PATHS_API, 
  ROLES, EDOS_COGNITO, PATTERN_CONTRASENIA, LINK_TERMINOS } from '../../Utils/constants';
import { cadenas } from '../../Utils/cadenas';
import { APIGATEWAY } from '../../Api/ApiGateway';
import { setUsuarioLogueado, setTokenUsuario } from '../../Utils/gestionSessionStorage';
import logoVwm from '../../Themes/assets/images/logo-vwm.png';
import History from '../../Navigation/History';
import { configuracionAws } from '../../Utils/AWSExports';
import { ItemDropDownInterface } from '../../Interfaces/ItemDropDown.interface';

/**
 * Definir los parámetros del router.
 */
interface RouteParams {
  rol: string;
};

/**
 * Definir las propiedades que recibe el componente.
 */
type Props = RouteComponentProps<RouteParams> & {};

/**
 * Definir el estado del componente.
 */
type State = {
  blocking: boolean;
  usuario: string;
  usuarioCognito: any;
  passwd: string;
  signInRole: ItemDropDownInterface;
  edoUsuario: string;
  newPasswd: string;
  newPasswdConfirm: string;
  contrasenia:boolean;
  contraseniaNueva:boolean;
  contraseniaNuevaConfirm:boolean;
  [key:string]:any;
  codigoReset: string;
  checkedTerminos: boolean;
};

/**
 * Opciones que muestra el Droodown Tipo Usuario.
 */
const opcionesRol: Array<ItemDropDownInterface> = [
  { label: 'User Management', value: ROLES.ADMINUMS },
  { label: 'Administrador', value: ROLES.ADMINSIS },
  { label: 'Transportista Admin', value: ROLES.ADMINTRANSPORTISTA },
  { label: 'Conductor', value: ROLES.CONDUCTOR },
  { label: 'Concesionario', value: ROLES.ADMINDEALER },
  { label: 'Inspector', value: ROLES.INSPECTOR },
  { label: 'Administrador (sólo lectura)', value: ROLES.ADMINREAD },
  { label: 'Seguros', value: ROLES.SEGUROS },
  { label: 'Administrador NSO', value: ROLES.ADMINNSO },
];

/**
 * @class
 * Componente que permite generar la pantalla del login.
 */
class LoginContainer extends React.Component<Props, State> {
  private growlError: any;
  
  /**
   * Inicializar el estado del componente.
   * @param {Props} props Propiedades que recibe el componente.
   */
  constructor(props:Props) {
    super(props);
    this.growlError = createRef(); 
    this.state = {
      blocking: false,
      usuarioCognito: undefined,
      usuario: '',
      passwd: '',
      signInRole: {label:'', value:''},
      edoUsuario: '',
      newPasswd: '',
      newPasswdConfirm: '',
      contrasenia: false,
      contraseniaNueva: false,
      contraseniaNuevaConfirm: false,
      codigoReset: '',
      checkedTerminos: false,
    };
    this.login = this.login.bind(this);
    this.crearFormLogin = this.crearFormLogin.bind(this);
    this.crearFormCambioContrasenia = this.crearFormCambioContrasenia.bind(this);
    this.crearFormResetContrasenia = this.crearFormResetContrasenia.bind(this);
    this.cambiarContrasenia = this.cambiarContrasenia.bind(this);
    this.resetContrasenia = this.resetContrasenia.bind(this);
  }

  /**
   * Consumir el api de los usuarios para obtener
   * la información del usuario que se loguea.
   * @param {string} usuario Nombre del usuario.
   */
  async obtenerInfoUsuario(usuario:string) {
    const body = {
      usuario: usuario
    };
    APIGATEWAY.post(PATHS_API.USUARIOS_API+"/leer", body)
    .then(response => {
      console.log(PATHS_API.USUARIOS_API+"/leer response", response);
        if (Array.isArray(response)) {
          setUsuarioLogueado(response[0]);
          //
          if(this.state.checkedTerminos){
            let body = {
              cont: 'checkedTerminos'
            };
            APIGATEWAY.post(PATHS_API.BITACORA_CREAR, body )
            .then(response => {
                console.log("Bitacora -> crear -> response", response)            
            })
            .catch(error => {
                console.log("Bitacora -> crear -> error: ", error)
            });
          }
          History.replace(ROUTES.DASHBOARD);
        } else {
          this.setState({blocking:false});
          this.showError(cadenas.LOGIN_ERROR_TITULO_SESION, cadenas.LOGIN_ERROR_NO_CARGAR_DATOS);
        }
    })
    .catch(error => {
      console.log(PATHS_API.USUARIOS_API+"/leer error: ", error);
      this.setState({blocking:false});
      this.showError(cadenas.LOGIN_ERROR_TITULO_SESION, cadenas.LOGIN_ERROR_DATOS_INCORRECTOS);
    });
  }

  /**
   * Verificar el acceso del usuario.
   * @param {React.FormEvent} event Evento submit del formulario.
   */
  async login(event:React.FormEvent) {
    event.preventDefault();
    configuracionAws(this.state.signInRole.value);
    this.setState({blocking:true});
    Auth.signIn(this.state.usuario, this.state.passwd)
        .then(usuarioFirmado => {
          console.log("usuarioFirmado", usuarioFirmado);
          console.log("challenge usuario", usuarioFirmado.challengeName)
            if (usuarioFirmado.challengeName !== undefined)
              this.setState({
                blocking: false,
                usuarioCognito: usuarioFirmado,
                edoUsuario: usuarioFirmado.challengeName
              });
            else{
              if(this.state.edoUsuario !== ''){
                this.extraerInfoUsuario(usuarioFirmado);
              }else{
                let rolUser = usuarioFirmado.signInUserSession.accessToken.payload['cognito:groups'][0];
                if(rolUser !== this.state.signInRole.value){
                  this.setState({blocking:false});
                  this.showError(cadenas.LOGIN_ERROR_TITULO_SESION, cadenas.LOGIN_MENSAJE_ERROR_USER_PASS_WRONG);
                  return;
                }else{
                  this.extraerInfoUsuario(usuarioFirmado);
                }
              }
            }
              
        })
        .catch(error => {     
          console.log("LoginContainer -> login -> error", error)
          this.setState({blocking:false});
          var mensajeError = '';
          switch(error.message){
            case "Incorrect username or password.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_USER_PASS_WRONG;
            break;
            case "User does not exist.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_USER_PASS_WRONG;
            break;
            case "User is disabled.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_USER_DISABLED;
            break;
            case "Password reset required for the user":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_PASS_RESET;
            break;
            case "Temporary password has expired and must be reset by an administrator.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_TEMP_PASS_EXPIRED;
            break;
            case "Invalid session for the user, session is expired.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_EXPIRED_SESSION;
            break;
            case "PreAuthentication failed with error User does not exist.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_USER_PASS_WRONG;
            break;
            case "PreAuthentication failed with error User is disabled.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_USER_DISABLED;
            break;
            case "PreAuthentication failed with error Role is disabled or non-existent.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_ROLE_DISABLED;
            break;
            case "PreAuthentication failed with error Company code is not correct.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_INCORRECT_COMPANY_CODE;
            break;
            case "PreAuthentication failed with error Carrier is not correct.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_INCORRECT_CARRIER;
            break;
            case "PreAuthentication failed with error Dealer is not correct.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_INCORRECT_DEALER;
            break;
            case "PreAuthentication failed with error Not authorized user rol.":
              mensajeError = cadenas.LOGIN_MENSAJE_ERROR_ROLE_NOT_AUTHORIZED;
            break;
          }
          this.showError(cadenas.ERROR_TITULO, mensajeError);
        });
  }

  /**
   * Extraer la información que responde cognito.
   * @param {any} usuarioCognito Objeto que responde cognito.
   */
  extraerInfoUsuario(usuarioCognito: any) {
    let signInUserSession = usuarioCognito.getSignInUserSession();
    let idToken;
    if (signInUserSession) {
      idToken = signInUserSession.getIdToken();
      console.log("accessToken", idToken);
      if (idToken) {
        if (idToken.getJwtToken()) {
          setTokenUsuario(idToken);
        }
        this.obtenerInfoUsuario(usuarioCognito.username);
      }
    }
  }

  /**
   * Mostrar notificación de tipo error. 
   * @param {string} title Título de la notificación.
   * @param {string} message Mensaje de la notificación.
   */
  showError(title:string, message:string) {
    this.growlError.current.show({severity: 'error', summary: title, detail: message});
  }

  /**
   * Permitir mostrar el etxto del input tipo password.
   * @param idInputContrasenia Identificador del input.
   */
  mostrarContrasenia(idInputContrasenia:string) {
    this.setState({[idInputContrasenia]: !this.state[idInputContrasenia]});
    const inputContrasenia: HTMLElement | null = document.getElementById(idInputContrasenia);
    inputContrasenia?.setAttribute("type", (this.state[idInputContrasenia] ? "password" : "text"));
  }
  
  /**
   * Realizar el cambio de la contraseña del usuario.
   * @param {React.FormEvent} event Evento submit del formulario.
   */
  cambiarContrasenia(event:React.FormEvent) {
    event.preventDefault();
    if (this.state.newPasswd !== this.state.newPasswdConfirm) {
      this.showError(cadenas.ERROR_TITULO_VALIDACION, cadenas.LOGIN_ERROR_CONTRASENIAS_DIFERENTES);
      return;
    }
    this.setState({blocking:true});
    Auth.completeNewPassword(this.state.usuarioCognito, this.state.newPasswd, {})
    .then(usuarioCambiado => {
      console.log("LoginContainer -> cambiarContrasenia -> usuarioCambiado", usuarioCambiado)      
      this.login(event);
    })
    .catch(error => {     
      console.log("LoginContainer -> cambiarContrasenia -> error", error)
      this.setState({blocking:false});
      this.showError(cadenas.LOGIN_ERROR_TITULO_SESION, cadenas.LOGIN_ERROR_CAMBIO_PASSWORD);
    });
  }

  /**
   * Realizar el reset de la contraseña del usuario.
   * @param {React.FormEvent} event Evento submit del formulario.
   */
  resetContrasenia(event:React.FormEvent) {
    event.preventDefault();
    if (this.state.newPasswd !== this.state.newPasswdConfirm) {
      this.showError(cadenas.ERROR_TITULO_VALIDACION, cadenas.LOGIN_ERROR_CONTRASENIAS_DIFERENTES);
      return;
    }
    configuracionAws(this.state.signInRole.value);
    this.setState({blocking:true});
    Auth.forgotPasswordSubmit(this.state.usuario, this.state.codigoReset, this.state.newPasswd)
    .then(usuarioCambiado => {
      console.log("LoginContainer -> resetContrasenia -> usuarioCambiado", usuarioCambiado)
      this.login(event);
    })
    .catch(error => {     
      console.log("LoginContainer -> resetContrasenia -> error", error)
      this.setState({blocking:false});
      var mensajeError = '';
          switch(error.message){
            case "Username/client id combination not found.":
              mensajeError = cadenas.LOGIN_PASS_ERROR_NO_USER_EXIST;
            break;
            case "Invalid code provided, please request a code again.":
              mensajeError = cadenas.LOGIN_PASS_ERROR_CODE_INVALID_EXPIRED;
            break;
            case "Attempt limit exceeded, please try after some time.":
              mensajeError = cadenas.ATTEMPT_LIMIT_EXCEEDED;
            break;
            default:
              mensajeError = cadenas.LOGIN_PASS_ERROR_GENERAL;
            break;
          }
          this.showError(cadenas.LOGIN_ERROR_TITULO_SESION, mensajeError);
    });
  }

  /**
   * Crear el formulario para realizar el inicio de sesión.
   */
  crearFormLogin() {
    return (
      <div>
        <form onSubmit={this.login}>
          <div style={{textAlign:'center', marginBottom:'10px'}}>
            <h1>{cadenas.LOGIN_TITULO}</h1>
          </div>
          <div className="p-grid">
            <div className="p-col-12">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
                    <InputText placeholder={cadenas.LOGIN_FORM_USUARIO} 
                      required={true}
                      value={this.state.usuario}
                      autoComplete="off"
                      id="usuarioLogin"
                      onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        this.setState({usuario: (event.target as HTMLInputElement).value});
                      }}/>
                </div>
            </div>
            <div className="p-col-12">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon"><i className="pi pi-lock" /></span>
                    <Password id="contrasenia" feedback={false} 
                      required={true} placeholder={cadenas.LOGIN_FORM_PASSWD}
                      value={this.state.passwd}
                      autoComplete="off"
                      onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        this.setState({passwd: (event.target as HTMLInputElement).value});
                      }}/>
                      <span className="p-inputgroup-addon" style={{"cursor":"pointer"}}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            this.mostrarContrasenia("contrasenia");
                        }}>
                        {this.state.contrasenia ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                    </span>
                </div>
            </div>
            <div className="p-col-12">
              <Dropdown 
                  value={this.state.signInRole.value} options={opcionesRol} 
                  id="rol"
                  onChange={(e) => {
                      let signInRoleSeleccionado = opcionesRol.find(or => or.value === e.value);
                      this.setState({signInRole: signInRoleSeleccionado!})
                  }} required={true}
                  placeholder={cadenas.LOGIN_FORM_SELECT}
              />
            </div>
            <div className="p-col-12" style={{textAlign:'center'}}>
              <Button label={cadenas.LOGIN_FORM_INGRESAR} 
                className="p-button p-button-rounded"
                id="ingresar"
                icon="pi pi-sign-in" 
                iconPos="left"
                style={{
                  width:'150px',
                }} />
            </div>
          </div>
        </form>
        <div className="p-col-12" style={{textAlign:'right'}}>
          <Button label={cadenas.LOGIN_BTN_RECUPERAR_CONTRASENIA} 
            className="p-button p-button-rounded p-button-secondary"
            onClick={(e) => {
              this.setState({
                edoUsuario: EDOS_COGNITO.RESET
              });
            }} />
        </div>
      </div>
    );
  }

  /**
   * Crear el formulario para realizar el cambio de contraseña.
   */
  crearFormCambioContrasenia() {
    return (
      <form onSubmit={this.cambiarContrasenia}>
        <div style={{textAlign:'center', marginBottom:'10px'}}>
          <h3>{cadenas.LOGIN_TITULO_CAMBIO_CONTRASENIA}</h3>
        </div>
        <div className="p-grid">
          <div className="p-col-12">
              <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
                  <InputText placeholder={cadenas.LOGIN_FORM_USUARIO} 
                    required={true} disabled={true}
                    value={this.state.usuario}
                    autoComplete="off"
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      this.setState({usuario: (event.target as HTMLInputElement).value});
                    }}/>
              </div>
          </div>
          <div className="p-col-12">
              <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"><i className="pi pi-lock" /></span>
                  <Password id="contraseniaNueva" feedback={false} 
                    required={true} pattern={PATTERN_CONTRASENIA}
                    placeholder={cadenas.LOGIN_FORM_NEW_PASSWD} value={this.state.newPasswd}
                    tooltip={cadenas.FORMULARIO_REGISTRO_TOOLTIP_CONTRASENIA} tooltipOptions={{event: 'focus'}}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      this.setState({
                        newPasswd: (event.target as HTMLInputElement).value,
                        passwd: (event.target as HTMLInputElement).value
                      });
                    }}/>
                    <span className="p-inputgroup-addon" style={{"cursor":"pointer"}}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                          this.mostrarContrasenia("contraseniaNueva");
                      }}>
                      {this.state.contraseniaNueva ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                    </span>
              </div>
          </div>
          <div className="p-col-12">
              <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"><i className="pi pi-lock" /></span>
                  <Password id="contraseniaNuevaConfirm" feedback={false} 
                    required={true} pattern={PATTERN_CONTRASENIA}
                    placeholder={cadenas.LOGIN_FORM_NEW_PASSWD_CONFIRM} value={this.state.newPasswdConfirm}
                    tooltip={cadenas.FORMULARIO_REGISTRO_TOOLTIP_CONTRASENIA} tooltipOptions={{event: 'focus'}}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      this.setState({newPasswdConfirm: (event.target as HTMLInputElement).value});
                    }}/>
                    <span className="p-inputgroup-addon" style={{"cursor":"pointer"}}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                          this.mostrarContrasenia("contraseniaNuevaConfirm");
                      }}>
                
                      {this.state.contraseniaNuevaConfirm ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                    </span>
              </div>
          </div>

          <div className="p-col-12">
              <div className="p-inputgroup">
                <Checkbox 
                  onChange={e => 
                    this.setState({checkedTerminos: e.checked})} 
                  checked={this.state.checkedTerminos}>
                </Checkbox>
                <label style={{paddingLeft:10}}>Acepto </label>
                <a href={LINK_TERMINOS}
                  target="_blank" rel="noopener noreferrer" style={{paddingLeft:5}}>
                        Terminos de Servicio
                </a>
              </div>
          </div>

          <div className="p-col-12" style={{
              textAlign:'center'
            }}>
            <Button label={cadenas.LOGIN_FORM_CAMBIAR} 
              className="p-button p-button-rounded"
              icon="pi pi-sign-in" 
              iconPos="left"
              disabled={!this.state.checkedTerminos}
              style={{
                width:'150px',
              }} />
          </div>
        </div>
      </form>
    );
  }

  /**
   * Crear el formulario para realizar el reset de contraseña.
   */
  crearFormResetContrasenia() {
    return (
      <form onSubmit={this.resetContrasenia}>
        <div style={{textAlign:'center', marginBottom:'10px'}}>
          <h3>{cadenas.LOGIN_TITULO_RESET_CONTRASENIA}</h3>
        </div>
        <div className="p-grid">
          <div className="p-col-12">
            <Dropdown 
                value={this.state.signInRole.value} options={opcionesRol} 
                onChange={(e) => {
                    let signInRoleSeleccionado = opcionesRol.find(or => or.value === e.value);
                    this.setState({signInRole: signInRoleSeleccionado!})
                }} required={true}
                placeholder={cadenas.LOGIN_FORM_SELECT}
            />
          </div>
          <div className="p-col-12">
              <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
                  <InputText placeholder={cadenas.LOGIN_FORM_USUARIO} 
                    required={true}
                    value={this.state.usuario}
                    autoComplete="off"
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      this.setState({usuario: (event.target as HTMLInputElement).value});
                    }}/>
              </div>
          </div>
          <div className="p-col-12">
              <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"><i className="pi pi-lock" /></span>
                  <Password id="contraseniaNueva" feedback={false} 
                    required={true} pattern={PATTERN_CONTRASENIA}
                    placeholder={cadenas.LOGIN_FORM_NEW_PASSWD} value={this.state.newPasswd}
                    tooltip={cadenas.FORMULARIO_REGISTRO_TOOLTIP_CONTRASENIA} tooltipOptions={{event: 'focus'}}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      this.setState({
                        newPasswd: (event.target as HTMLInputElement).value,
                        passwd: (event.target as HTMLInputElement).value
                      });
                    }}/>
                    <span className="p-inputgroup-addon" style={{"cursor":"pointer"}}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                          this.mostrarContrasenia("contraseniaNueva");
                      }}>
                      {this.state.contraseniaNueva ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                    </span>
              </div>
          </div>
          <div className="p-col-12">
              <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"><i className="pi pi-lock" /></span>
                  <Password id="contraseniaNuevaConfirm" feedback={false} 
                    required={true} pattern={PATTERN_CONTRASENIA}
                    placeholder={cadenas.LOGIN_FORM_NEW_PASSWD_CONFIRM} value={this.state.newPasswdConfirm}
                    tooltip={cadenas.FORMULARIO_REGISTRO_TOOLTIP_CONTRASENIA} tooltipOptions={{event: 'focus'}}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      this.setState({newPasswdConfirm: (event.target as HTMLInputElement).value});
                    }}/>
                    <span className="p-inputgroup-addon" style={{"cursor":"pointer"}}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                          this.mostrarContrasenia("contraseniaNuevaConfirm");
                      }}>
                      {this.state.contraseniaNuevaConfirm ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                    </span>
              </div>
          </div>
          <div className="p-col-12">
              <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"><i className="pi pi-key" /></span>
                  <InputText placeholder={cadenas.LOGIN_FORM_COD_RESET} 
                    required={true}
                    value={this.state.codigoReset}
                    autoComplete="off"
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      this.setState({codigoReset: (event.target as HTMLInputElement).value});
                    }}/>
              </div>
          </div>
          <div className="p-col-12" style={{
              textAlign:'center'
            }}>
            <Button label={cadenas.LOGIN_BTN_RECUPERAR} 
              className="p-button p-button-rounded"
              icon="pi pi-sign-in" 
              iconPos="left"
              style={{
                width:'150px',
              }} />
          </div>
        </div>
      </form>
    );
  }

  /**
   * @function
   * Función principal para renderizar el contenido del componente.
   */
  render() {
    const estiloContainer = {height:'90vh', overflow:'auto'};
    const header = <div style={{textAlign:"center"}}><img src={logoVwm} alt="VWM" className="imgVwmLogin" /></div>;
    return (
      <div>
        <BlockUi tag="div" id="bloqueoLogin" blocking={this.state.blocking}>
          <Growl ref={this.growlError} />
          <div className="p-grid p-fluid p-align-center" style={estiloContainer}>
            <div className="p-col-4"></div>
            <div className="p-col-4">
              <div className="p-col-12">
                <Card header={header}>
                    {this.state.edoUsuario === '' && this.crearFormLogin()}
                    {this.state.edoUsuario === EDOS_COGNITO.NEWPASSWORDREQUIRED && this.crearFormCambioContrasenia()}
                    {this.state.edoUsuario === EDOS_COGNITO.RESET && this.crearFormResetContrasenia()}
                </Card>
              </div>
            </div>
            <div className="p-col-4"></div>
          </div>
        </BlockUi>
      </div>
    );
  }
}

export default LoginContainer;
