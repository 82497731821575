import React, { Component } from 'react';

class InfoDashboard extends Component {
    render() {
        return  (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Información del dashboard</h1>
                        <p>Bienvenido a Entregas Dealer.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default InfoDashboard;
