import React, { Component, createRef } from 'react';
import BlockUi from 'react-block-ui';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Growl } from 'primereact/growl';

import { PATHS_API, CONFIG_GROWL } from '../../Utils/constants';
import { cadenas } from '../../Utils/cadenas';
import { getUsuarioLogueado } from '../../Utils/gestionSessionStorage';
import { APIGATEWAY } from '../../Api/ApiGateway';


/**
 * @interface
 * Se define el estado del componente.
 */
interface State {

}

/**
 * @class
 * Componentes para realizar consultas de la bitácora.
 */
class NuevoCodigo extends Component<{}, State> {
    private growlError: any;

    /**
     * @constructor
     * @param {any} props Son las propiedades del componente-
     */
    constructor(props: any) {
        super(props);
        this.growlError = createRef();
    }

    /**
     * Evento que se ejecuta antes de renderizar el componente.
     */
    componentDidMount() {
    }

    /**
     * Mostrar notificación de error.
     * @param {string} title Título de la notificación. 
     * @param {string} message Mensaje de la notificación.
     */
    showError(title: string, message: string) {
        this.growlError.current.show({ severity: 'error', summary: title, detail: message });
    }


    /**
     * Función principal de react para renderizar los componentes html.
     */
    render() {
        const headerCard = <div style={{ textAlign: "center" }}><h1>{cadenas.REGENERAR_CODIGO_CONFIRMACION_DEALER}</h1></div>;
        let cod = getUsuarioLogueado()?.codigoEmpresa as string
        
        return (
            <div>
                <BlockUi tag="div">
                    <Growl ref={this.growlError} />
                    <Card header={headerCard} style={{ padding: "1em" }}>
                        <div>
                            <Button
                                type="button" 
                                icon="pi pi-envelope"
                                className="p-button-success"
                                style={{marginRight: '.5em', width: '100px'}}
                                onClick={() => { this.generarNuevoCodigo(cod) }}>
                                Generar
                            </Button>
                        </div>
                    </Card>
                </BlockUi>
            </div>
        );
    }


    /**
     * Invocar endpoint /destinos/regen para la regeneracion de un codigo de confirmacion generico para el dealer
     * @param concesionario Concesionario para el cual debe regenerarse un nuevo codigo.
     */
    async generarNuevoCodigo(concesionario: String) {
        this.setState({
            blocking: true,
        });
       
        //armar json a enviar
        var data = {          
        }

        //crear path
        var path = `/${concesionario}`;

        //post
        APIGATEWAY.post(PATHS_API.REGEN_CODE + path, data)
          .then(async response => {
            console.log("regenCode()", response);
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.ACCION_EXITOSA);
          })
          .catch(async (error) => {
            this.setState({blocking:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_ERROR_RESEND);
            console.error("regenCode()",error);
          });
    }

     /**
     * Mostrar la notificación de tipo Growl (componente del framewrok Prime React).
     * @param {string} type Tipo de la notificación. 
     * @param {string} title Título de la notificación.
     * @param {string} message Mensaje de la notificación.
     */
      showNotificacion(type:string, title:string, message:string) {
        this.growlError.current.show({severity: type, summary: title, detail: message, life:CONFIG_GROWL.LIFE});
    }
}

export default NuevoCodigo;
