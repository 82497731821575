/**
 * Cadenas que se muestran en las pantallas.
 */
export const cadenas = {
    LOGIN_TITULO: 'VW Dealer Delivery',
    LOGIN_INICIAR_CONCE: 'Ingresar',
    LOGIN_TITULO_CAMBIO_CONTRASENIA: 'Por seguridad, cambie su contraseña.',
    LOGIN_TITULO_RESET_CONTRASENIA: 'Recuperar su contraseña.',
    LOGIN_FORM_USUARIO: 'Usuario',
    LOGIN_FORM_PASSWD: 'Contraseña',
    LOGIN_FORM_NEW_PASSWD: 'Contraseña nueva',
    LOGIN_FORM_NEW_PASSWD_CONFIRM: 'Confirmar contraseña',
    LOGIN_FORM_ROL: 'Selecciona tu Rol',
    LOGIN_FORM_COD_RESET: 'Código de recuperación',
    LOGIN_FORM_INGRESAR: 'Ingresar',
    LOGIN_FORM_CAMBIAR: 'Cambiar',
    LOGIN_BTN_RECUPERAR: 'Recuperar',
    LOGIN_BTN_RECUPERAR_CONTRASENIA: 'Recuperar contraseña',
    LOGIN_ERROR_USUARIO_REQUERIDO: 'Falta ingresar el usuario.',
    LOGIN_ERROR_PASSWD_REQUERIDO: 'Falta ingresar la contraseña.',
    LOGIN_ERROR_TITULO_VALIDACION: 'Validación',
    LOGIN_ERROR_TITULO_SESION: 'Sesión',
    LOGIN_ERROR_CREDENCIALES_INCORRECTAS: 'El usuario y contraseña son inceorrectos.',
    LOGIN_ERROR_USUARIO_DESACTIVADO: 'Su cuenta ha sido desactivada.',
    LOGIN_ERROR_CAMBIO_PASSWORD: 'Ocurrió un error con el cambio.',
    LOGIN_ERROR_RESET_PASSWORD: 'Ocurrió un error con el reset.',
    LOGIN_ERROR_DATOS_INCORRECTOS: 'Datos incorrectos.',
    LOGIN_ERROR_NO_CARGAR_DATOS: 'No es posible cargar sus datos.',
    LOGIN_ERROR_CONTRASENIAS_DIFERENTES: 'Las contraseñas no coinciden.',
    LOGIN_MENSAJE_ERROR_USER_PASS_WRONG: 'Usuario o contraseña incorrecta',
    LOGIN_MENSAJE_ERROR_NO_USER_EXIST: 'El usuario no existe',
    LOGIN_MENSAJE_ERROR_USER_DISABLED: 'El usuario esta desactivado',
    LOGIN_MENSAJE_ERROR_ROLE_DISABLED: 'El rol esta desactivado o no existe',
    LOGIN_MENSAJE_ERROR_INCORRECT_COMPANY_CODE: 'La compañía es incorrecta',
    LOGIN_MENSAJE_ERROR_INCORRECT_CARRIER: 'El transportista es incorrecto',
    LOGIN_MENSAJE_ERROR_INCORRECT_DEALER: 'El concesionario es incorrecto',
    LOGIN_MENSAJE_ERROR_ROLE_NOT_AUTHORIZED: 'Rol no autorizado para ingresar',
    LOGIN_MENSAJE_ERROR_PASS_RESET: 'Se requiere resetear la contraseña',
    LOGIN_MENSAJE_ERROR_TEMP_PASS_EXPIRED: 'La contraseña temporal ha caducado y un administrador debe restablecerla',
    LOGIN_MENSAJE_ERROR_EXPIRED_SESSION: 'Sesión no válida para el usuario, la sesión caducó',
    LOGIN_PASS_ERROR_NO_USER_EXIST: 'El usuario no existe o es incorrecto',
    LOGIN_PASS_ERROR_GENERAL: 'Ocurrió un error',
    LOGIN_PASS_ERROR_CODE_INVALID_EXPIRED: 'El código ha expirado. Solicite uno nuevo a su Administrador',
    ATTEMPT_LIMIT_EXCEEDED:'Número de intentos excedido. Por favor intente más tarde',
    ADMINSIS_VISTA_TITULO: 'Administradores de sistema',
    ADMINUMS_ROLE: 'Rol',
    ADMINSIS_BTN_NUEVO: 'Nuevo',
    ADMINSIS_DIALOG_NUEVO_TITULO: 'Nuevo Administrador de sistema',
    ADMINSIS_DIALOG_EDITAR_TITULO: 'Edita Administrador de sistema',
    ADMINSIS_DATA_NOMBRE_COMPLETO: 'Nombre completo',
    ADMINSIS_DATA_CORREO: 'Correo',
    ADMINSIS_DATA_ROL: 'Rol',
    ADMINSIS_DATA_TELEFONO: 'Teléfono',
    ADMINSIS_DATA_COD_EMPRESA: 'Empresa',
    ADMINSIS_DATA_ESTADO: 'Estado',
    ADMINSIS_DATA_ACTIVO: 'Activo',
    ADMINSIS_DATA_USUARIO: 'Usuario',
    ADMINSIS_DATA_CONTRASENIA: 'Contraseña',
    ADMINSIS_DATA_CONTRASENIA_CONFIRMADA: 'Confimar contraseña',
    ADMINSIS_ERROR_VALIDACION_CONTRASENIAS: 'Las contraseñas no coinciden.',
    ADMINSIS_ERROR_ELIMINACION: 'Ocurrió algo mal para eliminar.',
    ADMINSIS_ERROR_OBTENER_USUARIOS: 'Ocurrió algo mal para consultar usuarios.',
    ADMINSIS_ERROR_REGISTRO: 'Ocurrió algo mal en el registro.',
    ADMINSIS_BTN_RESET: 'Reset contraseña',
    ADMINSIS_BTN_ACTIVAR: 'Activar usuario',
    ADMINSIS_BTN_DESACTIVAR: 'Desactivar usuario',
    ADMINDEALER_VISTA_TITULO: 'Administradores Dealer',
    ADMINDEALER_BTN_NUEVO: 'Nuevo',
    ADMINDEALER_BTN_ACTIVAR: 'Activar usuario',
    ADMINDEALER_BTN_DESACTIVAR: 'Desactivar usuario',
    ADMINDEALER_BTN_RESET: 'Reset contraseña',
    ADMINDEALER_DIALOG_NUEVO_TITULO: 'Nuevo Administrador Dealer',
    ADMINDEALER_DIALOG_EDITAR_TITULO: 'Edita Administrador Dealer',
    ADMINDEALER_DATA_NUM_CONCESIONARIA: 'No. Concesionaria',
    ADMINDEALER_DATA_NOMBRE_CONCESIONARIA: 'Nombre Concesionaria',
    ADMINDEALER_DATA_NOMBRE_COMPLETO: 'Nombre de contacto',
    ADMINDEALER_DATA_CORREO: 'Correo',
    ADMINDEALER_DATA_TELEFONO: 'Teléfono',
    ADMINDEALER_DATA_USUARIO: 'Usuario',
    ADMINDEALER_DATA_CONTRASENIA: 'Contraseña',
    ADMINDEALER_DATA_COD_EMPRESA: 'Código Empresa',
    ADMINDEALER_DATA_ROL: 'Rol',
    ADMINDEALER_DATA_ESTADO: 'Estado',
    ADMINDEALER_DATA_ACTIVO: 'Activo',
    ADMINDEALER_DATA_CONTRASENIA_CONFIRMADA: 'Confimar contraseña',
    ADMINDEALER_ERROR_VALIDACION_CONTRASENIAS: 'Las contraseñas no coinciden.',
    ADMINDEALER_ERROR_ELIMINACION: 'Ocurrió algo mal para eliminar.',
    ADMINDEALER_ERROR_OBTENER_USUARIOS: 'Ocurrió algo mal para consultar usuarios.',
    ADMINDEALER_ERROR_REGISTRO: 'Ocurrió algo mal en el registro.',
    ADMINDEALER_ERROR_CAT_CONCESIONARIOS: 'No es posible cargar los Concesionarios.',
    INSPECTOR_VISTA_TITULO: 'Inspectores',
    INSPECTOR_BTN_NUEVO: 'Nuevo',
    INSPECTOR_DIALOG_NUEVO_TITULO: 'Nuevo Inspector',
    INSPECTOR_DIALOG_EDITAR_TITULO: 'Edita Inspector',
    INSPECTOR_DATA_NUM_EMPRESA: 'No. Empresa',
    INSPECTOR_DATA_NOMBRE_EMPRESA: 'Nombre Empresa',
    INSPECTOR_DATA_NOMBRE_COMPLETO: 'Nombre de contacto',
    INSPECTOR_DATA_ESTADO: 'Estado',
    INSPECTOR_DATA_ACTIVO: 'Activo',
    INSPECTOR_DATA_CODIGO_EMPRESA: 'Empresa',
    INSPECTOR_DATA_CORREO: 'Correo',
    INSPECTOR_DATA_TELEFONO: 'Teléfono',
    INSPECTOR_DATA_USUARIO: 'Usuario',
    INSPECTOR_DATA_CONTRASENIA: 'Contraseña',
    INSPECTOR_DATA_ROL: 'Rol',
    INSPECTOR_DATA_CONTRASENIA_CONFIRMADA: 'Confimar contraseña',
    INSPECTOR_ERROR_VALIDACION_CONTRASENIAS: 'Las contraseñas no coinciden.',
    INSPECTOR_ERROR_OBTENER_USUARIOS: 'Ocurrió algo mal para consultar usuarios.',
    INSPECTOR_ERROR_REGISTRO: 'Ocurrió algo mal en el registro.',
    INSPECTOR_ERROR_ELIMINACION: 'Ocurrió algo mal para eliminar.',
    INSPECTOR_BTN_RESET: 'Reset contraseña',
    INSPECTOR_BTN_ACTIVAR: 'Activar usuario',
    INSPECTOR_BTN_DESACTIVAR: 'Desactivar usuario',
    INSPECTOR_DATA_NUM_CONCESIONARIA: 'No. Concesionaria',
    INSPECTOR_DATA_NOMBRE_CONCESIONARIA: 'Nombre Concesionaria',
    ADMINTRANSPORTISTA_VISTA_TITULO: 'Administradores Transportista',
    ADMINTRANSPORTISTA_BTN_NUEVO: 'Nuevo',
    ADMINTRANSPORTISTA_DIALOG_NUEVO_TITULO: 'Nuevo Administrador Transportista',
    ADMINTRANSPORTISTA_DIALOG_EDITAR_TITULO: 'Edita Administrador Transportista',
    ADMINTRANSPORTISTA_DATA_NUM_EMPRESA: 'No. Empresa',
    ADMINTRANSPORTISTA_DATA_ROL: 'Rol',
    ADMINTRANSPORTISTA_DATA_COD_EMPRESA: 'Código Empresa',
    ADMINTRANSPORTISTA_DATA_NOMBRE_EMPRESA: 'Nombre Empresa',
    ADMINTRANSPORTISTA_DATA_NOMBRE_COMPLETO: 'Nombre de contacto',
    ADMINTRANSPORTISTA_DATA_CORREO: 'Correo',
    ADMINTRANSPORTISTA_DATA_TELEFONO: 'Teléfono',
    ADMINTRANSPORTISTA_DATA_ESTADO: 'Estado',
    ADMINTRANSPORTISTA_DATA_ACTIVO: 'Activo',
    ADMINTRANSPORTISTA_DATA_USUARIO: 'Usuario',
    ADMINTRANSPORTISTA_DATA_CONTRASENIA: 'Contraseña',
    ADMINTRANSPORTISTA_DATA_CONTRASENIA_CONFIRMADA: 'Confimar contraseña',
    ADMINTRANSPORTISTA_ERROR_VALIDACION_CONTRASENIAS: 'Las contraseñas no coinciden.',
    ADMINTRANSPORTISTA_ERROR_CAT_TRANPORTISTAS: 'No es posible cargar las Transportistas.',
    ADMINTRANSPORTISTA_ERROR_CAT_TRANSPORTISTAS: 'No hay Transportistas disponibles para el registro.',
    ADMINTRANSPORTISTA_ERROR_OBTENER_USUARIOS: 'Ocurrió algo mal para consultar usuarios.',
    ADMINTRANSPORTISTA_ERROR_REGISTRO: 'Ocurrió algo mal en el registro.',
    ADMINTRANSPORTISTA_ERROR_ELIMINACION: 'Ocurrió algo mal para eliminar.',
    ADMINTRANSPORTISTA_BTN_RESET: 'Reset contraseña',
    ADMINTRANSPORTISTA_BTN_ACTIVAR: 'Activar usuario',
    ADMINTRANSPORTISTA_BTN_DESACTIVAR: 'Desactivar usuario',
    
    CONDUCTOR_VISTA_TITULO: 'Conductores',
    CONDUCTOR_BTN_NUEVO: 'Nuevo',
    CONDUCTOR_DIALOG_NUEVO_TITULO: 'Nuevo Conductor',
    CONDUCTOR_DIALOG_EDITAR_TITULO: 'Edita Conductor',
    CONDUCTOR_DATA_NUM_EMPRESA: 'No. Empresa',
    CONDUCTOR_DATA_NOMBRE_EMPRESA: 'Nombre Empresa',
    CONDUCTOR_DATA_NOMBRE_COMPLETO: 'Nombre de contacto',
    CONDUCTOR_DATA_ESTADO: 'Estado',
    CONDUCTOR_DATA_ACTIVO: 'Activo',
    CONDUCTOR_DATA_CODIGO_EMPRESA: 'Empresa',
    CONDUCTOR_DATA_CORREO: 'Correo',
    CONDUCTOR_DATA_TELEFONO: 'Teléfono',
    CONDUCTOR_DATA_USUARIO: 'Usuario',
    CONDUCTOR_DATA_CONTRASENIA: 'Contraseña',
    CONDUCTOR_DATA_ROL: 'Rol',
    CONDUCTOR_DATA_CONTRASENIA_CONFIRMADA: 'Confimar contraseña',
    CONDUCTOR_ERROR_VALIDACION_CONTRASENIAS: 'Las contraseñas no coinciden.',
    CONDUCTOR_ERROR_OBTENER_USUARIOS: 'Ocurrió algo mal para consultar usuarios.',
    CONDUCTOR_ERROR_REGISTRO: 'Ocurrió algo mal en el registro.',
    CONDUCTOR_ERROR_ELIMINACION: 'Ocurrió algo mal para eliminar.',
    CONDUCTOR_BTN_RESET: 'Reset contraseña',
    CONDUCTOR_BTN_ACTIVAR: 'Activar usuario',
    CONDUCTOR_BTN_DESACTIVAR: 'Desactivar usuario',
    
    CONSULTA_VIAJES_VISTA_TITUTLO: 'Consulta de Viajes',
    CONSULTA_VIAJES_COD_CONFIRM_LABEL: 'Código confirmación',
    CONSULTA_VIAJES_NUM_TRK_LABEL: 'Núm. de TRK',
    CONSULTA_VIAJES_ORIGENLABEL: 'Origen',
    CONSULTA_VIAJES_NUM_TRP_LABEL: 'Núm. de TRP',
    CONSULTA_VIAJES_RANGO_FECHA_LABEL: 'Fecha de partida',
    CONSULTA_VIAJES_NO_UNIDADES_LABEL: 'No. Unidades',
    CONSULTA_VIAJES_NUM_ECONO_LABEL: 'Núm. Económico',
    CONSULTA_VIAJES_CONDUCTOR_LABEL: 'Conductor',
    CONSULTA_VIAJES_CARRIER_LABEL: 'Carrier',
    CONSULTA_VIAJES_ESTATUS_LABEL: 'Estatus',
    CONSULTA_VIAJES_VIN_LABEL: 'VIN',
    CONSULTA_VIAJES_ORDER_ID_LABEL: 'Order ID',
    CONSULTA_VIAJES_INICIO_VIAJE_LABEL: 'Núm. Económico (Iniciar Viaje)',
    CONSULTA_VIAJES_ERROR_VALIDACION_NUMS: 'Debe indicar al menos un: ',
    CONSULTA_VIAJES_FORM_BUSCAR: 'Buscar por',
    CONSULTA_VIAJES_FORM_FECHA_PARTIDA: 'Fecha de partida',
    CONSULTA_VIAJES_FORM_FECHA_LLEGADA: 'Fecha de llegada',
    CONSULTA_VIAJES_FORM_NUMEROS: 'Introduzca uno o hasta 10',
    CONSULTA_VIAJES_FORM_NUMEROS_HELPER: 'Use la tecla Enter o Coma para ingresar otro valor.',
    CONSULTA_VIAJES_DATA_ORDER_ID: 'ID Orden',
    CONSULTA_VIAJES_DATA_NUM_TRK: 'Núm. TRK',
    CONSULTA_VIAJES_DATA_NUM_DEALER: 'Núm. Dealer',
    CONSULTA_VIAJES_DATA_VIN: 'VIN',
    CONSULTA_VIAJES_DATA_EDO: 'Estado',
    CONSULTA_VIAJES_DATA_FECHA_LLEGADA: 'Fecha llegada',
    CONSULTA_VIAJES_DATA_UBICACION_LLEGADA: 'Ubicación llegada',
    CONSULTA_VIAJES_DATA_FECHA_CONFIRMACION: 'Fecha confirmación',
    CONSULTA_VIAJES_DATA_CONFIRMADO_POR: 'Confirmado Por',
    CONSULTA_VIAJES_BTN_BUSCAR: 'Buscar',
    CONSULTA_VIAJES_BUSQUEDA_ERROR_VIAJES: 'No se encontraron viajes.',
    CONSULTA_VIAJES_BUSQUEDA_ERROR_VISITAS: 'No se encontraron visistas.',
    CONSULTA_VIAJES_BUSQUEDA_ERROR_CHASIS: 'No se encontraron vehículos.',
    CONSULTA_VIAJES_INFORMACION_CHASIS: 'Información del vin',
    CONSULTA_BITACORA_VISTA_TITUTLO: 'Consulta de Bitácora',
    CONSULTA_BITACORA_FECHA: 'Fecha',
    CONSULTA_BITACORA_ERROR_VALIDACION_NUMS: 'Debe indicar al menos un: ',
    CONSULTA_BITACORA_BUSQUEDA_ERROR_BITACORA: 'No se encontraron registros.',
    CONSULTA_BITACORA_FORM_FECHA_MES_ANIO: 'Mes y año',
    CONSULTA_BITACORA_FECHA_PARTIDA: 'Fecha de partida',
    CONSULTA_BITACORA_FORM_NUMEROS: 'Introduzca uno o hasta 10',
    CONSULTA_BITACORA_FORM_NUMEROS_HELPER: 'Use la tecla Enter o Coma para ingresar otro valor.',
    CONSULTA_BITACORA_FORM_BUSCAR: 'Buscar por',
    CONSULTA_BITACORA_BTN_BUSCAR: 'Buscar',
    CAPTURA_ONLINE_TITULO_DATATABLE_BITACORA: 'Bitácora',
    CAPTURA_ONLINE_BITACORA_DATA_LONGDATE: 'Long date',
    CAPTURA_ONLINE_BITACORA_DATA_FECHA: 'Fecha',
    CAPTURA_ONLINE_BITACORA_DATA_PRINCIPAL: 'Usuario que ejecuta',
    CAPTURA_ONLINE_BITACORA_DATA_TARGET: 'Usuario procesado',
    CAPTURA_ONLINE_BITACORA_DATA_OPERACION: 'Operación',
    CAPTURA_ONLINE_BITACORA_DATA_EMPRESA: 'Empresa',
    CAPTURA_ONLINE_BITACORA_DATA_MES: 'Mes',
    CAPTURA_ONLINE_BITACORA_DATA_ANIO: 'Año',
    CAPTURA_ONLINE_BITACORA_DATA_USUARIO_ITEM: 'Detalle',

    CAPTURA_ONLINE_TITULO_DATATABLE_USOAPP: 'Uso app',
    CAPTURA_ONLINE_USOAPP_DATA_PRINCIPAL: 'Usuario',
    CAPTURA_ONLINE_USOAPP_DATA_TIPOUSUARIO: 'Tipo usuario',
    CAPTURA_ONLINE_USOAPP_DATA_FECHAREGISTRO: 'Fecha registro',
    CAPTURA_ONLINE_USOAPP_DATA_OPERACION: 'Operación',
    CAPTURA_ONLINE_USOAPP_DATA_CONCESIONARIO: 'Concesionario',
    CAPTURA_ONLINE_USOAPP_DATA_ORDENAMIENTO: 'Ordenamiento',

    CONSULTA_USOAPP_FORM_FECHAINICIO_MES_ANIO: 'Fecha inicio(Mes y año)',
    CONSULTA_USOAPP_FORM_FECHAFIN_MES_ANIO: 'Fecha fin(Mes y año)',
    CONSULTA_USOAPP_VISTA_TITUTLO: 'Estadísticas de uso de app',

    CAPTURA_ONLINE_TITULO: 'Captura Online',
    CAPTURA_ONLINE_FORM_NUM_ECONOMICO: 'Número económico',
    CAPTURA_ONLINE_FORM_PUEBLA: 'Puebla',
    CAPTURA_ONLINE_FORM_VERACRUZ: 'Veracruz',
    CAPTURA_ONLINE_BTN_BUSCAR: 'Buscar',
    CAPTURA_ONLINE_TITULO_DATATABLE_VIAJES: 'Viajes',
    CAPTURA_ONLINE_VIAJES_DATA_ORDERID: 'Order ID',
    CAPTURA_ONLINE_VIAJES_DATA_CARRIER: 'Carrier',
    CAPTURA_ONLINE_VIAJES_DATA_TRK: 'TRK',
    CAPTURA_ONLINE_VIAJES_DATA_COMPOUND: 'Origen',
    CAPTURA_ONLINE_VIAJES_DATA_NUMECONOMICO: 'No. Económico',
    CAPTURA_ONLINE_VIAJES_DATA_CONDUCTOR: 'Conductor',
    CAPTURA_ONLINE_VIAJES_DATA_ESTATUS: 'Estatus',
    CAPTURA_ONLINE_VIAJES_DATA_FECHA_REGISTRO: 'Fecha inicio',
    CAPTURA_ONLINE_VIAJES_DATA_FECHA_CONSULTA: 'Fecha consulta',
    CAPTURA_ONLINE_VIAJES_DATA_FECHA_NUMVISITAS: 'No. visitas',
    CAPTURA_ONLINE_VIAJES_DATA_FECHA_NUM_UNIDADES: 'No. unidades',
    CAPTURA_ONLINE_ERRO_BUSQUEDA: 'No se ha encontrado el viaje.',
    CAPTURA_ONLINE_LLEGADA_EXITOSA: 'Registro de llegada correcta.',
    CAPTURA_ONLINE_LLEGADA_ERRONEA: 'No se logró registrar la llegada.',
    CAPTURA_ONLINE_TITULO_DATATABLE_VISITAS: 'Entregas del Viaje',
    CAPTURA_ONLINE_BTN_REGISTRAR_LLEGADA: 'Llegada',
    CAPTURA_ONLINE_BTN_REGISTRAR_NUEVA_EVIDENCIA: 'Nueva Evidencia',
    CAPTURA_ONLINE_VISITAS_DATA_CONCESIONARIA: 'Concesionaria',
    CAPTURA_ONLINE_VISITAS_DATA_ESTATUS_ENTREGA: 'Estatus entrega',
    CAPTURA_ONLINE_VISITAS_DATA_FECHA_LLEGADA: 'Fecha llegada',
    CAPTURA_ONLINE_VISITAS_DATA_FECHA_RECEPCION: 'Fecha recepción',
    CAPTURA_ONLINE_VISITAS_DATA_UBICACION_LAT: 'Ubicación latitud',
    CAPTURA_ONLINE_VISITAS_DATA_UBICACION_LNG: 'Ubicación longitud',
    CAPTURA_ONLINE_VISITAS_DATA_FECHA_FINALIZACION: 'Fecha Recepción',
    CAPTURA_ONLINE_TITULO_DATATABLE_VINS: 'Vins de la Concesionaria',
    CAPTURA_ONLINE_VINS_DATA_VIN: 'VIN',
    CAPTURA_ONLINE_VINS_DATA_MODELO: 'Modelo',
    CAPTURA_ONLINE_VINS_DATA_TAMANIO: 'Tamaño',
    CAPTURA_ONLINE_VINS_DATA_COLOR: 'Color',
    CAPTURA_ONLINE_VINS_DATA_ESTATUS: 'Estatus',
    CAPTURA_ONLINE_BTN_DANIOS: 'Daños',
    CAPTURA_ONLINE_BTN_FALTANTES: 'Faltantes',
    CAPTURA_ONLINE_BTN_SINIESTRO: 'Siniestro',
    CAPTURA_ONLINE_BTN_CONFIRMAR: 'Confirmar',
    CAPTURA_ONLINE_BTN_FINALIZACION_SIN_DESCARGA: 'Finalizar sin Descarga',
    CAPTURA_ONLINE_BTN_FINALIZACION: 'Finalizar',
    CAPTURA_ONLINE_BTN_OBSERVACIONES: 'Observaciones',
    CAPTURA_ONLINE_VISITAS_DATA_FINALIZADO_POR: 'Finalizado Por',
    CONSULTA_VIAJES_FORM_FECHA_MES_ANIO: 'Mes y año',
    CONSULTA_VIAJES_FECHA_PARTIDA: 'Fecha de partida',
    CONSULTA_VIAJES_EVIDENCIAS: 'Evidencias',
    CONSULTA_VIAJES_RESEND: 'Reenviar código',
    CONSULTA_VIAJES_ERROR_RESEND: 'No se pudo reenviar el codigo de confirmación',
    CONSULTA_VIAJES_FECHA_INICIO: 'Fecha de inicio',
    CONSULTA_VIAJES_FECHA_CONSULTA: 'Fecha de consulta',
    INICIALIZA_VIAJE_TITULO: 'Inicializa Viaje',

    // CADENAS GENERALES
    PAGINA_404: 'Error 404',
    FORMULARIO_REGISTRO_TOOLTIP_CONTRASENIA: '<ul><li>Minímo 8 caracteres</li><li>Debe terner números</li><li>Debe tener minúsculas</li><li>Debe tener mayúsculas</li><li>Debe tener un carácter especial: @ # $ % :</li></ul>',
    BTN_GUARDAR: 'Guardar',
    BTN_CANCELAR: 'Cancelar',
    BTN_EDITAR: 'Editar',
    BTN_ELIMINAR: 'Eliminar',
    SELECT_SELECCIONA: 'Selecciona una opción',
    SELECT_ESCRIBE_CONCESIONARIO: 'Escribe un concesionario',
    ERROR_TITULO_VALIDACION: 'Validación',
    ERROR_TITULO: 'Error',
    EXITO_TITUTLO: 'Éxito',
    CONFIRMAR_ACCION:'¿Está seguro de realizar la acción?',
    DATATABLE_VACIO: 'No se encontraron registros.',
    BTN_EXPORTAR_CSV: 'Exportar CSV',
    GROWL_ERROR: 'error',
    GROWL_SUCCESS: 'success',
    BTN_REGRESAR: 'Regresar',
    LOGIN_FORM_SELECT: 'Tipo usuario',
    ACCION_EXITOSA: 'Acción realizada con éxito.',
    ACCION_NO_EXITOSA: 'Ocurrió algo mal con la acción.',
    USUARIO_REGISTRADO: 'Se ha enviado una contraseña temporal al correo:',
    USUARIO_RESET: 'Se ha enviado código de cambio al correo:',
    ACTIVO_SI: 'SI',
    ACTIVO_NO: 'NO',
    REV: 'REV',
    REPORTE_FULL_DANIOS: 'Reporte mensual danios CSV',
    REPORTE_FULL_FALTANTES: 'Reporte mensual faltantes CSV',
    SUBIR_IMAGENES_DANIOS: 'Agregar imagenes de daños',
    IMAGENES_DANIOS_VACIO: 'No se agregaron imagenes de daños para este vin:',
    VER_IMAGENES_DANIOS: 'Ver imagenes de daños',
    IMAGENES_DANIOS_EXCEDER: 'Ya se alcanzó el limite de imagenes permitidas por vin',
    IMAGENES_EXITO_AGREGAR: ' agregada correctamente',
    BOTON_CERRAR: 'Cerrar',
    REV_ARCHIVOS_EXCEDER: 'Ya se alcanzó el limite de archivos permitidos por entrega',
    ERROR_DESCARGA_REV: 'Ocurrió un error al descargar el REV',

    REGENERAR_CODIGO_CONFIRMACION_DEALER: 'Haga clic y se creará un nuevo código único de confirmación.'
};
