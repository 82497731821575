import * as React from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

/**
 * Estilos personalizados
 */
import '../Themes/layout/layout.scss';
import '../Themes/layout/App.scss';

/**
 * Configuración de las rutas.
 */
import AppRoutes from '../Navigation/Routes';

/**
 * Configuración de los iconos.
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTruck, faPlus, faKey, faUserTimes, faUserPlus, faCarCrash, 
  faCheckCircle, faExclamationCircle, faMapMarker, faStoreAltSlash, faBan,
  faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { getUsuarioLogueado } from '../Utils/gestionSessionStorage';
import { configuracionAws } from '../Utils/AWSExports';
library.add(faTruck, faPlus, faKey, faUserTimes, faUserPlus, faCarCrash, 
  faCheckCircle, faExclamationCircle, faMapMarker, faStoreAltSlash, faBan,
  faCommentAlt);

/** configuracion aws de acuerdo al rol logueado */
if ( getUsuarioLogueado()?.rol !== undefined )
  configuracionAws(getUsuarioLogueado()?.rol as string);

/**
 * @constant
 * Función para renderizar la configuración de las rutas.
 * @returns {React.FC} Componente funcional de rutas.
 */
const App: React.FC = () => {
  return <AppRoutes />;
};

export default App;
