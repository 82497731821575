import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Button } from 'primereact/button';
// import {Column} from 'primereact/column';
import { Card } from 'primereact/card';
import { Growl } from 'primereact/growl';
import { Chips } from 'primereact/chips';

import {
  CONSULTA_VIAJES,
  PATHS_API,
} from '../../Utils/constants';
import { cadenas } from '../../Utils/cadenas';
import { getUsuarioLogueado } from '../../Utils/gestionSessionStorage';
import { APIGATEWAY } from '../../Api/ApiGateway';

/**
 * @interface
 * Se define el estado del componente.
 */
interface State {
  numerosFiltro: [];
  blocking: boolean;
}

/**
 * @class
 * Componentes para realizar consultas de los vioajes.
 */
class Correos extends Component<{}, State> {
  /**
   * @constructor
   * @param {any} props Son las propiedades del componente-
   */
  constructor(props: any) {
    super(props);
    this.state = {
      numerosFiltro: [],
      blocking: false,
    };
    this.enviar = this.enviar.bind(this);
    this.crearFormularioBusqueda = this.crearFormularioBusqueda.bind(this);
    this.crearCamposBusqueda = this.crearCamposBusqueda.bind(this);
    this.isValidEmailAddress = this.isValidEmailAddress;
  }
  /**
   * Creación del campo tipo array de textos para realizar la búsqueda.
   */
  crearCamposBusqueda() {
    return (
      <div className="p-field">
        <label htmlFor="numerosFiltro">
          Administra los correos autorizados para el envio del código de confirmación: *
        </label>
        <Chips
          id="numerosFiltro"
          value={this.state.numerosFiltro}
          separator=","
          max={100}
          allowDuplicate={false}
          onChange={(e) => {
            console.log(e.value);
            var elementos = [];
            var sigue = true;
                for (let i = 0; i < e.value.length; i++) {
                    if (!this.isValidEmail(e.value[i])) {
                    sigue = false;
                    }
                }
                if (sigue) {
                    this.setState({ numerosFiltro: e.value });
                } else {
                    window.alert('El correo ingresado no es válido');
                }
            }
            
        }
        />
        <small id="username-help">Use la tecla Enter o Coma para ingresar otro valor.</small>
      </div>
    );
  }
  isValidEmailAddress(correo: string) {
    return !!correo.match(/.+@.+/);
  }
  isValidEmail(correo: string) {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(correo)) {
      return false;
    }
    else{
        return true;
    }
  }
  /**
   * actualizar correos de concesionario
   * @param {React.FormEvent} event Evento del formulario.
   */
  obtener() {
    this.setState({
      blocking: true,
    });
    let body = {
      busqueda: getUsuarioLogueado()?.codigoEmpresa,
    };
    console.log('Viajes -> enviarcorreos -> this.crearBodyBusqueda()', JSON.stringify(body));
    console.log(PATHS_API.CORREOS_CONSULTA);
    APIGATEWAY.post(PATHS_API.CORREOS_CONSULTA, body)
      .then((response) => {
        console.log('Correos -> buscarCorreos -> response', response);
        this.setState({
          numerosFiltro: response,
        });
        const count = this.state.numerosFiltro.length;
        for (let i = 0; i < count; i++) {
          console.log(this.state.numerosFiltro[i]);
        }
        this.setState({
          blocking: false,
        });
      })
      .catch((error) => {
        this.setState({
          blocking: false,
        });
      });
  }
  /**
   * actualizar correos de concesionario
   * @param {React.FormEvent} event Evento del formulario.
   */
  enviar(event: React.FormEvent) {
    event.preventDefault();
    this.setState({
      blocking: true,
    });
    let body = {
      busqueda: getUsuarioLogueado()?.codigoEmpresa,
      correos: this.state.numerosFiltro.toString()
      //'["1gespinosagalindo@gmail.com","fernando.lopez2@proveed-vw.com.mx","lucy_1651@hotmail.com","luz.razo@proveed-vw.com.mx","mayri_xion@live.com","otrocorreo1@nomail.com","otrocorreo2@nomail.com","ricardo.moctezuma1@proveed-vw.com.mx","rmluzelena@hotmail.com","roberto.espinosa@proveed-vw.com.mx","usuario002@noexiste.com"]',
    };
    console.log('correos', JSON.stringify(this.state.numerosFiltro));
    console.log('Viajes -> enviarcorreos -> this.crearBodyBusqueda()', JSON.stringify(body));
    APIGATEWAY.post(PATHS_API.CORREOS_ACTUALIZA, body)
      .then((response) => {
        if (response == "OK"){
            window.alert('Se ha actualizado con éxito la lista de correos');
            console.log('Viajes -> actualizacorreos -> response', response);
            this.setState({
            blocking: false,
            });
        }
        else{
             window.alert('No fue posible actualizar la lista de correos');
             this.setState({
               blocking: false,
             });
        }
      })
      .catch((error) => {
        console.log('Correos -> error actualizacorreos -> error: ', error);
        this.setState({
          blocking: false,
        });
      });
  }
  /**
   * Evento que se ejecuta antes de renderizar el componente.
   */
  componentDidMount() {
    this.obtener();
  }
  /**
   * Crear el objeto body que se envia al servidor
   * para realizar la búsqueda de viajes.
   */
  crearBodyBusqueda() {
    let body = {
      correos: this.state.numerosFiltro,
      busqueda: 'MEXPV14020',
    };
    return body;
  }

  /**
   * Creación del formulario para realizar la búsqueda de viajes.
   */
  crearFormularioBusqueda() {
    return (
      <form onSubmit={this.enviar}>
        <div className="p-fluid">{this.crearCamposBusqueda()}</div>
        <Button
          label="Guardar"
          className="p-button p-button-success p-button-rounded"
          icon="pi pi-search"
          iconPos="left"
        />
      </form>
    );
  }
  /**
   * Función principal de react para renderizar los componentes html.
   */
  render() {
    const headerCard = (
      <div style={{ textAlign: 'center' }}>
        <h1>ADMINISTRACIÓN DE CORREOS</h1>
      </div>
    );

    return (
      <div>
        <BlockUi tag="div" blocking={this.state.blocking}>
          <Growl />
          <div className="p-grid">
            <div className="p-col-12">
              <Card header={headerCard} style={{ padding: '1em' }}>
                <div className="p-grid">{this.crearFormularioBusqueda()}</div>
              </Card>
            </div>
          </div>
        </BlockUi>
      </div>
    );
  }
}

export default Correos;
