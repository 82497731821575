import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {IconLookup, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

/**
 * Definir el tipo TooltipOptions.
 */
interface TooltipOptions {
    event?: string;
    position?: string;
    showDelay?: number;
    hideDelay?: number;
    className?: string;
}

/**
 * Definir el tipo IconProp.
 */
type IconProp = IconName | [IconPrefix, IconName] | IconLookup;

/**
 * Definir las propiedades que recibe el componente.
 */
interface Props {
    onlyIcon: boolean;
    buttonStyle: string;
    icon: IconProp;
    text?: string;
    iconPosition?: "left" | "right";
    onClick?: () => void;
    tooltip?: any;
    tooltipOptions?: TooltipOptions;
    [key: string]: any;
};

/**
 * Definir el estado del componente.
 */
interface State {};

/**
 * @class
 * Componente que permite crear un botón personalizado
 * con los iconos del framework Font Awesome.
 */
class ButtonSwf extends Component<Props, State> {
    
    /**
     * @function
     * Función principal para renderizar el contenido del componente.
     */
    render() {
        const onlyIcon = this.props.onlyIcon;
        const buttonStyle = "p-button p-component p-button p-button-rounded "+this.props.buttonStyle;
        const icon  = this.props.icon;
        const text = this.props.text;
        const iconPosition = this.props.iconPosition === "left" ? "p-button-icon-left" : "p-button-icon-right";
        const textPosition = this.props.iconPosition === "left" ? "p-button-text-icon-left" : "p-button-text-icon-right";
        return (
            <div>
                {onlyIcon
                    ? 
                    <button type="button" className={buttonStyle+" p-button-icon-only"}
                        onClick={this.props.onClick}>
                        <span className="p-c p-button-icon-left">
                            <FontAwesomeIcon icon={icon} />
                        </span>
                        <span className="p-button-text p-c">p-btn</span>
                    </button>
                    : 
                    <button className={buttonStyle+" "+textPosition}
                        onClick={this.props.onClick}>
                        <span className={"p-c "+iconPosition}>
                            <FontAwesomeIcon icon={icon} />
                        </span>
                        <span className="p-button-text p-c">{text}</span>
                    </button>
                }
            </div>
        );
    }
}

export default ButtonSwf;
