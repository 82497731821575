import React, { Component, createRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import BlockUi from 'react-block-ui';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';

import { cadenas } from '../../Utils/cadenas';
import { PATHS_API, ROLES, NUM_ROWS_POR_PAGINA, CONFIG_GROWL, 
    EDOS_COGNITO, MILLIS_RESET_DISABLED } from '../../Utils/constants';
import FormularioRegistro from '../../Components/FormularioRegistro';
import { APIGATEWAY } from '../../Api/ApiGateway';
import { ItemDropDownInterface } from '../../Interfaces/ItemDropDown.interface';
import { UsuarioInterface } from '../../Interfaces/Usuario.interface';

/** Definir el tipo del catálogo de Concecionarios. */
interface CatDealer {
    codigo: string;
    nombre: string;
}

/** Definición del estado para el componente. */
interface State {
    usuarios: Array<{}>;
    dialogVisible: boolean;
    id:string,
    nombreCompleto:string;
    correo:string;
    usuario:string;
    blocking:boolean;
    [key: string]: any;
    tituloDialog: string;
    labelBtnAccion: string;
    dealerSeleccionada: ItemDropDownInterface;
    dealers: Array<ItemDropDownInterface>;
    filteredDealers: Array<ItemDropDownInterface>;
}

/**
 * @class
 * Componente de tipo clase para mostrar la pantalla donde se realiza
 * la administración de los usuarios de tipo ADMIN_DEALER.
 */
class AdminDealer extends Component<{}, State> {
    private dataTable: any;
    private growlNotificacion: any;
    constructor(props:any) {
        super(props);
        this.dataTable = createRef(); 
        this.growlNotificacion = createRef(); 
        this.state = {
            usuarios: [],
            dialogVisible: false,
            id:'',
            nombreCompleto:'',
            correo:'',
            telefono:'',
            usuario:'',
            blocking:false,
            tituloDialog:'',
            labelBtnAccion:'',
            dealerSeleccionada: {label:'', value:''},
            dealers: [],
            filteredDealers:[]
        };
        this.export = this.export.bind(this);
        this.limpiarFormulario = this.limpiarFormulario.bind(this);
        this.settearState = this.settearState.bind(this);
        this.obtenerAdminDealer = this.obtenerAdminDealer.bind(this);
        this.showDialogNuevo = this.showDialogNuevo.bind(this);
        this.validarRegistro = this.validarRegistro.bind(this);
        this.registrar = this.registrar.bind(this);
        this.eliminar = this.eliminar.bind(this);
        this.activarDesactivar = this.activarDesactivar.bind(this);
        this.resetear = this.resetear.bind(this);
        this.searchDealers = this.searchDealers.bind(this);
    }

    /** 
     * Limpiar las variables de estado. 
     * */
    limpiarFormulario() {
        this.setState({
            id:'',
            nombreCompleto:'',
            correo:'',
            telefono:'',
            usuario:'',
            dealerSeleccionada: {label:'', value:''}
        });
    }

    /** 
     * Función para acceder al valor de los inputs 
     * del componente FormularioRegistro.
     */
    settearState(id:string, value:any) {
        this.setState({[id]:value});
    }

    /**
     * Se ejecuta antes de renderizar el componente.
     */
    componentDidMount() {
        this.obtenerAdminDealer();   
    }

    /**
     * Obtener los usuarios de tipo ADMIN_DEALER.
     */
    obtenerAdminDealer() {
        const body = {
            usuario: "*",
            rol:"ADMIN_DEALER"
        }
        console.log("AdminDealer -> obtenerAdminDealer -> body", body)
        APIGATEWAY.post(PATHS_API.USUARIOS_API+"/leer", body)
        .then(response => {
            console.log("AdministradorSistema -> obtenerAdministradoresSistema -> response", response)
            this.setState({
                    usuarios: response,
                    blocking:false
                });
        })
        .catch(error => {
            console.log("AdministradorSistema -> obtenerAdministradoresSistema -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, cadenas.ADMINDEALER_ERROR_OBTENER_USUARIOS);
        });
    }

    /**
     * Validar el registro del usuario.
     * @param {React.FormEvent} event Evento submit del formulario.
     */
    validarRegistro(event:React.FormEvent) {
        event.preventDefault();
        let body:UsuarioInterface = {
            nombreCompleto: this.state.nombreCompleto,
            correo: this.state.correo,
            telefono: this.state.telefono,
            usuario: this.state.usuario,
            rol: ROLES.ADMINDEALER,
            codigoEmpresa: this.state.dealerSeleccionada.value
        };
        this.setState({blocking:true});
        console.log("AdminDealer -> validarRegistro -> body", body)
        APIGATEWAY.post(PATHS_API.VALIDAR_USUARIO_SIGNUP, body)
        .then(usuarioValidado => {
            console.log("AdminDealer -> validarRegistro -> usuarioValidado", usuarioValidado)
            this.registrar(usuarioValidado);
        })
        .catch(error => {
            console.log("AdminDealer -> validarRegistro -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, error.response.data);
        });
    }

    /**
     * Registro del usuario.
     * @param {any} body Información del usuario para registrar.
     */
    registrar(body:any) {
        APIGATEWAY.post(PATHS_API.USUARIOS_API+"/crear", body)
        .then(() => {
            this.limpiarFormulario();
            this.setState({
                    blocking:false,
                    dialogVisible:false
                });
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.ACCION_EXITOSA);
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.USUARIO_REGISTRADO+" "+body.correo);
            this.obtenerAdminDealer();
        })
        .catch(error => {
            this.limpiarFormulario(); 
            console.log("AdminDealer -> registrar -> error", error);
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, error.response.data);
        });
    }

/**
     * Eliminar el usuario.
     * @param {any} rowData Instancia del row que pertenece al datatable. 
     */
    eliminar(rowData:any) {
        this.setState({blocking:true});
        let body = {
            usuario: rowData.usuario
        };
        APIGATEWAY.post(PATHS_API.USUARIOS_API+"/borrar", body)
        .then(() => {
            this.setState({
                    blocking:false,
                    dialogVisible:false
                });
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.ACCION_EXITOSA);
            this.obtenerAdminDealer();
        })
        .catch(error => {
            console.log("AdminDealer -> eliminar -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, cadenas.ADMINDEALER_ERROR_ELIMINACION);
        });
    }

    /**
     * Activar y desactivar según sea el caso del usuario de acuerdo a su 
     * propiedad activo.
     * @param {any} rowData Instancia del row que pertenece al datatable.
     */
    activarDesactivar(rowData:any) {
        const body = {
            usuario: rowData.usuario
        };
        const path = rowData.activo ? PATHS_API.USUARIOS_API+"/desactivar" : PATHS_API.USUARIOS_API+"/activar";
        APIGATEWAY.post(path, body)
        .then(response => {
            console.log("AdminDealer -> activarDesactivar -> response", response)
            this.obtenerAdminDealer();
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.ACCION_EXITOSA);
        })
        .catch(error => {
            console.log("AdminDealer -> activarDesactivar -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, cadenas.ACCION_NO_EXITOSA);
        });        
    }
    
    /**
     * Resetear la contraseña del usuario.
     * @param {any} rowData Instancia del row que pertenece al datatable.
     */
    resetear(rowData:any) {
        const body = {
            usuario: rowData.usuario
        };
        APIGATEWAY.post(PATHS_API.USUARIOS_API+"/reset", body)
        .then(response => {
            console.log("AdminDealer -> resetear -> response", response)
            this.obtenerAdminDealer();
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.ACCION_EXITOSA);
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.USUARIO_RESET+" "+rowData.correo);
        })
        .catch(error => {
            console.log("AdminDealer-> resetear -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, cadenas.ACCION_NO_EXITOSA);
        });     
    }

    /**
     * Mostrar la notificación de tipo Growl (componente del framewrok Prime React).
     * @param {string} type Tipo de la notificación. 
     * @param {string} title Título de la notificación.
     * @param {string} message Mensaje de la notificación.
     */
    showNotificacion(type:string, title:string, message:string) {
        this.growlNotificacion.current.show({severity: type, summary: title, detail: message, life:CONFIG_GROWL.LIFE});
    }

    /**
     * Exportar los datos del datatable a formato CSV.
     * @param {any} dataTable Referencia del datatable.
     */
    export() {
        this.dataTable.current.exportCSV();
    }

    /**
     * Desactivar o activar boton de reset para cada usuario
     */
    activarReset(rowData:any) {
        let activar = true;
        if(rowData.activo){
            if(rowData.estado === EDOS_COGNITO.RESET){
                var diff = (new Date().getTime()) - rowData.fechaUltimoReset;
                if(diff > MILLIS_RESET_DISABLED){   
                    activar = false;              
                }
            }else if (rowData.estado !== EDOS_COGNITO.CREADO) {
                activar = false;
            }
            
        }
        return activar;
    }

    /**
     * Mostrar el dialog para registrar usuario.
     */
    showDialogNuevo() {
        this.limpiarFormulario();
        this.setState({blocking:true});
        APIGATEWAY.get(PATHS_API.CAT_CONCESIONARIOS+"/listar")
        .then(response => {
            const dealersDropdown:Array<ItemDropDownInterface> = [];
            response.forEach((element:CatDealer) => dealersDropdown.push({label:element.codigo, value:element.codigo}));
            this.setState({
                blocking:false,
                dealers: dealersDropdown,
                dialogVisible: true, 
                tituloDialog: cadenas.ADMINDEALER_DIALOG_NUEVO_TITULO,
                labelBtnAccion: cadenas.BTN_GUARDAR
            });
        })
        .catch(error => {
            console.log("AdminDealer -> showDialogNuevo -> error", error)
            this.setState({
                blocking:false,
                dialogVisible: false
            });
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, cadenas.ADMINDEALER_ERROR_CAT_CONCESIONARIOS);
        });
    }

    searchDealers(event:any){
        setTimeout(() => {
            let filteredDealers;
            if (!event.query.trim().length) {
                filteredDealers = [...this.state.dealers];
            }
            else {
                filteredDealers = this.state.dealers.filter((dealer) => {
                    return dealer.value.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            this.setState({ filteredDealers });
        }, 250);
    }

    /**
     * @function
     * Función principal para renderizar el contenido del componente.
    */
    render() {
        const headerDatatable = <div className="p-grid">
            <div className="p-col-6" style={{textAlign:"left"}} >
                <Button className="p-button p-button-rounded"  label={cadenas.ADMINDEALER_BTN_NUEVO}
                    onClick={this.showDialogNuevo} />
            </div>
            <div className="p-col-6" style={{textAlign:"right"}} >
                <Button type="button" icon="pi pi-external-link" iconPos="left" label={cadenas.BTN_EXPORTAR_CSV} onClick={this.export} />
            </div>
        </div>
        const headerCard = <div style={{textAlign:"center"}}><h1>{cadenas.ADMINDEALER_VISTA_TITULO}</h1></div>;
        const footerDialog = (
            <div>
                <Button className="p-button p-button-success" label={this.state.labelBtnAccion} icon="pi pi-check" type="submit" />
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times" onClick={(e) => {this.limpiarFormulario(); this.setState({dialogVisible: false});} }/>
            </div>
        );
        const actionDatatable = (rowData:any, column:any) => {
            return <div>
                <Button tooltip={rowData.activo ? cadenas.ADMINDEALER_BTN_DESACTIVAR : cadenas.ADMINDEALER_BTN_ACTIVAR} 
                    tooltipOptions={{position: 'top'}} type="button" 
                    disabled={rowData.estado === EDOS_COGNITO.RESET || rowData.estado === EDOS_COGNITO.CREADO}
                    icon={rowData.activo ? "pi pi-user-minus"  : "pi pi-user-plus"}
                    className={rowData.activo ? "p-button-danger" : "p-button-success"} 
                    style={{marginRight: '.5em'}} 
                    onClick={(e) => {
                        if (window.confirm(cadenas.CONFIRMAR_ACCION)) {
                            this.activarDesactivar(rowData);
                        }
                    }}/>
                <Button tooltip={cadenas.ADMINDEALER_BTN_RESET} tooltipOptions={{position: 'top'}} type="button" 
                    icon="pi pi-key" className="p-button-success" style={{marginRight: '.5em'}} 
                    //disabled={rowData.estado === EDOS_COGNITO.RESET || !rowData.activo}
                    disabled={this.activarReset(rowData)}
                    onClick={(e) => {
                        if (window.confirm(cadenas.CONFIRMAR_ACCION)) {
                            this.resetear(rowData);
                        }
                    }}/>
                <Button tooltip={cadenas.BTN_ELIMINAR} tooltipOptions={{position: 'top'}} type="button" 
                    icon="pi pi-trash" className="p-button-warning"
                    onClick={(e) => {
                        if (window.confirm(cadenas.CONFIRMAR_ACCION)) {
                            this.eliminar(rowData);
                        }
                    }} />
            </div>;
        }
        return  (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking}>
                <Growl ref={this.growlNotificacion} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <Card header={headerCard} style={{padding:"1em"}}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <DataTable value={this.state.usuarios} paginator={true} rows={NUM_ROWS_POR_PAGINA}
                                        header={headerDatatable} responsive={true}
                                        ref={this.dataTable} 
                                        emptyMessage={cadenas.DATATABLE_VACIO}>
                                        <Column field="nombreCompleto" header={cadenas.ADMINDEALER_DATA_NOMBRE_COMPLETO} filter={true} sortable={true} />
                                        <Column field="usuario" header={cadenas.ADMINDEALER_DATA_USUARIO} filter={true} sortable={true} />
                                        <Column field="correo" header={cadenas.ADMINDEALER_DATA_CORREO} filter={true} sortable={true} />
                                        <Column field="rol" header={cadenas.ADMINDEALER_DATA_ROL} filter={true} sortable={true} />
                                        <Column field="codigoEmpresa" header={cadenas.ADMINDEALER_DATA_COD_EMPRESA} filter={true} sortable={true} />
                                        <Column field="estado" header={cadenas.ADMINDEALER_DATA_ESTADO} filter={true} sortable={true} />
                                        <Column body={(rowData:any, column:any) => rowData.activo ? cadenas.ACTIVO_SI : cadenas.ACTIVO_NO} header={cadenas.ADMINDEALER_DATA_ACTIVO} style={{width: '5em'}}/>
                                        <Column  body={actionDatatable} style={{textAlign:'center', width: '10em'}}/>
                                    </DataTable>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <form onSubmit={this.validarRegistro}>
                        <Dialog header={this.state.tituloDialog} visible={this.state.dialogVisible} position="center"
                            blockScroll={true} footer={footerDialog}
                            style={{width: '50vw'}} modal={true} onHide={() => {this.limpiarFormulario(); this.setState({dialogVisible: false});}}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="numConcesionaria" className="p-col-12 p-md-2">{cadenas.ADMINDEALER_DATA_COD_EMPRESA}: *</label>
                                    <div className="p-col-12 p-md-10">
                                        <AutoComplete 
                                            value={this.state.dealerSeleccionada} 
                                            suggestions={this.state.filteredDealers}
                                            completeMethod={this.searchDealers} 
                                            field="label"
                                            onChange={(e) => this.setState({ dealerSeleccionada: e.value })} 
                                            placeholder={cadenas.SELECT_ESCRIBE_CONCESIONARIO} 
                                        />
                                    </div>
                                </div>
                            </div>
                            <FormularioRegistro settearState={this.settearState} stateParent={this.state} />
                        </Dialog>
                    </form>
                </div>
            </BlockUi>
        </div>
        );
    }
}

export default AdminDealer;
