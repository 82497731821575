const jwtDecode = require('jwt-decode');

/**
 * Validar el token del usuario.
 * @param {any} token Token del usuario.
 * @returns {boolean} Verdadero si es valido en otro caso Falso.
 */
export const ValidateToken = (token: any): boolean => {
  if (!token) {
    return false;
  }
  try {
    const decodedJwt: any = jwtDecode(token);
    return decodedJwt.exp >= Date.now() / 1000;
  } catch (e) {
    return false;
  }
};
