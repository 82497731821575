import React, {Component, createRef } from 'react';
import {Dropdown} from 'primereact/dropdown';
import BlockUi from 'react-block-ui';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Card} from 'primereact/card';
import {Growl} from 'primereact/growl';
import {Chips} from 'primereact/chips';
import {Calendar} from 'primereact/calendar';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonSwf from  '../../Components/ButtonSwf';
import {FileUpload} from 'primereact/fileupload';
import { Storage } from 'aws-amplify';
import { Galleria } from 'primereact/galleria';
import { Prompt } from "react-router-dom";
import {TabView,TabPanel} from 'primereact/tabview';
import {DataView, DataViewLayoutOptions} from 'primereact/dataview';

import {
    CONSULTA_VIAJES, CALENDAR_LOCALE,
    ROLES, NUM_ROWS_POR_PAGINA,
    PATHS_API, NUM_IMAGENES_MAX_CHASIS, CAPTURA_ONLINE,
    NUM_IMAGENES_MAX_EVIDENCIA, TAM_MAX_EVIDENCIA, NUM_ARCHIVOS_MAX_REV
} from '../../Utils/constants';
import {cadenas} from '../../Utils/cadenas';
import {getUsuarioLogueado } from '../../Utils/gestionSessionStorage';
import {convertirDate, convertirDateAnioMEs} from '../../Utils/fechas';
import {ItemDropDownInterface} from '../../Interfaces/ItemDropDown.interface';
import {APIGATEWAY} from '../../Api/ApiGateway';
import {ViajeInterface} from '../../Interfaces/Viaje.interface';
import { VisitaInterface } from '../../Interfaces/Visita.interface';
import { ChasisInterface } from '../../Interfaces/Chasis.interface';
import { RANGO_ANIOS, DANIO_CATEGORIA } from '../../Utils/constants';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';

/**
 * @interface
 * Se define el estado del componente.
 */
interface State {
    tiposBusqueda: Array<ItemDropDownInterface>;
    tipoBusquedaSeleccionado: ItemDropDownInterface;
    numerosFiltro: [];
    blocking: boolean;
    numEconomico: string;
    origen: string;
    fechaPartida: Date|Date[]|undefined;
    fechaLlegada: Date|Date[]|undefined;
    viajes: Array<ViajeInterface>;
    viajeSeleccionado: ViajeInterface | undefined;
    visitas: Array<VisitaInterface>;
    visitaSeleccionada: VisitaInterface | undefined;
    chasisSeleccionado: ChasisInterface | undefined;
    chasis: Array<ChasisInterface>;
    pagina: number;
    tamanioPagina: number;
    totalElementos: number;
    dialogInfoVisible: boolean;
    chasisSelected: any;
    indexActivo: number | undefined;
    dialogFaltantesVisible: boolean;
    dialogFaltantesVisibleRead: boolean;
    catalogoFaltantes: Array<ItemDropDownInterface>;
    selectedFaltante: ItemDropDownInterface;
    selectedFaltantes: Array<any>;
    catalogoDaniosArea: Array<ItemDropDownInterface>;
    selectedDanioArea: ItemDropDownInterface;
    dialogDaniosVisible: boolean;
    dialogDaniosVisibleRead:boolean;
    catalogoDaniosTipo: Array<ItemDropDownInterface>;
    selectedDanioTipo: ItemDropDownInterface;
    catalogoDaniosSeveridad: Array<ItemDropDownInterface>;
    selectedDanioSeveridad: ItemDropDownInterface;
    damagesSelected: Array<any>;
    catalogoSiniestros: Array<ItemDropDownInterface>;
    dialogSiniestrosVisible: boolean;
    dialogSiniestrosVisibleRead: boolean;
    selectedSiniestro: ItemDropDownInterface;
    catalogoRechazo: Array<ItemDropDownInterface>;
    selectedRechazo: ItemDropDownInterface;
    dialogSinDescarga: boolean;
    selectedVisita: VisitaInterface | undefined;
    catalogoCategoria: Array<ItemDropDownInterface>;
    selectedCategoria: ItemDropDownInterface;
    dialogGaleriaVisible: boolean;
    images: Array<any>;
    responsiveOptions:Array<any>;
    dialogFileUploadVisible: boolean;
    viajesTemporal: Array<ViajeInterface>;
    comentarioDanioSelected: string;
    comentarioFaltanteSelected: string;
    isEdited: boolean;
    dialogLlegada: boolean;
    dateLlegadaShow: Date | Date[];
    dialogObservaciones: boolean;
    headerObservaciones: string;
    visitaTemp: VisitaInterface | undefined;
    observacionesEntrega: string;
    dialogFinalizarEntrega: boolean;
    dialogFinalizarEntregaConOp: boolean;
    dialogNuevaEvidencia: boolean;
    activarEnvioFile: boolean;
}

/**
 * @class
 * Componentes para realizar consultas de los viajes.
 */
class Emergencia extends Component<{}, State> {
    private dataTableRefViajes: any;
    private dataTableRefVisitas: any;
    private dataTableRefChasis: any;
    private growlError: any;
    private fileUplo:any;

    /**
     * @constructor
     * @param {any} props Son las propiedades del componente-
     */
    constructor(props:any) {
        super(props);
        this.dataTableRefViajes = createRef();
        this.dataTableRefVisitas = createRef();
        this.dataTableRefChasis = createRef();
        this.growlError = createRef();
        this.fileUplo=createRef();
        this.state = {
            viajes: [],
            viajeSeleccionado: undefined,
            visitas: [],
            visitaSeleccionada: undefined,
            chasisSeleccionado: undefined,
            chasis: [],
            tiposBusqueda: [],
            tipoBusquedaSeleccionado: {label:'', value:''},
            numerosFiltro: [],
            blocking:false,
            numEconomico: '',
            origen: '',
            fechaPartida: new Date(),
            fechaLlegada: new Date(),
            pagina: 0,
            tamanioPagina: 0,
            totalElementos: 0,
            dialogInfoVisible: false,
            chasisSelected: undefined,
            indexActivo: undefined,
            dialogFaltantesVisible: false,
            dialogFaltantesVisibleRead: false,
            catalogoFaltantes: [],
            selectedFaltante: {label:'',value:''},
            selectedFaltantes: [],
            catalogoDaniosArea: [],
            selectedDanioArea: {label:'',value:''},
            dialogDaniosVisible: false,
            dialogDaniosVisibleRead: false,
            catalogoDaniosTipo: [],
            selectedDanioTipo: {label:'',value:''},
            catalogoDaniosSeveridad: [],
            selectedDanioSeveridad: {label:'',value:''},
            damagesSelected: [],
            catalogoSiniestros: [],
            dialogSiniestrosVisible: false,
            dialogSiniestrosVisibleRead: false,
            selectedSiniestro: {label:'', value:''},
            catalogoRechazo: [],
            selectedRechazo: {label:'', value:''},
            dialogSinDescarga: false,
            selectedVisita: undefined,
            catalogoCategoria: [
                {label: 'CNA', value: 'CNA'},
                {label: 'CA', value: 'CA'}
            ],
            selectedCategoria: {label:'',value:''},
            dialogGaleriaVisible:false,
            images:[],
            responsiveOptions : [
                {
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '960px',
                    numVisible: 4
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
            dialogFileUploadVisible:false,
            viajesTemporal: [],
            comentarioDanioSelected: '',
            comentarioFaltanteSelected: '',
            isEdited: false,
            dialogLlegada: false,
            dateLlegadaShow: new Date(),
            dialogObservaciones: false,
            headerObservaciones: '',
            visitaTemp: undefined,
            observacionesEntrega: '',
            dialogFinalizarEntrega: false,
            dialogNuevaEvidencia: false,
            activarEnvioFile: true,
            dialogFinalizarEntregaConOp:false
        }
        this.export = this.export.bind(this);
        this.buscarViajes = this.buscarViajes.bind(this);
        this.crearFormularioBusqueda = this.crearFormularioBusqueda.bind(this);
        this.crearCamposBusqueda = this.crearCamposBusqueda.bind(this);
        this.crearFechasBusqueda = this.crearFechasBusqueda.bind(this);
        this.cambiarVista = this.cambiarVista.bind(this);
        this.dataTableViajes = this.dataTableViajes.bind(this);
        this.dataTableVisitas = this.dataTableVisitas.bind(this);
        this.dataTableChasis = this.dataTableChasis.bind(this);
        this.organizarOpcionesPorRol = this.organizarOpcionesPorRol.bind(this);
        this.buscarVisistasPorViaje = this.buscarVisistasPorViaje.bind(this);
        this.buscarChasisPorVisita = this.buscarChasisPorVisita.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.crearCamposBusquedaNumEcoFecha=this.crearCamposBusquedaNumEcoFecha.bind(this);
        this.registrarLlegada = this.registrarLlegada.bind(this);
        this.damageTemplate = this.damageTemplate.bind(this);
        this.onUploadd = this.onUploadd.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.thumbnailTemplate = this.thumbnailTemplate.bind(this);
        this.showGaleriaDanios= this.showGaleriaDanios.bind(this);
        this.showSubirImagenesDanios= this.showSubirImagenesDanios.bind(this);
        this.simularOrderIdIniciodeViaje=this.simularOrderIdIniciodeViaje.bind(this);
    }

    itemTemplate(item:any) {
        return <img src={item}  style={{ width: '100%', display: 'block' }} alt="Img" />
    }

    thumbnailTemplate(item:any) {
        return <img src={item}   style={{ width: '5%', display: 'block' }} alt="Img" />
    }

    /**
     * Evento que se ejecuta antes de renderizar el componente.
     */
    componentDidMount() {
        this.organizarOpcionesPorRol();
    }

    /**
     * Organizar las opciones de búsqueda de acuerdo al perfil logueado.
     */
    organizarOpcionesPorRol() {
        switch (getUsuarioLogueado()?.rol) {
            case ROLES.ADMINSIS:
                this.setState({
                    tiposBusqueda: [
                        //{label: cadenas.CONSULTA_VIAJES_COD_CONFIRM_LABEL, value: CONSULTA_VIAJES.COD_CONFIRM_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_TRK_LABEL, value: CONSULTA_VIAJES.NUM_TRK_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_FECHA_PARTIDA, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_VIN_LABEL, value: CONSULTA_VIAJES.VIN_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE}
                    ]
                });
                break;
            case ROLES.ADMINDEALER:
               this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_VIAJES_RANGO_FECHA_LABEL, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_INICIO_VIAJE_LABEL, value: CONSULTA_VIAJES.INICIO_VIAJE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE}
                    ]
                });
                break;
            case ROLES.ADMINTRANSPORTISTA:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_VIAJES_RANGO_FECHA_LABEL, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_INICIO_VIAJE_LABEL, value: CONSULTA_VIAJES.INICIO_VIAJE}
                    ]
                });
                break;
            case ROLES.SEGUROS:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_VIAJES_NUM_TRK_LABEL, value: CONSULTA_VIAJES.NUM_TRK_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_FECHA_PARTIDA, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_VIN_LABEL, value: CONSULTA_VIAJES.VIN_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE}
                    ]
                });
                break;
            case ROLES.ADMINNSO:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_VIAJES_FECHA_PARTIDA, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_VIN_LABEL, value: CONSULTA_VIAJES.VIN_VALUE}                        
                    ]
                });
                break;
                case ROLES.CONDUCTOR:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_VIAJES_RANGO_FECHA_LABEL, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_INICIO_VIAJE_LABEL, value: CONSULTA_VIAJES.INICIO_VIAJE}
                    ]
                });
                break;

                case ROLES.INSPECTOR:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_VIAJES_RANGO_FECHA_LABEL, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE}
                    ]
                });
                break;
        }
    }

    /**
     * Exportar los datos del datatable a formato CSV.
     * @param {any} dataTable Referencia del datatable.
     */
    export(dataTable: any) {
        dataTable.current.exportCSV();
    }
    /**
     * resetear los datos del datatable.
     * @param {any} dataTable Referencia del datatable.
     */
    resetTable(dataTable: any) {
        dataTable.current.reset();
        console.log('Se reseteo la tabla');
    }

    /**
     * Mostrar notificación de error.
     * @param {string} title Título de la notificación.
     * @param {string} message Mensaje de la notificación.
     */
    showError(title:string, message:string) {
        this.growlError.current.show({severity: 'error', summary: title, detail: message});
    }

    /**
     * Definir el rol que realiza la búsqueda de los viajes.
     * @param {any} body Objeto que se envia al servidor.
     */
    definirRolBusqueda(body: any) {
        switch (getUsuarioLogueado()?.rol) {
            case ROLES.ADMINSIS:
                body.numDealer = '*';
                body.numTransportista = '*';
                break;
            case ROLES.ADMINDEALER:
                body.numDealer = (getUsuarioLogueado()?.codigoEmpresa as string);
                body.numTransportista = '*';
                break;
            case ROLES.ADMINTRANSPORTISTA:
                body.numDealer = '*';
                body.numTransportista = (getUsuarioLogueado()?.codigoEmpresa as string);
                break;
            case ROLES.INSPECTOR:
                body.numDealer = (getUsuarioLogueado()?.codigoEmpresa as string);
                body.numTransportista = '*';
                break;
            case ROLES.CONDUCTOR:
                    body.numDealer = '*';
                    body.numTransportista = (getUsuarioLogueado()?.codigoEmpresa as string);
                break;
        } 
        return body;
    }

    /**
     * Definir las opciones para realizar la búsqueda de los viajes.
     * @param {any} body Objeto que se envia al servidor.
     */
    definirOpcionesBusqueda(body: any) {
        switch (this.state.tipoBusquedaSeleccionado.value) {
            case CONSULTA_VIAJES.RANGO_FECHA_VALUE:
                body.fechaPartida = convertirDateAnioMEs(this.state.fechaPartida as Date);
                console.log(body.fechaPartida);
                body.fechaLlegada = convertirDate(this.state.fechaLlegada as Date);
                break;
            case CONSULTA_VIAJES.NUM_TRK_VALUE:
                body.trks = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.COD_CONFIRM_VALUE:
                body.codigos = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.VIN_VALUE:
                body.vins = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.ORDERID_VALUE:
                body.ordersId = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.NUM_ECONO_VALUE:
                body.numsEconomico = this.state.numerosFiltro;
                body.fechaPartida = convertirDateAnioMEs(this.state.fechaPartida as Date);
                break;
            case CONSULTA_VIAJES.INICIO_VIAJE:
                body.numsEconomico = this.state.numerosFiltro;
                break;
        }
        return body;
    }

    /**
     * Crear el objeto body que se envia al servidor
     * para realizar la búsqueda de viajes.
     */
    crearBodyBusqueda() {
        let body = {
            fechaPartida: '',
            fechaLlegada: '',
            rol: getUsuarioLogueado()?.rol,
            numDealer: '',
            numTransportista: '',
            vins: [],
            trks: [],
            codigos: [],
            numsEconomico: [],
            ordersId: [],
            pagina: 0,
            tamanioPagina: NUM_ROWS_POR_PAGINA,
            filtrosTabla:[],
            daniosFaltantes:'',
        };
        body = this.definirRolBusqueda(body);
        body = this.definirOpcionesBusqueda(body);
        return body;
    }

    /**
     * Buscar viajes con párametros de búsqueda.
     * @param {React.FormEvent} event Evento del formulario.
     */
    buscarViajes(event:React.FormEvent) {
        event.preventDefault();

        if(this.state.isEdited){
            if (window.confirm('Pudiera haber cambios sin guardar, ¿Desea continuar?')) {
                // validacion
                if (this.state.tipoBusquedaSeleccionado.value !== CONSULTA_VIAJES.RANGO_FECHA_VALUE && this.state.tipoBusquedaSeleccionado.value !== CONSULTA_VIAJES.INICIO_VIAJE && this.state.numerosFiltro.length === 0) {
                    this.showError(cadenas.ERROR_TITULO_VALIDACION, cadenas.CONSULTA_VIAJES_ERROR_VALIDACION_NUMS+this.state.tipoBusquedaSeleccionado.label);
                    return;
                }
                this.setState({
                    blocking: true
                });
                console.log("Viajes -> buscarViajes -> this.crearBodyBusqueda()", JSON.stringify(this.crearBodyBusqueda()))
                APIGATEWAY.post(PATHS_API.VIAJES_CONSULTA, this.crearBodyBusqueda() )
                .then(response => {
                    console.log("Viajes -> buscarViajes -> response", response)
                    this.setState({
                        blocking: false,
                        viajes: response.datos,
                        totalElementos: response.totalElementos,
                        pagina: response.pagina*NUM_ROWS_POR_PAGINA, // datatable usa como first, el num del row con cual inicia la pagina seleccionada
                        viajesTemporal: response.datos,
                        isEdited: false,
                    });

                })
                .catch(error => {
                    this.setState({
                        blocking:false,
                        viajes: [],
                        viajesTemporal: [],
                        isEdited: false,
                    });
                    this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_VIAJES);
                });
            }
        } else if (this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.INICIO_VIAJE) {
            this.setState({blocking:true});
            let pathViaje = this.crearPathViaje();
            console.log('Path inicio viaje',pathViaje);

            APIGATEWAY.get(pathViaje)
              .then(response => {
                  console.log("buscarViaje()", response);
                  this.simularOrderIdIniciodeViaje(response);
                  //this.mappingVisitasToViaje(response);
              })
              .catch(error => {
                  this.setState({blocking:false});
                  this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, cadenas.CAPTURA_ONLINE_ERRO_BUSQUEDA);
                  console.error("buscarViaje()",error);
              })


        } else{
            // validacion
            if (this.state.tipoBusquedaSeleccionado.value !== CONSULTA_VIAJES.RANGO_FECHA_VALUE && this.state.tipoBusquedaSeleccionado.value !== CONSULTA_VIAJES.INICIO_VIAJE && this.state.numerosFiltro.length === 0) {
                this.showError(cadenas.ERROR_TITULO_VALIDACION, cadenas.CONSULTA_VIAJES_ERROR_VALIDACION_NUMS+this.state.tipoBusquedaSeleccionado.label);
                return;
            }
            this.setState({
                blocking: true
            });
            console.log("Viajes -> buscarViajes -> this.crearBodyBusqueda()", JSON.stringify(this.crearBodyBusqueda()))
            APIGATEWAY.post(PATHS_API.VIAJES_CONSULTA, this.crearBodyBusqueda() )
            .then(response => {
                console.log("Viajes -> buscarViajes -> response", response)
                this.setState({
                    blocking: false,
                    viajes: response.datos,
                    totalElementos: response.totalElementos,
                    pagina: response.pagina*NUM_ROWS_POR_PAGINA, // datatable usa como first, el num del row con cual inicia la pagina seleccionada
                    viajesTemporal: response.datos,
                });
            })
            .catch(error => {
                this.setState({
                    blocking:false,
                    viajes: [],
                    viajesTemporal: [],
                });
                this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_VIAJES);
            });
        }
    }

    /**
     * Realizar la búsqueda de visitas por el viaje seleccionado.
     * @param {any} viaje Instancia del viaje seleccionado en el datatable de viajes.
     */
    buscarVisistasPorViaje(viaje: any) {
        this.setState({
            blocking: true
        });
        const body = {
            orderId: viaje.orderId
        };
        console.log("Viajes -> buscarVisistasPorViaje -> body", body)
        APIGATEWAY.post(PATHS_API.VISITAS_CONSULTA, body )
        .then(response => {
        console.log("Viajes -> buscarVisistasPorViaje -> response", response)
            this.setState({
                viajeSeleccionado: viaje,
                blocking: false,
                visitas: response
            });
            this.setearVisitas(response, viaje.orderId);

        })
        .catch(error => {
            this.setState({blocking:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_VISITAS);
        });
    }

    setearVisitas(visitas:any, orderId:any){
        let index = this.state.viajesTemporal.findIndex(v => v.orderId === orderId);
        if(this.state.viajesTemporal[index].visits === undefined){
            this.state.viajesTemporal[index].visits = visitas;
        }else{
            this.setState({visitas:this.state.viajesTemporal[index].visits || []})
        }
    }

    /**
     * Realizar la búsqueda de chasis por la visita seleccionada.
     * @param {any} visita Instancia de la visita seleccionada en el datatable de visitas.
     */
    buscarChasisPorVisita(visita: any) {
        console.log("visita seleccionada", visita)
        this.setState({
            blocking: true
        });
        const body = {
            orderId: this.state.viajeSeleccionado?.orderId,
            numDealer: visita.concesionario
        };
        APIGATEWAY.post(PATHS_API.CHASIS_CONSULTA, body )
        .then(response => {
            console.log("Viajes -> buscarChasisPorVisita -> response", response)
            this.setState({
                visitaSeleccionada: visita,
                blocking: false,
                chasis: response
            });
            this.setearChasis(response, this.state.viajeSeleccionado?.orderId, visita.concesionario);
        })
        .catch(error => {
            this.setState({blocking:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_CHASIS);
        });
    }

    setearChasis(chasis:any, orderId:any, concesionario:any){
        this.state.viajesTemporal.forEach((element: any) => {
            if(element.orderId === orderId){
                element.visits.forEach((v:any) =>{
                    if(v.concesionario === concesionario){
                        if(v.shipment === undefined){
                            v.shipment = chasis;
                        }else{
                            this.setState({chasis:v.shipment})
                        }
                    }
                })
            }
        })
    }

    /**
     * Creación de los campos tipo fecha para realizar la búsqueda.
     */
    crearFechasBusqueda() {
        return (
        <div>
            <div className="p-field">
                <label htmlFor="fechaPartida">{cadenas.CONSULTA_VIAJES_FORM_FECHA_MES_ANIO}: *</label>
                <Calendar id="fechaPartida" value={this.state.fechaPartida}
                    locale={CALENDAR_LOCALE}
                    onChange={(e) => this.setState({fechaPartida: e.value})}
                    required={true} showTime={false}
                    monthNavigator={true} yearNavigator={true} yearRange={RANGO_ANIOS}
                    view="month" dateFormat="mm/yy"
                />
            </div>
            {/**
             <div className="p-field">
                <label htmlFor="fechaLlegada">{cadenas.CONSULTA_VIAJES_FORM_FECHA_LLEGADA}: *</label>
                <Calendar id="fechaLlegada" inputId="fechaLlegadaInput" value={this.state.fechaLlegada}
                    locale={CALENDAR_LOCALE} minDate={this.state.fechaPartida as Date}
                    onChange={(e) => this.setState({fechaLlegada: e.value})}
                    required={true} showTime={true}
                    monthNavigator={true} yearNavigator={true} yearRange={RANGO_ANIOS}
                />
            </div>*/}
        </div>
        );
    }

    /**
     * Creación del campo tipo array de textos para realizar la búsqueda.
     */
    crearCamposBusqueda() {
        return (
        <div className="p-field">
            <label htmlFor="numerosFiltro">{cadenas.CONSULTA_VIAJES_FORM_NUMEROS} {this.state.tipoBusquedaSeleccionado.label}: *</label>
            <Chips id="numerosFiltro" value={this.state.numerosFiltro} separator=","
                max={CONSULTA_VIAJES.MAX_NUMEROS_FILTRO} allowDuplicate={false}
                onChange={(e) =>{
                    this.setState({numerosFiltro: e.value})
            }}
            />
            <small id="username-help">{cadenas.CONSULTA_VIAJES_FORM_NUMEROS_HELPER}</small>
        </div>
        );
    }
    /**
     * Creación del campo tipo array de textos para realizar la búsqueda.
     */
    crearCamposBusquedaNumEcoFecha() {
        return (
        <div>
        <div className="p-field">
                <label htmlFor="fechaPartida2">{cadenas.CONSULTA_VIAJES_FORM_FECHA_MES_ANIO}: *</label>
                <Calendar id="fechaPartida2" value={this.state.fechaPartida}
                    locale={CALENDAR_LOCALE}
                    onChange={(e) => this.setState({fechaPartida: e.value})}
                    required={true} showTime={false}
                    monthNavigator={true} yearNavigator={true} yearRange={RANGO_ANIOS}
                    view="month" dateFormat="mm/yy"
                />
            </div>
        <div className="p-field">
            <label htmlFor="numerosFiltro">{cadenas.CONSULTA_VIAJES_FORM_NUMEROS} {this.state.tipoBusquedaSeleccionado.label}: *</label>
            <Chips id="numerosFiltro" value={this.state.numerosFiltro} separator=","
                max={CONSULTA_VIAJES.MAX_NUMEROS_FILTRO} allowDuplicate={false}
                onChange={(e) => this.setState({numerosFiltro: e.value})}
            />
            <small id="username-help">{cadenas.CONSULTA_VIAJES_FORM_NUMEROS_HELPER}</small>
        </div>

        </div>
        );
    }

    /**
     * Creación del campo tipo array de textos para realizar la búsqueda.
     */
    crearCamposBusquedaInicioViaje() {
        return (
          <div>
              <div className="p-fluid">
                  <div className="p-field">
                      <label htmlFor="numEconomico">{cadenas.CAPTURA_ONLINE_FORM_NUM_ECONOMICO}: *</label>
                      <InputText required={true}
                                 value={this.state.numEconomico}
                                 onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                     this.setState({numEconomico: (event.target as HTMLInputElement).value});
                                 }}
                      />
                  </div>
                  <div className="p-formgroup-inline">
                      <div className="p-field-checkbox">
                          <RadioButton inputId="origenPuebla" name="origen"
                                       required={true}
                                       value={CAPTURA_ONLINE.PUEBLA} onChange={(e) => this.setState({origen: e.value})}
                                       checked={this.state.origen === CAPTURA_ONLINE.PUEBLA}
                          />
                          <label htmlFor="origenPuebla">{cadenas.CAPTURA_ONLINE_FORM_PUEBLA}</label>
                      </div>
                      <div className="p-field-checkbox">
                          <RadioButton inputId="origenPuebla" name="origen"
                                       required={true}
                                       value={CAPTURA_ONLINE.VERACRUZ} onChange={(e) => this.setState({origen: e.value})}
                                       checked={this.state.origen === CAPTURA_ONLINE.VERACRUZ}
                          />
                          <label htmlFor="origenPuebla">{cadenas.CAPTURA_ONLINE_FORM_VERACRUZ}</label>
                      </div>
                  </div>
              </div>

          </div>
        );
    }

    /**
     * Creación del formulario para realizar la búsqueda de viajes.
     */
    crearFormularioBusqueda() {
        return (
        <form onSubmit={this.buscarViajes}>
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="tipoBusquedaSeleccionado">{cadenas.CONSULTA_VIAJES_FORM_BUSCAR}: *</label>
                    <Dropdown
                        value={this.state.tipoBusquedaSeleccionado.value} options={this.state.tiposBusqueda}
                        onChange={(e) => {
                            let tipoSeleccionado = this.state.tiposBusqueda.find(tb => tb.value === e.value);
                            this.setState({tipoBusquedaSeleccionado: tipoSeleccionado!})
                        }} required={true}
                        placeholder={cadenas.SELECT_SELECCIONA}
                    />
                </div>
                {this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.RANGO_FECHA_VALUE
                    &&
                    this.crearFechasBusqueda()
                }
                {(this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.COD_CONFIRM_VALUE
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.NUM_TRK_VALUE
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.ORDERID_VALUE
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.VIN_VALUE) &&
                    this.crearCamposBusqueda()
                }
                {(this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.NUM_ECONO_VALUE
                    ) &&
                    this.crearCamposBusquedaNumEcoFecha()
                }
                {(this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.INICIO_VIAJE
                    ) &&
                    this.crearCamposBusquedaInicioViaje()
                }
            </div>
            <Button label={cadenas.CONSULTA_VIAJES_BTN_BUSCAR}
                className="p-button p-button-success p-button-rounded"
                icon="pi pi-search"
                iconPos="left"
                id="submitButton"
            />
        </form>
        );
    }

    /**
     * @returns Datatable para mostrar el resultado de la búsqueda.
     */
    dataTableViajes() {
        const headerDatatable = <div className="p-grid">
            <div className="p-col-6" style={{textAlign:"left"}} >
                <h2>{cadenas.CAPTURA_ONLINE_TITULO_DATATABLE_VIAJES}</h2>
            </div>
            <div className="p-col-6" style={{textAlign:"right"}} >
                <Button type="button" icon="pi pi-external-link" iconPos="left" style={{marginRight: '.5em'}}
                    label={cadenas.BTN_EXPORTAR_CSV} onClick={() => this.export(this.dataTableRefViajes)}
                />
            </div>
        </div>;

        return (
            <div className="p-col-12">
                <DataTable value={this.state.viajes}  paginator={true}
                    rows={NUM_ROWS_POR_PAGINA}
                    header={headerDatatable}  responsive={true}
                    ref={this.dataTableRefViajes}
                    emptyMessage={cadenas.DATATABLE_VACIO}
                    selection={this.state.viajeSeleccionado}
                    onSelectionChange={e => this.buscarVisistasPorViaje(e.value)}
                    lazy={false}>
                    <Column selectionMode="single" style={{width:'3em'}} />
                    <Column field="orderId" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_ORDERID} filter={true} sortable={true} />
                    <Column field="driverName" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_CONDUCTOR} filter={true} sortable={true} />
                    <Column field="estatusViaje" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_ESTATUS} filter={true} sortable={true} />
                    <Column field="leftDate" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_FECHA_REGISTRO} filter={true} sortable={true} />
                </DataTable>
            </div>
        );

    }

    /**
     * @returns Datatatable para mostrar las visitas del viaje encontrado.
     */
    dataTableVisitas() {
        const headerDatatable = <div className="p-grid">
            <div className="p-col-4" style={{textAlign:"left"}} >
                <Button type="button" icon="pi pi-arrow-left"
                    iconPos="left" label={cadenas.BTN_REGRESAR}
                    onClick={
                        () => this.cambiarVista(CONSULTA_VIAJES.VISTA_VISITAS)
                    }
                />
            </div>
            <div className="p-col-4" style={{textAlign:"center"}}>
                <h2>{cadenas.CAPTURA_ONLINE_TITULO_DATATABLE_VISITAS}: {this.state.viajeSeleccionado!.orderId}</h2>
            </div>
            <div className="p-col-4" style={{textAlign:"right"}} >
                <Button type="button" icon="pi pi-external-link" iconPos="left" label={cadenas.BTN_EXPORTAR_CSV} onClick={() => this.export(this.dataTableRefVisitas)} />
            </div>
        </div>;
        const accionesDatatable = (rowData:any, column:any) => {
            return <div>
                {
                (rowData.estatusEntrega === 'ENTREGA_CONFIRMADA' || rowData.estatusEntrega === 'FINALIZADO_TRANSPORTISTA' || rowData.estatusEntrega ==='FINALIZADO_CONCESIONARIO')
                &&
                (getUsuarioLogueado()?.rol === ROLES.ADMINTRANSPORTISTA
                ||
                getUsuarioLogueado()?.rol === ROLES.CONDUCTOR
                ||
                getUsuarioLogueado()?.rol === ROLES.ADMINDEALER
                ||
                getUsuarioLogueado()?.rol === ROLES.INSPECTOR
                ||
                getUsuarioLogueado()?.rol === ROLES.ADMINSIS)
                &&
                <button type="button"
                    className="p-button p-component p-button p-button-rounded p-button-success p-button-icon-only"
                    style={{marginRight: '.5em'}}
                    title={cadenas.CAPTURA_ONLINE_BTN_OBSERVACIONES}
                    onClick={() => this.showDialogObservaciones(rowData)}
                >
                        <span className="p-c p-button-icon-left">
                            <FontAwesomeIcon icon="comment-alt" />
                        </span>
                        <span className="p-button-text p-c">p-btn</span>
                </button>
                }
            </div>;
        }
        const footerDialogSinDescarga = (
            <div>
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check"
                    onClick={(e) => {
                        this.finalizarViaje('COMPLETADO SIN DESCARGA', this.state.selectedVisita);
                        }}/>
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times"
                    onClick={(e) => {
                        this.setState({dialogSinDescarga: false})
                        }} />
            </div>
        );
        const footerDialogObservaciones = (
            <div>
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check"
                    onClick={(e) => {
                        this.enviarObservaciones();
                        }}/>
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times"
                    onClick={(e) => {
                        this.setState({dialogObservaciones: false, headerObservaciones: '', 
                            visitaTemp: undefined, observacionesEntrega:''})
                        }} />
            </div>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <DataTable value={this.state.visitas} paginator={true} rows={NUM_ROWS_POR_PAGINA}
                        header={headerDatatable} ref={this.dataTableRefVisitas}
                        responsive={true} emptyMessage={cadenas.DATATABLE_VACIO}
                        selection={this.state.viajeSeleccionado}
                        onSelectionChange={e => this.buscarChasisPorVisita(e.value)}
                    >
                        <Column selectionMode="single" style={{width:'3em'}}/>
                        <Column field="concesionario" header={cadenas.CAPTURA_ONLINE_VISITAS_DATA_CONCESIONARIA} filter={true} sortable={true} />
                        <Column field="estatusEntrega" header={cadenas.CAPTURA_ONLINE_VISITAS_DATA_ESTATUS_ENTREGA} filter={true} sortable={true} />
                        <Column  body={accionesDatatable} style={{textAlign:'center', width: '15em'}}/>

                    </DataTable>
                </div>
                <Dialog header='Finalizar sin descarga' visible={this.state.dialogSinDescarga} position="center"
                            blockScroll={true} footer={footerDialogSinDescarga} //maximized={true}
                            style={{width: '50vw'}} modal={true}
                            onHide={() => this.setState({dialogSinDescarga: false})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="selectedRechazo">Elija una opción: *</label>
                                    <Dropdown
                                        value={this.state.selectedRechazo.value} options={this.state.catalogoRechazo}
                                        onChange={(e) => {
                                            let tipoSeleccionado = this.state.catalogoRechazo.find(tb => tb.value === e.value);
                                            this.setState({ selectedRechazo: tipoSeleccionado! })
                                        }} required={true}
                                        placeholder={cadenas.SELECT_SELECCIONA}
                                        appendTo={document.body}
                                    />
                                </div>
                            </div>
                </Dialog>
                <Dialog header={this.state.headerObservaciones} visible={this.state.dialogObservaciones} position="center"
                            blockScroll={true} footer={footerDialogObservaciones} 
                            style={{width: '50vw'}} modal={true}
                            onHide={() => this.setState({dialogObservaciones: false})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">                                    
                                    <InputTextarea rows={5} cols={30} autoResize
                                            value={this.state.observacionesEntrega}
                                            maxLength={100}
                                            placeholder='Introduzca comentario (máximo 100 caracteres)'
                                            onChange={(e) => {
                                                this.setState({observacionesEntrega: (e.target as HTMLInputElement).value});
                                            }}
                                        />
                                </div>
                            </div>
                </Dialog>
            </div>
        );
    }

    /**
     * @returns Datatatable para mostrar los chasis de la visita seleccionada.
     */
    dataTableChasis() {
        const headerDatatable = <div className="p-grid">
                                <div className="p-col-4" style={{textAlign:"left"}} >
                                    <Button type="button" icon="pi pi-arrow-left"
                                        iconPos="left" label={cadenas.BTN_REGRESAR}
                                        onClick={
                                            () => this.cambiarVista(CONSULTA_VIAJES.VISTA_CHASIS)
                                        }
                                    />
                                </div>
                                <div className="p-col-4" style={{textAlign:"center"}}>
                                    <h2>{cadenas.CAPTURA_ONLINE_TITULO_DATATABLE_VINS}: {this.state.visitaSeleccionada!.concesionario}</h2>
                                </div>
                                <div className="p-col-4" style={{textAlign:"right"}} >
                                    <Button type="button" icon="pi pi-external-link" iconPos="left" label={cadenas.BTN_EXPORTAR_CSV} onClick={() => this.export(this.dataTableRefChasis)} />

                                </div>

                                <div className="p-col-12" style={{textAlign:"right"}}>
                {
                //se agrego para notones de visitas del div aterior
                this.state.visitaSeleccionada!.fechaLlegada.toString() === ''
                &&
                getUsuarioLogueado()?.rol !== ROLES.ADMINSIS
                &&
                getUsuarioLogueado()?.rol !== ROLES.SEGUROS
                &&
                getUsuarioLogueado()?.rol !== ROLES.ADMINDEALER
                &&
                getUsuarioLogueado()?.rol !== ROLES.INSPECTOR
                &&
                <button type="button"
                    className="p-button p-component p-button p-button-rounded p-button-success p-button-icon-only"
                    style={{marginRight: '.5em'}}
                    title={cadenas.CAPTURA_ONLINE_BTN_REGISTRAR_LLEGADA}
                    onClick={() => {
                        this.setState({dialogLlegada:true, dateLlegadaShow: new Date()})
                        //this.registrarLlegada(this.state.visitaSeleccionada);
                    }}
                >
                        <span className="p-c p-button-icon-left">
                            <FontAwesomeIcon icon="map-marker" />
                        </span>
                        <span className="p-button-text p-c">p-btn</span>
                </button>
                }

                {
                //para resubir evidencia
                this.state.visitaSeleccionada?.longitud.toString() === '0'
                &&
                this.state.visitaSeleccionada?.latitud.toString() === '0'
                &&
                getUsuarioLogueado()?.rol !== ROLES.ADMINSIS
                &&
                getUsuarioLogueado()?.rol !== ROLES.SEGUROS
                &&
                <button type="button"
                    className="p-button p-component p-button p-button-rounded p-button-warning p-button-icon-only"
                    style={{marginRight: '.5em'}}
                    title={cadenas.CAPTURA_ONLINE_BTN_REGISTRAR_NUEVA_EVIDENCIA}
                    onClick={() => {
                        let fecha = this.state.visitaSeleccionada?.fechaLlegada === undefined ? new Date() : new Date(this.state.visitaSeleccionada?.fechaLlegada);
                        this.setState({dialogNuevaEvidencia:true, dateLlegadaShow: fecha})
                        //this.registrarLlegada(this.state.visitaSeleccionada);
                    }}
                >
                        <span className="p-c p-button-icon-left">
                            <FontAwesomeIcon icon="map-marker" color="#fff"/>
                        </span>
                        <span className="p-button-text p-c">p-btn</span>
                </button>
                }

                {
                (this.state.visitaSeleccionada!.fechaLlegada.toString()!=='' && this.state.visitaSeleccionada!.estatusEntrega !== 'EN CURSO')
                &&
                this.state.visitaSeleccionada!.estatusEntrega !== 'COMPLETADO SIN DESCARGA'
                &&
                (this.state.visitaSeleccionada!.estatusEntrega !== 'ENTREGA_CONFIRMADA' && this.state.visitaSeleccionada!.estatusEntrega !== 'FINALIZADO_TRANSPORTISTA' && this.state.visitaSeleccionada!.estatusEntrega !== 'FINALIZADO_CONCESIONARIO')
                &&
                getUsuarioLogueado()?.rol !== ROLES.ADMINSIS
                &&
                getUsuarioLogueado()?.rol !== ROLES.SEGUROS
                &&
                this.validarBotonFinalizado()
                &&
                <button type="button"
                    className="p-button p-component p-button p-button-rounded p-button-success p-button-icon-only"
                    style={{marginRight: '.5em'}}
                    title={cadenas.CAPTURA_ONLINE_BTN_FINALIZACION_SIN_DESCARGA}
                    onClick={() => {
                        if(this.state.visitaSeleccionada!.fechaLlegada.toString()!==''){
                            if(this.state.visitaSeleccionada!.shipment !== undefined){
                                this.showDialogSinDescarga(this.state.visitaSeleccionada);
                            }else{
                                window.alert('Primero debe obtener los vins para esta entrega');
                            }
                        }else{
                            window.alert('Primero debe registrar su llegada');
                        }

                    }}
                >
                        <span className="p-c p-button-icon-left">
                            <FontAwesomeIcon icon="store-alt-slash" />
                        </span>
                        <span className="p-button-text p-c">p-btn</span>
                </button>
                }

            {
            (this.state.visitaSeleccionada!.fechaLlegada.toString() !=='' && this.state.visitaSeleccionada!.estatusEntrega !== 'EN CURSO' )
                &&
                this.state.visitaSeleccionada!.estatusEntrega !== 'COMPLETADO SIN DESCARGA'
                &&
                (this.state.visitaSeleccionada!.estatusEntrega !== 'ENTREGA_CONFIRMADA')
                &&
                getUsuarioLogueado()?.rol !== ROLES.ADMINSIS
                &&
                getUsuarioLogueado()?.rol !== ROLES.SEGUROS
                &&
                this.validarBotonFinalizado()
                &&
            <button type="button" 
                className="p-button p-component p-button p-button-rounded p-button-success p-button-icon-only"
                style={{display:  (this.state.visitaSeleccionada!.estatusEntrega==="ENTREGA_CONFIRMADA"  || (this.state.visitaSeleccionada!.estatusEntrega==="FINALIZADO_CONCESIONARIO" && (getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER || getUsuarioLogueado()?.rol=== ROLES.INSPECTOR) || (this.state.visitaSeleccionada!.estatusEntrega==="FINALIZADO_TRANSPORTISTA" && (getUsuarioLogueado()?.rol=== ROLES.ADMINTRANSPORTISTA || getUsuarioLogueado()?.rol=== ROLES.CONDUCTOR)))) ? "none" : "inline-block"}}
                title={cadenas.CAPTURA_ONLINE_BTN_FINALIZACION}
                onClick={() => {
                    if(this.state.visitaSeleccionada!.fechaLlegada.toString()!=='' ){
                        if(this.state.visitaSeleccionada!.shipment !== undefined){
                            let todos = true;
                            this.state.visitaSeleccionada!.shipment.forEach((element:any) => {
                                if(element.estadoChasis === ''){
                                    todos = false;
                                }
                            });
                            if(todos){
                                //this.finalizarViaje('ENTREGA_CONFIRMADA',this.state.visitaSeleccionada);
                                if(getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER){
                                    this.showDialogFinalizarEntregaConOp(this.state.visitaSeleccionada,);
                                }else{
                                    this.showDialogFinalizarEntrega(this.state.visitaSeleccionada);
                                }
                            }else{
                                window.alert('Debe confirmar todos los vins para finalizar')
                            }
                        }else{
                            window.alert('Primero debe obtener los vins para esta entrega');
                        }

                    }else{
                        window.alert('Primero debe registrar su llegada');
                    }
                }}
            >
                    <span className="p-c p-button-icon-left">
                        <FontAwesomeIcon icon="truck" />
                    </span>
                    <span className="p-button-text p-c">p-btn</span>
            </button>
        //div con punto y coma se agrego para botones
        }

            </div>

                            </div>;
        const footerDialog = (
            <div style={{ display:  (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)? "none" : "block"   }} >
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check"
                    onClick={(e) => {
                        this.guardarFaltantes();
                        }}/>
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times"
                    onClick={(e) => {
                        this.setState({dialogFaltantesVisible: false,selectedFaltantes:[],chasisSeleccionado:undefined})
                        }} />
            </div>
        );
        const footerDialogDanios = (
            <div style={{ display:  (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)? "none" : "block"   }} >
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check" 
                    onClick={(e) => {
                        this.guardarDanios();
                        }}/>
                
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times"
                    onClick={(e) => {
                        this.setState({dialogDaniosVisible: false, selectedDanioArea:{label:'',value:''},
                            selectedDanioTipo:{label:'',value:''}, selectedDanioSeveridad:{label:'',value:''},
                            selectedCategoria:{label:'',value:''}, comentarioDanioSelected:''})
                        }} />
            </div>
        );
        const footerDialogSiniestro = (
            <div>
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check"
                    onClick={(e) => {
                        this.guardarSiniestro();
                        }}/>
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times"
                    onClick={(e) => {
                        this.setState({dialogSiniestrosVisible: false})
                        }} />
            </div>
        );
        const catalogoTemplate = (row:any) => {
            return (
                <div className="p-clearfix">
                    <div style={{fontSize: '14px', float: 'right', margin: '10px 10px 0 0'}}>{row.label}</div>
                </div>
            );
        }
        const accionesDatatable = (rowData:any, column:any) => {
            return <div>
                {
                (this.state.visitaSeleccionada!.estatusEntrega === 'COMPLETADO'|| (this.state.visitaSeleccionada!.estatusEntrega === 'ENTREGA_CONFIRMADA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_TRANSPORTISTA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_CONCESIONARIO' ))
                &&
                (!rowData.siniestrados)
                &&
                true//rowData.estadoChasis === ''
                &&
                getUsuarioLogueado()?.rol !== ROLES.ADMINSIS
                &&
                getUsuarioLogueado()?.rol !== ROLES.SEGUROS
                &&
                <button type="button"
                    className="p-button p-component p-button p-button-rounded p-button-success p-button-icon-only"
                    style={{marginRight: '.5em', visibility: (this.state.visitaSeleccionada!.estatusEntrega === 'ENTREGA_CONFIRMADA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_TRANSPORTISTA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_CONCESIONARIO') && (getUsuarioLogueado()?.rol!== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER) ?'hidden':'visible' }}
                    title={cadenas.CAPTURA_ONLINE_BTN_DANIOS}
                    onClick={() => {

                        if((this.state.visitaSeleccionada!.estatusEntrega === 'ENTREGA_CONFIRMADA'|| this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_TRANSPORTISTA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_CONCESIONARIO') && (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER) ){
                            this.showDialogDaniosRead(rowData);
                        }else{
                        if(rowData.estadoChasis === 'CONFIRMADO'){
                            window.alert('Vin confirmado, no puede agregar daños');
                        }else{
                            if(rowData.siniestrados){
                                window.alert('Vehiculo siniestrado, no puede agregar daños');
                            }else{
                                this.showDialogDanios(rowData);
                            }
                        }
                    }
                    }}
                >
                        <span className="p-c p-button-icon-left">
                            <FontAwesomeIcon icon="car-crash" />
                        </span>
                        <span className="p-button-text p-c">p-btn</span>
                </button>
                }

                {
                (this.state.visitaSeleccionada!.estatusEntrega === 'COMPLETADO' || (this.state.visitaSeleccionada!.estatusEntrega === 'ENTREGA_CONFIRMADA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_TRANSPORTISTA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_CONCESIONARIO'))
                &&
                (!rowData.siniestrados)
                &&
                true//rowData.estadoChasis === ''
                &&
                getUsuarioLogueado()?.rol !== ROLES.ADMINSIS
                &&
                getUsuarioLogueado()?.rol !== ROLES.SEGUROS
                &&
                <button type="button"
                    className="p-button p-component p-button p-button-rounded p-button-success p-button-icon-only"
                    style={{marginRight: '.5em',visibility: (this.state.visitaSeleccionada!.estatusEntrega === 'ENTREGA_CONFIRMADA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_TRANSPORTISTA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_CONCESIONARIO') && (getUsuarioLogueado()?.rol!== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER) ?'hidden':'visible' }}
                    title={cadenas.CAPTURA_ONLINE_BTN_FALTANTES}
                    onClick={() => {
                        if((this.state.visitaSeleccionada!.estatusEntrega === 'ENTREGA_CONFIRMADA'|| this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_TRANSPORTISTA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_CONCESIONARIO') && (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER) ){
                            this.showDialogFaltantesRead(rowData);
                        }else{
                        if(rowData.estadoChasis === 'CONFIRMADO'){
                            window.alert('Vin confirmado, no puede agregar faltantes');
                        }else{
                            if(rowData.siniestrados){
                                window.alert('Vehiculo siniestrado, no puede agregar faltantes');
                            }else{
                                this.showDialogFaltantes(rowData);
                            }
                        }
                    }

                    }}
                >
                        <span className="p-c p-button-icon-left">
                            <FontAwesomeIcon icon="exclamation-circle" />
                        </span>
                        <span className="p-button-text p-c">p-btn</span>
                </button>
                }

                {
                (this.state.visitaSeleccionada!.estatusEntrega === 'COMPLETADO' || (this.state.visitaSeleccionada!.estatusEntrega === 'ENTREGA_CONFIRMADA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_TRANSPORTISTA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_CONCESIONARIO'))
                &&
                true//(rowData.siniestrados)
                &&
                true//rowData.estadoChasis !== ''
                &&
                getUsuarioLogueado()?.rol !== ROLES.ADMINSIS
                &&
                getUsuarioLogueado()?.rol !== ROLES.SEGUROS
                &&
                <button type="button" 
                    className="p-button p-component p-button p-button-rounded p-button-success p-button-icon-only"
                    style={{marginRight: '.5em', visibility: (this.state.visitaSeleccionada!.estatusEntrega === 'ENTREGA_CONFIRMADA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_TRANSPORTISTA' || this.state.visitaSeleccionada!.estatusEntrega === 'FINALIZADO_CONCESIONARIO') && (getUsuarioLogueado()?.rol!== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER )?'hidden':'visible' } }
                    title={cadenas.CAPTURA_ONLINE_BTN_SINIESTRO}
                    onClick={() =>{
                        if(rowData.estadoChasis === 'CONFIRMADO'){
                            window.alert('Vin confirmado, no puede realizar esta acción');
                        }else{
                            if((getUsuarioLogueado()?.rol!== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER )&& (this.state.visitaSeleccionada!.estatusEntrega !== 'ENTREGA_CONFIRMADA' &&  this.state.visitaSeleccionada!.estatusEntrega !== 'FINALIZADO_TRANSPORTISTA' && this.state.visitaSeleccionada!.estatusEntrega !== 'FINALIZADO_CONCESIONARIO')){
                                this.showDialogSiniestros(rowData);
                            }
                            else if((getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)){
                                this.showDialogSiniestrosRead(rowData);
                            }
                            else{
                                window.alert('No está permitida esta acción');
                            }
                        }
                    } }
                >
                        <span className="p-c p-button-icon-left">
                            <FontAwesomeIcon icon="ban" />
                        </span>
                        <span className="p-button-text p-c">p-btn</span>
                </button>

                }

                {
                (this.state.visitaSeleccionada!.estatusEntrega === 'COMPLETADO')
                &&
                rowData.estadoChasis === ''
                &&
                getUsuarioLogueado()?.rol !== ROLES.ADMINSIS
                &&
                getUsuarioLogueado()?.rol !== ROLES.SEGUROS
                &&
                <button type="button"
                    className="p-button p-component p-button p-button-rounded p-button-success p-button-icon-only"
                    title={cadenas.CAPTURA_ONLINE_BTN_CONFIRMAR}
                    onClick={() => {
                        if (window.confirm(cadenas.CONFIRMAR_ACCION)) {
                            this.confirmarVin(rowData);
                        }
                    }}
                >
                        <span className="p-c p-button-icon-left">
                            <FontAwesomeIcon icon="check-circle" />
                        </span>
                        <span className="p-button-text p-c">p-btn</span>
                </button>
                }

            </div>;
        }
        const actionDataTableDanios = (rowData: any, column: any) => {
            return <div>
                <Button tooltip={cadenas.BTN_ELIMINAR} tooltipOptions={{ position: 'right' }}  style={{display:  (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)? "none" : "block"}} type="button" icon="pi pi-trash" className="p-button-warning"
                    onClick={(e) => {
                        this.eliminarDanios(rowData);
                        /*if (window.confirm(cadenas.CONFIRMAR_ACCION)) {
                            this.eliminarDanios(rowData);
                        }*/
                    }} />
            </div>;
        }
        const actionDataTableFaltantes = (rowData: any, column: any) => {
            return <div>
                <Button tooltip={cadenas.BTN_ELIMINAR} tooltipOptions={{ position: 'right' }} style={{display:  (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)? "none" : "block"}} type="button" icon="pi pi-trash" className="p-button-warning"
                    onClick={(e) => {
                        this.eliminarFaltantes(rowData);
                    }} />
            </div>;
        }
        const obtenerNombreSiniestro = () =>{
            var motivoSiniestro = JSON.parse(this.state.chasisSelected.siniestro)
            return motivoSiniestro.nombre;
        }
        const renderListDanio = (danio:any, layout:any) => {
            return (
                <div className="p-col-12">
                    <div style={{
                        padding: '1em',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div>
                            <div className="p-grid">
                                <div className="p-col-12">Area: <b>{danio.area}</b></div>
                                <div className="p-col-12">Nombre: <b>{danio.areaNombre}</b></div>
                                <div className="p-col-12">Tipo: <b>{danio.type}</b></div>
                                <div className="p-col-12">Nombre: <b>{danio.typeNombre}</b></div>
                                <div className="p-col-12">Severidad: <b>{danio.severity}</b></div>
                                <div className="p-col-12">Nombre: <b>{danio.severityNombre}</b></div>
                                <div className="p-col-12">Categoria: <b>{danio.category}</b></div>
                                <div className="p-col-12">Comentario: <b>{danio.comentario}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        const renderListFaltante = (faltante:any, layout:any) => {
            return (
                <div className="p-col-12">
                    <div style={{
                        padding: '1em',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div>
                            <div className="p-grid">
                                <div className="p-col-12">Código: <b>{faltante.code}</b></div>
                                <div className="p-col-12">Nombre: <b>{faltante.nombre}</b></div>
                                <div className="p-col-12">Comentario: <b>{faltante.comentario}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const actionDatatableGalleria = (rowData:any, column:any) => {
            return <div>
                <Button tooltip={cadenas.SUBIR_IMAGENES_DANIOS} tooltipOptions={{position: 'top'}} type="button"
                    icon="pi pi-camera" className="p-button-success" style={{marginRight: '.5em'}}
                    //disabled={rowData.danios === undefined || rowData.danios.length <= 0}
                    disabled={this.activarSubirImagenes(rowData)}
                    onClick={(e) => {
                        this.showSubirImagenesDanios(rowData);

                    }}/>
                <Button tooltip={cadenas.VER_IMAGENES_DANIOS} tooltipOptions={{position: 'top'}} type="button"
                    icon="pi pi-image" className="p-button-success" style={{marginRight: '.5em'}}
                    //disabled={rowData.danios === undefined || rowData.danios.length <= 0}
                    disabled={this.activarGaleria(rowData)}
                    onClick={(e) => {
                        this.showGaleriaDanios(rowData,this.state.viajeSeleccionado!.orderId,this.state.visitaSeleccionada!.concesionario);

                    }}/>
            </div>;
        }
        const footerDialogSubirImagen = (
            <div>
                <Button type="button" className="p-button p-button-info" label={cadenas.BOTON_CERRAR} icon="pi pi-times-circle"
                    onClick={(e) => {
                        this.setState({dialogFileUploadVisible: false})
                        }} />
            </div>
        );
        //botones de visitas
        const footerDialogSinDescarga = (
            <div>
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check"
                    onClick={(e) => {
                        this.finalizarViaje('COMPLETADO SIN DESCARGA', this.state.selectedVisita);
                        }}/>
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times"
                    onClick={(e) => {
                        this.setState({dialogSinDescarga: false})
                        }} />
            </div>
        );
        //botones dialog llegada a concesionaria
        const footerDialogLlegada = (
            <div>
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check"
                    disabled={this.state.activarEnvioFile}
                    onClick={(e) => {
                        this.registrarLlegada(this.state.visitaSeleccionada);
                        }}/>
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times"
                    onClick={(e) => {
                        this.setState({dialogLlegada: false, activarEnvioFile:true})
                        }} />
            </div>
        );

        //botones dialog finalizar entrega
        const footerDialogFinalizarEntrega = (
            <div>
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check"
                    disabled={this.state.activarEnvioFile}
                    onClick={(e) => {
                        this.finalizarViaje(this.state.visitaSeleccionada!.estatusEntrega=='COMPLETADO'?'FINALIZADO_TRANSPORTISTA':'ENTREGA_CONFIRMADA',this.state.visitaSeleccionada);
                        }}/>
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times"
                    onClick={(e) => {
                        this.setState({dialogFinalizarEntrega: false, activarEnvioFile:true})
                        }} />
            </div>
        );

        const footerDialogFinalizarEntregaConOp = (
            <div>
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check"
                    onClick={(e) => {
                        this.finalizarViajeConOp(this.state.visitaSeleccionada!.estatusEntrega=='COMPLETADO'?'FINALIZADO_CONCESIONARIO':'ENTREGA_CONFIRMADA',this.state.visitaSeleccionada);
                        }}/>
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times"
                    onClick={(e) => {
                        this.setState({dialogFinalizarEntregaConOp: false, activarEnvioFile:true})
                        }} />
            </div>
        );

        //botones dialog resubir evidencia
        const footerDialogNuevaEvidencia = (
            <div>
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check"
                    disabled={this.state.activarEnvioFile}
                    onClick={(e) => {
                        this.enviarNuevaEvidencia();
                        }}/>
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times"
                    onClick={(e) => {
                        this.setState({dialogNuevaEvidencia: false, activarEnvioFile:true})
                        }} />
            </div>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <DataTable value={this.state.chasis} paginator={true} rows={NUM_ROWS_POR_PAGINA}
                        header={headerDatatable} ref={this.dataTableRefChasis}
                        responsive={true}
                    >
                        <Column field="vin" header={cadenas.CAPTURA_ONLINE_VINS_DATA_VIN} filter={true} sortable={true} />
                        <Column field="model" header={cadenas.CAPTURA_ONLINE_VINS_DATA_MODELO} filter={true} sortable={true} />
                        <Column field="modelsize" header={cadenas.CAPTURA_ONLINE_VINS_DATA_TAMANIO} filter={true} sortable={true} />
                        <Column field="color" header={cadenas.CAPTURA_ONLINE_VINS_DATA_COLOR} filter={true} sortable={true} />
                        <Column field="estadoChasis" header={cadenas.CAPTURA_ONLINE_VINS_DATA_ESTATUS} filter={true} sortable={true} />
                        <Column  body={accionesDatatable} style={{textAlign:'center', width: '15em'}}/>
                        <Column  body={actionDatatableGalleria} style={{textAlign:'center', width: '10em'}}/>
                    </DataTable>
                </div>

                <Dialog header='Faltantes' visible={this.state.dialogFaltantesVisible} position="center"
                    blockScroll={true} footer={footerDialog} //maximized={true}
                    style={{ width: '80vw' }} modal={true}
                    onHide={() => this.setState({ dialogFaltantesVisible: false, selectedFaltantes: [] })}>
                    <div className="p-fluid">
                        <div className="p-grid p-md-12 p-sm-12">
                            <div className="p-col-4 p-md-4 p-sm-4" style={{ display:  (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)? "none" : "block"   }}>
                                <label htmlFor="selectedFaltante">Area: *</label>
                                <Dropdown
                                    value={this.state.selectedFaltante.value} options={this.state.catalogoFaltantes}
                                    onChange={(e) => {
                                        let tipoSeleccionado = this.state.catalogoFaltantes.find(tb => tb.value === e.value);
                                        this.setState({ selectedFaltante: tipoSeleccionado! })
                                    }} required={true}
                                    placeholder={cadenas.SELECT_SELECCIONA}
                                />
                                <label htmlFor="comentarioFaltanteSelected">Comentario: </label>
                                <InputTextarea rows={5} cols={30} autoResize
                                    value={this.state.comentarioFaltanteSelected}
                                    onChange={(e) => {
                                        this.setState({ comentarioFaltanteSelected: (e.target as HTMLInputElement).value });
                                    }}
                                />
                                <div className="p-col-6 p-md-6 p-sm-6 p-col-offset-3 p-md-offset-3 p-sm-offset-3">
                                    <ButtonSwf  onlyIcon={false} buttonStyle="p-button-success" style={{ display:  (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)? "none" : "block"   }}
                                        icon="plus" text='Agregar' iconPosition="left"
                                        onClick={() => {
                                            
                                                this.agregarFaltante();
                                            
                                        }}
                                    />
                                </div>

                            </div>
                            <div  className={(getUsuarioLogueado()?.rol=== ROLES.INSPECTOR|| getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)? "p-col-12 p-md-12 p-sm-12" : "p-col-8 p-md-8 p-sm-8"  }>
                                <DataTable value={this.state.selectedFaltantes} paginator={true} rows={5}
                                    header='Lista de Faltantes' responsive={true}
                                    emptyMessage={cadenas.DATATABLE_VACIO}
                                >
                                    <Column field="code" header='Código' filter={true} sortable={true} />
                                    <Column field="nombre" header='Nombre' filter={true} sortable={true} />
                                    <Column field="comentario" header='Comentario' filter={true} sortable={true} />
                                    <Column body={actionDataTableFaltantes} style={{ textAlign: 'center', width: '4em' }} />
                                </DataTable>

                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header='Daños' visible={this.state.dialogDaniosVisible} position="center"
                            blockScroll={true} footer={footerDialogDanios}
                            style={{width: '80vw'}} modal={true}
                            onHide={() => this.setState({dialogDaniosVisible: false, selectedDanioArea:{label:'',value:''},
                                            selectedDanioTipo:{label:'',value:''}, selectedDanioSeveridad:{label:'',value:''},
                                            selectedCategoria:{label:'',value:''}, comentarioDanioSelected:''})}>
                            <div className="p-fluid">

                                    <div className="p-grid p-md-12 p-sm-12" >


                                    <div className="p-col-4 p-md-4 p-sm-4" style={{ display:  (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)? "none" : "block"   }} >
                                        <label htmlFor="selectedDanioArea">Area: *</label>
                                        <Dropdown
                                            value={this.state.selectedDanioArea.value} options={this.state.catalogoDaniosArea}
                                            onChange={(e) => {
                                                let tipoSeleccionado = this.state.catalogoDaniosArea.find(tb => tb.value === e.value);
                                                this.setState({selectedDanioArea: tipoSeleccionado!})
                                            }} required={true}
                                            placeholder={cadenas.SELECT_SELECCIONA}
                                        />
                                        <label htmlFor="selectedDanioTipo">Tipo: *</label>
                                        <Dropdown
                                            value={this.state.selectedDanioTipo.value} options={this.state.catalogoDaniosTipo}
                                            onChange={(e) => {
                                                let tipoSeleccionado = this.state.catalogoDaniosTipo.find(tb => tb.value === e.value);
                                                this.setState({selectedDanioTipo: tipoSeleccionado!})
                                            }} required={true}
                                            //disabled={true}
                                            placeholder={cadenas.SELECT_SELECCIONA}
                                        />
                                        <label htmlFor="selectedDanioSeveridad">Severidad: *</label>
                                        <Dropdown
                                            value={this.state.selectedDanioSeveridad.value} options={this.state.catalogoDaniosSeveridad}
                                            onChange={(e) => {
                                                let tipoSeleccionado = this.state.catalogoDaniosSeveridad.find(tb => tb.value === e.value);
                                                this.setState({selectedDanioSeveridad: tipoSeleccionado!})
                                            }} required={true}
                                            //disabled={true}
                                            placeholder={cadenas.SELECT_SELECCIONA}
                                        />
                                        <label htmlFor="comentarioDanioSelected">Comentario: </label>
                                        <InputTextarea rows={5} cols={30} autoResize
                                            value={this.state.comentarioDanioSelected}
                                            onChange={(e) => {
                                                this.setState({comentarioDanioSelected: (e.target as HTMLInputElement).value});
                                            }}
                                        />
                                        <div className="p-col-6 p-md-6 p-sm-6 p-col-offset-3 p-md-offset-3 p-sm-offset-3">
                                            <ButtonSwf style={{ display:  (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)? "none" : "block"   }} onlyIcon={false} buttonStyle="p-button-success"
                                                icon="plus" text='Agregar' iconPosition="left" 
                                                onClick={() => {
                                                   
                                                        this.agregarDanios();
                                                    
                                                } }
                                            />
                                        </div>

                                    </div>
                                    <div  className={(getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)? "p-col-12 p-md-12 p-sm-12" : "p-col-8 p-md-8 p-sm-8"  }>
                                        <DataTable value={this.state.damagesSelected}  paginator={true} rows={5}
                                            header='Lista de Daños'  responsive={true}
                                            emptyMessage={cadenas.DATATABLE_VACIO}
                                        >
                                            <Column field="nombreArea" header='Area' filter={true} sortable={true} />
                                            <Column field="nombreTipo" header='Tipo' filter={true} sortable={true} />
                                            <Column field="nombreSeveridad" header='Severidad' filter={true} sortable={true} />
                                            <Column field="comentario" header='Comentario' filter={true} sortable={true} />
                                            <Column  body={actionDataTableDanios} style={{textAlign:'center', width: '4em'}}/>
                                        </DataTable>

                                    </div>
                                    </div>

                            </div>
                </Dialog>

                <Dialog header='Siniestros' visible={this.state.dialogSiniestrosVisible} position="center"
                            blockScroll={true} footer={footerDialogSiniestro} //maximized={true}
                            style={{width: '50vw'}} modal={true}
                            onHide={() => this.setState({dialogSiniestrosVisible: false})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                        <label htmlFor="selectedSiniestro">Elija una opción: *</label>
                                        <Dropdown
                                            value={this.state.selectedSiniestro.value} options={this.state.catalogoSiniestros}
                                            onChange={(e) => {
                                                let tipoSeleccionado = this.state.catalogoSiniestros.find(tb => tb.value === e.value);
                                                this.setState({ selectedSiniestro: tipoSeleccionado! })
                                            }} required={true}
                                            placeholder={cadenas.SELECT_SELECCIONA}
                                            appendTo={document.body}
                                        />
                                </div>
                            </div>
                </Dialog>


    
                <Dialog header={this.state.chasisSelected?.vin} visible={this.state.dialogSiniestrosVisibleRead} position="center"
                            blockScroll={true} //footer={footerDialogSiniestro} //maximized={true}
                            style={{width: '60vw'}} modal={true} 
                            onHide={() => this.setState({dialogSiniestrosVisibleRead: false})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                {
                                    this.state.chasisSelected?.siniestrados
                                    ?
                                    <div className="p-col-12">
                                        <div style={{
                                            padding: '1em',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                            <div>
                                                <div className="p-grid">
                                                    <div className="p-col-12">Vin Siniestrado</div>
                                                    <div className="p-col-12">Motivo: <b>{obtenerNombreSiniestro()}</b></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                              
                                    :
                                    <div>
                                    <p>No hay datos</p>
                                </div>
                                }
                                </div>
                            </div>
                </Dialog>


                <Dialog header={this.state.chasisSelected?.vin} visible={this.state.dialogDaniosVisibleRead} position="center"
                            blockScroll={true} //footer={footerDialogSiniestro} //maximized={true}
                            style={{width: '60vw'}} modal={true} 
                            onHide={() => this.setState({dialogDaniosVisibleRead: false})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                <TabView activeIndex={this.state.indexActivo} onTabChange={(e) => this.setState({indexActivo: e.index})}>
                                    <TabPanel header="Daños">
                                        {
                                            this.state.chasisSelected?.danios !== undefined
                                            ?
                                            <DataView value={JSON.parse(this.state.chasisSelected?.danios)} 
                                                layout='list'
                                                //header={header}
                                                itemTemplate={renderListDanio} 
                                            />
                                            :
                                            <div>
                                                <p>No hay daños</p>
                                            </div>
                                        }
                                    </TabPanel>
                                </TabView>
                                </div>
                            </div>
                </Dialog>

                <Dialog header={this.state.chasisSelected?.vin} visible={this.state.dialogFaltantesVisibleRead} position="center"
                            blockScroll={true} //footer={footerDialogSiniestro} //maximized={true}
                            style={{width: '60vw'}} modal={true} 
                            onHide={() => this.setState({dialogFaltantesVisibleRead: false})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                <TabView activeIndex={this.state.indexActivo} onTabChange={(e) => this.setState({indexActivo: e.index})}>
                                
                                    <TabPanel header="Faltantes">
                                        {
                                            this.state.chasisSelected?.faltantes !== undefined
                                            ?
                                            <DataView value={JSON.parse(this.state.chasisSelected?.faltantes)} 
                                                layout='list'
                                                //header={header}
                                                itemTemplate={renderListFaltante} 
                                            />
                                            :
                                            <div>
                                                <p>No hay faltantes</p>
                                            </div>
                                        }
                                    </TabPanel>
                                </TabView>
                                </div>
                            </div>
                </Dialog>



                <Dialog header='Galeria daños' visible={this.state.dialogGaleriaVisible} position="center"
                    blockScroll={true} //footer={footerDialogSiniestro} maximized={true}
                    style={{ width: '50vw' }} modal={true}
                    onHide={() => this.setState({ dialogGaleriaVisible: false,
                        images:[] })}>
                    <div>
                        <div>{
                            this.state.images.length <= 0 ?
                                <div className="card">
                                    <Card >
                                        <div className="p-text-nowrap p-text-truncate p-text-bold" >
                                            No se agregaron imagenes de daños para este vin: {this.state.chasisSeleccionado?.vin}
                                        </div>
                                   </Card>
                                </div>
                                :
                                <div className="card">
                                    <Galleria value={this.state.images} responsiveOptions={this.state.responsiveOptions} numVisible={3} circular style={{ maxWidth: '800px' }}
                                        //thumbnailItemTemplate={this.thumbnailTemplate}
                                        previewItemTemplate={this.itemTemplate}
                                        showThumbnails={false}
                                        showIndicators={true}
                                    />
                                </div>
                        }</div>
                    </div>
                </Dialog>
                <Dialog header='Agregar imagenes de daños' visible={this.state.dialogFileUploadVisible} position="center"
                    blockScroll={true} footer={footerDialogSubirImagen} //maximized={true}
                    style={{ width: '50vw' }} modal={true}
                    onHide={() => this.setState({ dialogFileUploadVisible: false })}>
                    <div>
                    <FileUpload multiple={true} accept="image/*"
                ref={this.fileUplo}
                customUpload={true}
                maxFileSize={500000}
                invalidFileSizeMessageDetail="La imagen excede tamaño permitido"
                invalidFileSizeMessageSummary="Tamaño invalido"
                chooseLabel="Buscar"
                uploadLabel="Subir"
                cancelLabel="Cancelar"
                mode="advanced"
                onSelect={(e)=>{
                    if(e.files.length>3){
                        e.originalEvent.preventDefault();
                    }
                }}

                uploadHandler={(e)=> {
                    if(this.state.images.length < 3 &&  (getUsuarioLogueado()?.rol === ROLES.CONDUCTOR || (getUsuarioLogueado()?.rol === ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER))){
                        if(e.files.length>3 || (this.state.images.length + e.files.length)>3){
                            this.growlError.current.show({
                                severity: 'error',
                                summary: 'Error',
                                detail: 'Se ha excedido el número permitido de archivos'
                            });
                        }
                        else if(e.files.length<=3 && (this.state.images.length + e.files.length) < 3){
                            this.onUploadd(e);
                        }
                    }else if ((getUsuarioLogueado()?.rol !== ROLES.CONDUCTOR || (getUsuarioLogueado()?.rol === ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER))){
                        this.onUploadd(e);
                    }
                }}

                //onUpload={(e) => {
                  //  console.log('Subiendoooo');
                    //this.onUploadd(e);
                //}}
                />
                    </div>
                </Dialog>

                <Dialog header='Finalizar sin descarga' visible={this.state.dialogSinDescarga} position="center"
                            blockScroll={true} footer={footerDialogSinDescarga} //maximized={true}
                            style={{width: '50vw'}} modal={true}
                            onHide={() => this.setState({dialogSinDescarga: false})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label htmlFor="selectedRechazo">Elija una opción: *</label>
                                    <Dropdown
                                        value={this.state.selectedRechazo.value} options={this.state.catalogoRechazo}
                                        onChange={(e) => {
                                            let tipoSeleccionado = this.state.catalogoRechazo.find(tb => tb.value === e.value);
                                            this.setState({ selectedRechazo: tipoSeleccionado! })
                                        }} required={true}
                                        placeholder={cadenas.SELECT_SELECCIONA}
                                        appendTo={document.body}
                                    />
                                </div>
                            </div>
                </Dialog>

                <Dialog header='Llegada a Concesionaria' visible={this.state.dialogLlegada} position="center"
                            blockScroll={true} footer={footerDialogLlegada} //maximized={true}
                            style={{width: '50vw'}} modal={true}
                            onHide={() => this.setState({dialogLlegada: false, activarEnvioFile:true})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label>Elija fecha y hora: *</label>
                                    <Calendar showTime hourFormat="24"
                                        dateFormat="dd/mm/yy"
                                        value={this.state.dateLlegadaShow}
                                        appendTo={document.body}
                                        locale={CALENDAR_LOCALE}
                                        //hideOnDateTimeSelect={true}
                                        //footerTemplate={footerCalendar}
                                        onChange={(e) => {
                                            this.setState({ dateLlegadaShow: e.value })
                                            console.log("this.state.datellegadashow", this.state.dateLlegadaShow)
                                        }}>

                                    </Calendar>
                                    <div style={{paddingTop:10}}>
                                        <label>Evidencia: *</label>
                                        <FileUpload  multiple={false} accept="image/*"
                                            ref={this.fileUplo}
                                            customUpload={true}
                                            maxFileSize={TAM_MAX_EVIDENCIA}
                                            invalidFileSizeMessageDetail="La imagen excede tamaño permitido"
                                            invalidFileSizeMessageSummary="Tamaño invalido"
                                            chooseLabel="Buscar"
                                            mode="basic"
                                            uploadHandler={(e)=> {this.onUploadEvidencias(e);
                                            }}
                                            onSelect={(e)=>{
                                                console.log("on select", e);
                                                this.setState({activarEnvioFile:false})
                                            }}
                                            onValidationFail={(e)=>{
                                                this.setState({dialogLlegada:false, activarEnvioFile:true});
                                                this.growlError.current.show({
                                                    severity: 'error',
                                                    summary: 'Error',
                                                    detail: 'Imagen: ' + e.name + ' excede el tamaño permitido'
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                </Dialog>

                <Dialog header='Finalizar Entrega' visible={this.state.dialogFinalizarEntrega} position="center"
                            blockScroll={true} footer={footerDialogFinalizarEntrega} 
                            style={{width: '50vw'}} modal={true}
                            onHide={() => this.setState({dialogFinalizarEntrega: false, activarEnvioFile:true})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <div style={{paddingTop:10}}>
                                        <label>Adjuntar Rev: *</label>
                                        <FileUpload  multiple={false} accept=".pdf"
                                            ref={this.fileUplo}
                                            customUpload={true}
                                            maxFileSize={TAM_MAX_EVIDENCIA}
                                            invalidFileSizeMessageDetail="El archivo excede tamaño permitido"
                                            invalidFileSizeMessageSummary="Tamaño invalido"
                                            chooseLabel="Buscar"
                                            mode="basic"
                                            uploadHandler={(e)=> {this.onUploadRevFinalizacion(e);
                                            }}
                                            onSelect={(e)=>{
                                                console.log("on select", e);
                                                this.setState({activarEnvioFile:false})
                                            }}
                                            onValidationFail={(e)=>{
                                                this.setState({dialogFinalizarEntrega:false, activarEnvioFile:true});
                                                this.growlError.current.show({
                                                    severity: 'error',
                                                    summary: 'Error',
                                                    detail: 'Archivo: ' + e.name + ' excede el tamaño permitido'
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                </Dialog>

                <Dialog header='Finalizar Entrega' visible={this.state.dialogFinalizarEntregaConOp} position="center"
                            blockScroll={true} footer={footerDialogFinalizarEntregaConOp} 
                            style={{width: '50vw'}} modal={true}
                            onHide={() => this.setState({dialogFinalizarEntregaConOp: false, activarEnvioFile:true})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <div style={{paddingTop:10}}>
                                        <label>¿Está seguro que deséa finalizar el viaje?</label>
                                    </div>
                                </div>
                            </div>
                </Dialog>


                <Dialog header='Subir Nueva Evidencia' visible={this.state.dialogNuevaEvidencia} position="center"
                            blockScroll={true} footer={footerDialogNuevaEvidencia} //maximized={true}
                            style={{width: '50vw'}} modal={true}
                            onHide={() => this.setState({dialogNuevaEvidencia: false, activarEnvioFile:true})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">
                                    <label style={{color:'#c02519', paddingBottom:10, fontWeight:'bold'}}>
                                        Se sobreescribirá TODA la evidencia capturada anteriormente</label>
                                    <label>Fecha y hora de llegada registrada: </label>
                                    <Calendar showTime hourFormat="24"
                                        dateFormat="dd/mm/yy"
                                        value={this.state.dateLlegadaShow}
                                        appendTo={document.body}
                                        locale={CALENDAR_LOCALE}
                                        disabled={true}
                                        onChange={(e) => {
                                            this.setState({ dateLlegadaShow: e.value })
                                            console.log("this.state.datellegadashow", this.state.dateLlegadaShow)
                                        }}>

                                    </Calendar>
                                    <div style={{paddingTop:10}}>
                                        <label>Evidencia: *</label>
                                        <FileUpload  multiple={false} accept="image/*"
                                            ref={this.fileUplo}
                                            customUpload={true}
                                            maxFileSize={TAM_MAX_EVIDENCIA}
                                            invalidFileSizeMessageDetail="La imagen excede tamaño permitido"
                                            invalidFileSizeMessageSummary="Tamaño invalido"
                                            chooseLabel="Buscar"
                                            mode="basic"
                                            uploadHandler={(e)=> {this.onUploadNuevaEvidencia(e);
                                            }}
                                            onSelect={(e)=>{
                                                console.log("on select", e);
                                                this.setState({activarEnvioFile:false})
                                            }}
                                            onValidationFail={(e)=>{
                                                this.setState({dialogNuevaEvidencia:false, activarEnvioFile:true});
                                                this.growlError.current.show({
                                                    severity: 'error',
                                                    summary: 'Error',
                                                    detail: 'Imagen: ' + e.name + ' excede el tamaño permitido'
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                </Dialog>
            </div>
        );
    }

    activarGaleria(rowData:any){
        let activar = true;
        if (rowData.danios !== undefined){
            let dam = JSON.parse(rowData.danios)
            if(dam.length > 0){
                dam.forEach((d:any) => {
                    //if(d.comentario !== null){
                        activar = false;
                        return false;
                    //}
                });
            }
        }else if(rowData.damages !== undefined){
                rowData.damages.forEach((d:any) => {
                    //if(d.comentario !== null){
                        activar = false;
                        return false;
                    //}
                });

        }
        return activar;
    }

    activarSubirImagenes(rowData:any){
        let activar = true;
        //if(getUsuarioLogueado()?.rol === ROLES.ADMINSIS || getUsuarioLogueado()?.rol === ROLES.SEGUROS){
        if(getUsuarioLogueado()?.rol === ROLES.ADMINTRANSPORTISTA || getUsuarioLogueado()?.rol === ROLES.CONDUCTOR ){
            if (rowData.danios !== undefined){
                let dam = JSON.parse(rowData.danios)
                if(dam.length > 0){
                    dam.forEach((d:any) => {
                        if(d.comentario !== null){
                            activar = false;
                            return false;
                        }
                    });
                }
            }else if(rowData.damages !== undefined){
                    rowData.damages.forEach((d:any) => {
                        if(d.comentario !== null){
                            activar = false;
                            return false;
                        }
                    });

            }
        }
        return activar;
    }

    damageTemplate(damage:any){
        if (!damage) {
            return;
        }

        return (
            <div >
                <div>
                    <div className="p-grid">
                        <div className="p-col-12">Area: <b>{damage.area}</b></div>
                    </div>
                    <Button label="Save" />
                </div>
            </div>
        );
    }

    showDialogFaltantes(rowData:any) {
        this.obtenerCatalogo('faltantes', rowData);
        this.setState({
            dialogFaltantesVisible: true,
            chasisSeleccionado: rowData,
            selectedFaltantes: rowData.missings !== undefined ? rowData.missings : [],
        });
    }

    showDialogDanios(rowData:any) {
        this.obtenerCatalogo('daniosArea', rowData);
        this.obtenerCatalogo('daniosTipo', rowData);
        this.obtenerCatalogo('daniosSeveridad', rowData);

        this.setState({
            dialogDaniosVisible: true,
            chasisSeleccionado: rowData,
            damagesSelected: rowData.damages !== undefined ? rowData.damages : [],
        });
    }

    showDialogSiniestros(rowData:any) {
        this.obtenerCatalogo('siniestros', rowData);
        this.setState({
            dialogSiniestrosVisible: true,
            chasisSeleccionado: rowData,
        });
    }

    showDialogSiniestrosRead(rowData:any) {
        //this.obtenerCatalogo('siniestros', rowData);
        this.setState({
            dialogSiniestrosVisibleRead: true,
            chasisSelected: rowData,
        });
    }


    showDialogDaniosRead(rowData:any) {
        //this.obtenerCatalogo('siniestros', rowData);
        this.setState({
            dialogDaniosVisibleRead: true,
            chasisSelected: rowData,
        });
    }

    showDialogFaltantesRead(rowData:any) {
        //this.obtenerCatalogo('siniestros', rowData);
        this.setState({
            dialogFaltantesVisibleRead: true,
            chasisSelected: rowData,
        });
    }


    showDialogSinDescarga(rowData:any){
        this.obtenerCatalogo('rechazos', rowData);
        this.setState({
            dialogSinDescarga:true,
            selectedVisita:rowData
        })
    }

    showDialogFinalizarEntrega(rowData:any){
        //this.obtenerCatalogo('rechazos', rowData);
        this.setState({
            dialogFinalizarEntrega: true,
            //selectedVisita:rowData
        })
    }

    showDialogFinalizarEntregaConOp(rowData:any){
        //this.obtenerCatalogo('rechazos', rowData);
        this.setState({
            dialogFinalizarEntregaConOp: true,
            //selectedVisita:rowData
        })
    }

    /**
     * Buscar el catalogo en el servidor de acuerdo a la
     * ruta creada.
     */
    obtenerCatalogo(catalogo:String, rowData:any) {
        this.setState({blocking:true});
        let pathViaje = '';
        switch(catalogo){
            case 'faltantes':
                pathViaje = PATHS_API.CATALOGO_FALTANTES;
            break;
            case 'daniosArea':
                pathViaje = PATHS_API.CATALOGO_DANIOS_AREA;
            break;
            case 'daniosTipo':
                pathViaje = PATHS_API.CATALOGO_DANIOS_TIPO;
            break;
            case 'daniosSeveridad':
                pathViaje = PATHS_API.CATALOGO_DANIOS_SEVERIDAD;
            break;
            case 'siniestros':
                pathViaje = PATHS_API.CATALOGO_SINIESTROS;
            break;
            case 'rechazos':
                pathViaje = PATHS_API.CATALOGO_RECHAZOS;
            break;
        }
        APIGATEWAY.get(pathViaje)
        .then(response => {
            console.log("obtenerCatalogo()", response);
            this.mappingCatalogo(catalogo, response, rowData);
        })
        .catch(error => {
            this.setState({blocking:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.CAPTURA_ONLINE_ERRO_BUSQUEDA);
            console.error("obtenerCatalogo()",error);
        });
    }

    /**
     * Crear los objetos de tipo @interface ItemDropDownInterface
     * para ingresarlos en un array que debe tener el json de catalogos.
     * @param {String} catalogo Es el nombre del catalogo que se va a mapear
     * @param {any} response Es el JSOn que devuelve el servidor.
     */
    mappingCatalogo(catalogo: String, response :any, rowData:any) {
        let cat: Array<ItemDropDownInterface> = [];
        response.forEach((element: any) => {
            cat.push({
                label: element.nombre,
                value: element.codigo,
            });
        });
        switch(catalogo){
            case 'faltantes':
                //let catSelect: Array<ItemDropDownInterface> = [];
                if(rowData.missings !== undefined){
                    rowData.missings.forEach(async (miss: any) => {
                        console.log("miss", miss);
                        var itemBuscado = null;
                        itemBuscado = cat.find(item => item.value === miss.code);
                        miss.nombre = itemBuscado?.label;
                        //catSelect.push(itemBuscado!);
                        //cat = cat.filter(item => item.value !== miss.code);
                    });
                }
                this.setState({
                    blocking:false,
                    catalogoFaltantes:cat,
                    //selectedFaltantes:catSelect
                });
            break;
            case 'daniosArea':
                if(rowData.damages !== undefined){
                    rowData.damages.forEach(async (miss: any) => {
                        var itemBuscado = null;
                        itemBuscado = cat.find(item => item.value === miss.area);
                        miss.nombreArea = itemBuscado!.label;
                    });
                }
                this.setState({
                    blocking:false,
                    catalogoDaniosArea:cat,
                });
            break;
            case 'daniosTipo':
                if(rowData.damages !== undefined){
                    rowData.damages.forEach(async (miss: any) => {
                        var itemBuscado = null;
                        itemBuscado = cat.find(item => item.value === miss.type);
                        miss.nombreTipo = itemBuscado!.label;
                    });
                }
                this.setState({
                    blocking:false,
                    catalogoDaniosTipo:cat,
                });
            break;
            case 'daniosSeveridad':
                if(rowData.damages !== undefined){
                    rowData.damages.forEach(async (miss: any) => {
                        var itemBuscado = null;
                        itemBuscado = cat.find(item => item.value === miss.severity);
                        miss.nombreSeveridad = itemBuscado!.label;
                    });
                }
                this.setState({
                    blocking:false,
                    catalogoDaniosSeveridad:cat,
                });
            break;
            case 'siniestros':
                let sinSelect: ItemDropDownInterface = {label:'',value:''};
                if(rowData.opcionSiniestro !== undefined){
                    sinSelect = {
                        label: rowData.opcionSiniestro.nombre,
                        value: rowData.opcionSiniestro.codigo,
                    }
                }
                this.setState({
                    blocking:false,
                    catalogoSiniestros:cat,
                    selectedSiniestro: sinSelect
                });
            break;
            case 'rechazos':
                let rechazoSelect: ItemDropDownInterface = {label:'',value:''};
                if(rowData.razonTermino !== undefined){
                    rechazoSelect = {
                        label: rowData.razonTermino.nombre,
                        value: rowData.razonTermino.codigo,
                    }
                }
                this.setState({
                    blocking:false,
                    catalogoRechazo:cat,
                    selectedRechazo: rechazoSelect
                });
            break;
        }
    }

    /**
     * Crear los objetos de tipo @interface ItemDropDownInterface
     * para ingresarlos en un array que debe tener el json de catalogos.
     * @param {String} catalogo Es el nombre del catalogo que se va a mapear
     * @param {any} response Es el JSOn que devuelve el servidor.
     */
    guardarFaltantes() {
        this.setState({blocking:true});
        if(this.state.selectedFaltantes.length > 0){
            let missings:Array<any>=[];
            this.state.selectedFaltantes.forEach((element: any) => {
                console.log("element", element)
                if(element.comentario !== undefined){
                    missings.push({
                        code: element.code,
                        comentario: element.comentario,
                    });
                }else{
                    missings.push({
                        code: element.code,
                    });
                }                
            });

            this.state.viajes.forEach((element: any) => {
                if(element.orderId === this.state.viajeSeleccionado?.orderId){
                    element.visits.forEach((visita: any) => {
                        if(visita.concesionario === this.state.visitaSeleccionada?.concesionario){
                            visita.shipment.forEach((ship: any) => {
                                if(ship.vin === this.state.chasisSeleccionado?.vin){
                                    ship.missings = missings;
                                }
                            });
                        }
                    });
                }
            });
            this.state.viajesTemporal.forEach((element: any) => {
                if(element.orderId === this.state.viajeSeleccionado?.orderId){
                    element.visits.forEach((visita: any) => {
                        if(visita.concesionario === this.state.visitaSeleccionada?.concesionario){
                            visita.shipment.forEach((ship: any) => {
                                if(ship.vin === this.state.chasisSeleccionado?.vin){
                                    ship.missings = missings;
                                }
                            });
                        }
                    });
                }
            });
            this.setState({blocking:false,chasisSeleccionado:undefined,selectedFaltantes:[],dialogFaltantesVisible:false, isEdited:true})
        }else{
            this.setState({blocking:false,chasisSeleccionado:undefined})
        }

    }

    agregarDanios() {
        if(this.state.selectedDanioArea.value !== ''){
            let dam = this.state.damagesSelected;
            if(this.state.comentarioDanioSelected !== ''){
                dam.push({
                    area: this.state.selectedDanioArea.value,
                    nombreArea: this.state.selectedDanioArea.label,
                    type: this.state.selectedDanioTipo.value,
                    nombreTipo: this.state.selectedDanioTipo.label,
                    severity: this.state.selectedDanioSeveridad.value,
                    nombreSeveridad: this.state.selectedDanioSeveridad.label,
                    category: DANIO_CATEGORIA,
                    comentario: this.state.comentarioDanioSelected
                });
            }else{
                dam.push({
                    area: this.state.selectedDanioArea.value,
                    nombreArea: this.state.selectedDanioArea.label,
                    type: this.state.selectedDanioTipo.value,
                    nombreTipo: this.state.selectedDanioTipo.label,
                    severity: this.state.selectedDanioSeveridad.value,
                    nombreSeveridad: this.state.selectedDanioSeveridad.label,
                    category: DANIO_CATEGORIA,
                });
            }
            this.setState({damagesSelected: dam})
        }
    }

    agregarFaltante() {
        if(this.state.selectedFaltante.value !== ''){
            let dam = this.state.selectedFaltantes;
            if(this.state.comentarioFaltanteSelected !== ''){
                dam.push({
                    code: this.state.selectedFaltante.value,
                    nombre: this.state.selectedFaltante.label,
                    comentario: this.state.comentarioFaltanteSelected
                });
            }else{
                dam.push({
                    code: this.state.selectedFaltante.value,
                    nombre: this.state.selectedFaltante.label,
                });
            }
            this.setState({selectedFaltantes: dam, selectedFaltante:{label:'',value:''}, comentarioFaltanteSelected:''})
        }
    }

    eliminarDanios(danio:any) {
        var nuevosDanios = [...this.state.damagesSelected];
        let indiceBuscado = this.state.damagesSelected.findIndex(item =>
            item.area === danio.area &&
            item.type === danio.type &&
            item.severity === danio.severity &&
            item.category === danio.category);
        nuevosDanios.splice(indiceBuscado,1);
        this.setState({damagesSelected:nuevosDanios});
    }

    eliminarFaltantes(faltante:any) {
        var nuevosFaltantes = [...this.state.selectedFaltantes];
        let indiceBuscado = this.state.selectedFaltantes.findIndex(item =>
            item.code === faltante.code);
        nuevosFaltantes.splice(indiceBuscado,1);
        this.setState({selectedFaltantes:nuevosFaltantes});
    }

    guardarDanios() {
        this.setState({blocking:true});
        if(this.state.damagesSelected.length > 0){
            let damages:Array<any>=[];
            this.state.damagesSelected.forEach((element: any) => {
                if(element.comentario !== undefined){
                    damages.push({
                        area: element.area,
                        type: element.type,
                        severity: element.severity,
                        category: element.category,
                        comentario: element.comentario,
                    });
                }else{
                    damages.push({
                        area: element.area,
                        type: element.type,
                        severity: element.severity,
                        category: element.category,
                    });
                }

            });

            this.state.viajes.forEach((element: any) => {
                if(element.orderId === this.state.viajeSeleccionado?.orderId){
                    element.visits.forEach((visita: any) => {
                        if(visita.concesionario === this.state.visitaSeleccionada?.concesionario){
                            visita.shipment.forEach((ship: any) => {
                                if(ship.vin === this.state.chasisSeleccionado?.vin){
                                    ship.damages = damages;
                                }
                            });
                        }
                    });
                }
            });
            this.state.viajesTemporal.forEach((element: any) => {
                if(element.orderId === this.state.viajeSeleccionado?.orderId){
                    element.visits.forEach((visita: any) => {
                        if(visita.concesionario === this.state.visitaSeleccionada?.concesionario){
                            visita.shipment.forEach((ship: any) => {
                                if(ship.vin === this.state.chasisSeleccionado?.vin){
                                    ship.damages = damages;
                                }
                            });
                        }
                    });
                }
            });
            this.setState({blocking:false,chasisSeleccionado:undefined,damagesSelected:[],
                    dialogDaniosVisible:false,selectedDanioArea:{label:'',value:''},
                    selectedDanioTipo:{label:'',value:''}, selectedDanioSeveridad:{label:'',value:''},
                    selectedCategoria:{label:'',value:''}, isEdited: true})
        }else{
            this.setState({blocking:false,chasisSeleccionado:undefined})
        }
    }

    guardarSiniestro() {
        this.setState({blocking:true});
        if(this.state.selectedSiniestro.value !== ''){

            this.state.viajes.forEach((element: any) => {
                if(element.orderId === this.state.viajeSeleccionado?.orderId){
                    element.visits.forEach((visita: any) => {
                        if(visita.concesionario === this.state.visitaSeleccionada?.concesionario){
                            visita.shipment.forEach((ship: any) => {
                                if(ship.vin === this.state.chasisSeleccionado?.vin){
                                    ship.opcionSiniestro = {
                                        codigo: this.state.selectedSiniestro.value,
                                        estado: true,
                                        nombre: this.state.selectedSiniestro.label
                                    };
                                    ship.siniestrados = true;
                                }
                            });
                        }
                    });
                }
            });
            this.state.viajesTemporal.forEach((element: any) => {
                if(element.orderId === this.state.viajeSeleccionado?.orderId){
                    element.visits.forEach((visita: any) => {
                        if(visita.concesionario === this.state.visitaSeleccionada?.concesionario){
                            visita.shipment.forEach((ship: any) => {
                                if(ship.vin === this.state.chasisSeleccionado?.vin){
                                    ship.opcionSiniestro = {
                                        codigo: this.state.selectedSiniestro.value,
                                        estado: true,
                                        nombre: this.state.selectedSiniestro.label
                                    };
                                    ship.siniestrados = true;
                                }
                            });
                        }
                    });
                }
            });
            this.setState({blocking:false,chasisSeleccionado:undefined,selectedSiniestro:{label:'',value:''},
                    dialogSiniestrosVisible:false, isEdited: true})
                }else{
                    this.setState({blocking:false,chasisSeleccionado:undefined})
                }
    }

    confirmarVin(rowData:any){
        this.setState({blocking:true});
            this.state.viajes.forEach((element: any) => {
                if(element.orderId === this.state.viajeSeleccionado?.orderId){
                    element.visits.forEach((visita: any) => {
                        if(visita.concesionario === this.state.visitaSeleccionada?.concesionario){
                            visita.shipment.forEach((ship: any) => {
                                if(ship.vin === rowData.vin){
                                    ship.estadoChasis = 'CONFIRMADO';
                                }
                            });
                        }
                    });
                }
            });
            this.state.viajesTemporal.forEach((element: any) => {
                if(element.orderId === this.state.viajeSeleccionado?.orderId){
                    element.visits.forEach((visita: any) => {
                        if(visita.concesionario === this.state.visitaSeleccionada?.concesionario){
                            visita.shipment.forEach((ship: any) => {
                                if(ship.vin === rowData.vin){
                                    ship.estadoChasis = 'CONFIRMADO';
                                }
                            });
                        }
                    });
                }
            });
            this.setState({blocking:false, isEdited: true})
    }

    verificarVinsConfirmados(rowData:any){
        let todos = true;
        rowData.shipment.forEach((element:any) => {
            if(!element.status){
                todos = false;
            }
        });
        if(todos){
            this.finalizarViaje('ENTREGA_CONFIRMADA',rowData);
        }else{
            window.alert('Debe confirmar todos los vins para finalizar')
        }
    }

    finalizarViaje = (estatus:any,visita:any) => {
        this.setState({blocking:true});
        //armar shipment
        var ship:Array<any> = [];
        visita.shipment.forEach((s:any) => {
          var newShip ={
            vin: s.vin,
            damages: s.damages === undefined ? [] : s.damages,
            missings: s.missings === undefined ? [] : s.missings,
            siniestrado: s.siniestrados === undefined ? false : s.siniestrados,
            opcionSiniestro: s.opcionSiniestro === undefined ? null : s.opcionSiniestro,
          }
          ship.push(newShip);
        });
        //armar razonTermino solo si finaliza sin descarga
        let razonTermino = null;
        if(estatus === 'COMPLETADO SIN DESCARGA'){
            razonTermino = {
                codigo: this.state.selectedRechazo.value,
                nombre: this.state.selectedRechazo.label
            }
        }
        //armar json a enviar
        var data = {
          numeroEconomico : this.state.viajeSeleccionado?.numEconomico, //this.state.numEconomico,
          confirmedOn: convertirDate(new Date()),
          confirmedBy: null,
          estatusEntrega: estatus,
          razonTermino: razonTermino === null ? null : razonTermino,
          shipment: ship,
        }

        //crear path
        var path = `/${this.state.viajeSeleccionado?.compound}/${
          this.state.viajeSeleccionado?.orderId}/${visita.concesionario}/f1`;

        //post
        APIGATEWAY.post(PATHS_API.FINALIZAR_VIAJE + path, data)
          .then(async response => {
            console.log("finalizarViaje()", response);
            this.guardarVisitaFin(estatus, visita, data);
            this.fileUplo.current.upload();
          })
          .catch(async (error) => {
            this.setState({blocking:false, dialogSinDescarga:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.CAPTURA_ONLINE_ERRO_BUSQUEDA);
            console.error("finalizarViaje()",error);
          });
    }

    finalizarViajeConOp = (estatus:any,visita:any) => {
        this.setState({blocking:true});
        //armar shipment
        var ship:Array<any> = [];
        visita.shipment.forEach((s:any) => {
          var newShip ={
            vin: s.vin,
            damages: s.damages === undefined ? [] : s.damages,
            missings: s.missings === undefined ? [] : s.missings,
            siniestrado: s.siniestrados === undefined ? false : s.siniestrados,
            opcionSiniestro: s.opcionSiniestro === undefined ? null : s.opcionSiniestro,
          }
          ship.push(newShip);
        });
        //armar razonTermino solo si finaliza sin descarga
        let razonTermino = null;
        if(estatus === 'COMPLETADO SIN DESCARGA'){
            razonTermino = {
                codigo: this.state.selectedRechazo.value,
                nombre: this.state.selectedRechazo.label
            }
        }
        //armar json a enviar
        var data = {
          numeroEconomico : this.state.viajeSeleccionado?.numEconomico, //this.state.numEconomico,
          confirmedOn: convertirDate(new Date()),
          confirmedBy: null,
          estatusEntrega: estatus,
          razonTermino: razonTermino === null ? null : razonTermino,
          shipment: ship,
        }

        //crear path
        var path = `/${this.state.viajeSeleccionado?.compound}/${
          this.state.viajeSeleccionado?.orderId}/${visita.concesionario}/f1`;

        //post
        APIGATEWAY.post(PATHS_API.FINALIZAR_VIAJE + path, data)
          .then(async response => {
            console.log("finalizarViaje()", response);
            this.guardarVisitaFin(estatus, visita, data);
            //this.fileUplo.current.upload();
            this.growlError.current.show({
                severity: 'info',
                summary: 'Exito',
                detail: '¡Viaje ' + this.state.viajeSeleccionado?.numEconomico + ' finalizado con exito!'
            });
            this.setState({blocking:false,dialogFinalizarEntregaConOp:false, activarEnvioFile:true})
          })
          .catch(async (error) => {
            this.setState({blocking:false, dialogSinDescarga:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.CAPTURA_ONLINE_ERRO_BUSQUEDA);
            console.error("finalizarViaje()",error);
          });
    }

    guardarVisitaFin(estatus:string, rowData:any, dataEnviada:any){
            this.state.viajes.forEach((element: any) => {
                if(element.orderId === this.state.viajeSeleccionado?.orderId){
                    element.visits.forEach((visita: any) => {
                        if(visita.concesionario === rowData.concesionario){
                            visita.estatusEntrega = estatus;
                            visita.confirmedOn = dataEnviada.confirmedOn;
                            visita.confirmedBy = dataEnviada.confirmedBy;
                            if(estatus === 'COMPLETADO SIN DESCARGA'){
                                visita.razonTermino = dataEnviada.razonTermino;
                            }
                        }
                    });
                }

            });
            this.state.viajesTemporal.forEach((element: any) => {
                if(element.orderId === this.state.viajeSeleccionado?.orderId){
                    element.visits.forEach((visita: any) => {
                        if(visita.concesionario === rowData.concesionario){
                            visita.estatusEntrega = estatus;
                            visita.confirmedOn = dataEnviada.confirmedOn;
                            visita.confirmedBy = dataEnviada.confirmedBy;
                            if(estatus === 'COMPLETADO SIN DESCARGA'){
                                visita.razonTermino = dataEnviada.razonTermino;
                            }
                        }
                    });
                }

            });
            //this.setState({blocking:false,dialogSinDescarga:false})
    }

    async onUploadd(event: any) {
        this.setState({
            blocking: true
        });
        //this.onUploadd(e,rowData,this.state.viajeSeleccionado!.orderId,this.state.visitaSeleccionada!.destination);
        console.log(event);
        console.log(this.state.chasisSeleccionado);
        console.log(this.state.viajeSeleccionado!.orderId);
        console.log(this.state.visitaSeleccionada!.concesionario);
        console.log('Se van a subir arhivos');
        //var path = 'imagenes/' + orderId + '/' + location + '/' + rowData.vin + '/';
        var path='imagenes/' + this.state.viajeSeleccionado!.orderId + '/' + this.state.visitaSeleccionada!.concesionario + '/' + this.state.chasisSeleccionado?.vin + '/';
        console.log('path bucket', path);
        var numIma = 0;
        await Storage.list(path, {
            level: 'public'
        })
            .then(result => {
                console.log('listar bucket valor', result);
                numIma = result.length;
                console.log('lista tamEncontrados', numIma);
            })
            .catch(err => {
                console.log('error al tratar de listar bucket', err);
            });
        for (let file of event.files) {
            console.log(file);
            console.log(path + file.name);
            if (numIma < NUM_IMAGENES_MAX_CHASIS) {
                await Storage.put(path + file.name, file, {
                    level: 'public',
                    contentType: file.type
                })
                    .then(result => {
                        console.log('subido exito', result);
                        numIma++;
                        this.growlError.current.show({
                            severity: 'info',
                            summary: 'Exito',
                            detail: 'Imagen: ' + file.name + ' agregada '
                        });
                    })
                    .catch(err => {
                        console.log('error al subir al bucket', err);
                        this.growlError.current.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Imagen: ' + file.name + ' no agregada'
                        });
                    })
            } else {
                console.log('Ya excedio el limite de numero de imagenes');
                this.growlError.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: cadenas.IMAGENES_DANIOS_EXCEDER
                });
                break;
            }
        }

        this.setState({
            blocking: false,

        });
        this.fileUplo.current.clear();
    }
    async onUploadEvidencias(event: any) {
        /*this.setState({
            blocking: true
        });*/

        console.log(event);
        //console.log(this.state.chasisSeleccionado);
        console.log(this.state.viajeSeleccionado!.orderId);
        console.log(this.state.visitaSeleccionada!.concesionario);
        console.log('Se van a subir arhivo de evidencia');
        //var path = 'imagenes/' + orderId + '/' + location + '/' + rowData.vin + '/';
        var path='imagenes/' + this.state.viajeSeleccionado!.orderId + '/' + this.state.visitaSeleccionada!.concesionario + '/evidencias/';
        console.log('path bucket', path);
        var numIma = 0;
        await Storage.list(path, {
            level: 'public'
        })
            .then(result => {
                console.log('listar bucket valor', result);
                numIma = result.length;
                console.log('lista tamEncontrados', numIma);
            })
            .catch(err => {
                console.log('error al tratar de listar bucket', err);
            });
        for (let file of event.files) {
            console.log(file);
            console.log(path + file.name);
            if (numIma < NUM_IMAGENES_MAX_EVIDENCIA) {
                await Storage.put(path + file.name, file, {
                    level: 'public',
                    contentType: file.type
                })
                    .then(result => {
                        console.log('subido exito', result);
                        numIma++;
                        this.growlError.current.show({
                            severity: 'info',
                            summary: 'Exito',
                            detail: 'Imagen: ' + file.name + ' agregada '
                        });
                    })
                    .catch(err => {
                        console.log('error al subir al bucket', err);
                        this.growlError.current.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Imagen: ' + file.name + ' no agregada'
                        });
                    })
            } else {
                console.log('Ya excedio el limite de numero de imagenes');
                this.growlError.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: cadenas.IMAGENES_DANIOS_EXCEDER
                });
                break;
            }
        }

        /*this.setState({
            blocking: false,
        });*/
        this.setState({visitas:this.state.visitas, blocking:false, dialogLlegada:false, activarEnvioFile:true});
        this.fileUplo.current.clear();
        //this.fileUplo.current.upload();
    }

    async onUploadNuevaEvidencia(event: any) {
        /*this.setState({
            blocking: true
        });*/

        console.log(event);
        //console.log(this.state.chasisSeleccionado);
        console.log(this.state.viajeSeleccionado!.orderId);
        console.log(this.state.visitaSeleccionada!.concesionario);
        console.log('Se van a subir nuevo arhivo de evidencia');
        //var path = 'imagenes/' + orderId + '/' + location + '/' + rowData.vin + '/';
        var path='imagenes/' + this.state.viajeSeleccionado!.orderId + '/' + this.state.visitaSeleccionada!.concesionario + '/evidencias/';
        console.log('path bucket', path);
        var numIma = 0;
        await Storage.list(path, {
            level: 'public'
        })
            .then(result => {
                console.log('listar bucket valor', result);
                numIma = result.length;
                console.log('lista tamEncontrados', numIma);
                for (let r of result){
                    Storage.remove(r.key)
                    .then(result => console.log(result))
                    .catch(err => console.log(err));
                }
            })
            .catch(err => {
                console.log('error al tratar de listar bucket', err);
            });
        for (let file of event.files) {
            console.log(file);
            console.log(path + file.name);
            
                await Storage.put(path + file.name, file, {
                    level: 'public',
                    contentType: file.type
                })
                    .then(result => {
                        console.log('subido exito', result);
                        numIma++;
                        this.growlError.current.show({
                            severity: 'info',
                            summary: 'Exito',
                            detail: 'Imagen: ' + file.name + ' agregada '
                        });
                    })
                    .catch(err => {
                        console.log('error al subir al bucket', err);
                        this.growlError.current.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Imagen: ' + file.name + ' no agregada'
                        });
                    })
            
        }

        /*this.setState({
            blocking: false,
        });*/
        this.setState({visitas:this.state.visitas, blocking:false, dialogNuevaEvidencia:false, activarEnvioFile:true});
        this.fileUplo.current.clear();
        //this.fileUplo.current.upload();
    }

    async onUploadRevFinalizacion(event: any) {
        /*this.setState({
            blocking: true
        });*/

        console.log(event);
        //console.log(this.state.chasisSeleccionado);
        console.log(this.state.viajeSeleccionado!.orderId);
        console.log(this.state.visitaSeleccionada!.concesionario);
        console.log('Se van a subir arhivo de rev finalizacion');
        //var path = 'imagenes/' + orderId + '/' + location + '/' + rowData.vin + '/';
        var path='imagenes/' + this.state.viajeSeleccionado!.orderId + '/' + this.state.visitaSeleccionada!.concesionario + '/rev/';
        console.log('path bucket', path);
        var numIma = 0;
        await Storage.list(path, {
            level: 'public'
        })
            .then(result => {
                console.log('listar bucket valor', result);
                numIma = result.length;
                console.log('lista tamEncontrados', numIma);
            })
            .catch(err => {
                console.log('error al tratar de listar bucket', err);
            });
        for (let file of event.files) {
            console.log(file);
            console.log(path + file.name);
            if (numIma < NUM_ARCHIVOS_MAX_REV) {
                await Storage.put(path + file.name, file, {
                    level: 'public',
                    contentType: file.type
                })
                    .then(result => {
                        console.log('subido exito', result);
                        numIma++;
                        this.growlError.current.show({
                            severity: 'info',
                            summary: 'Exito',
                            detail: 'Archivo: ' + file.name + ' agregado'
                        });
                    })
                    .catch(err => {
                        console.log('error al subir al bucket', err);
                        this.growlError.current.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Archivo: ' + file.name + ' no agregado'
                        });
                    })
            } else {
                console.log('Ya excedio el limite de numero de archivos');
                this.growlError.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: cadenas.REV_ARCHIVOS_EXCEDER
                });
                break;
            }
        }

        /*this.setState({
            blocking: false,
        });*/
        this.setState({blocking:false,dialogFinalizarEntrega:false, activarEnvioFile:true})
        this.fileUplo.current.clear();
        //this.fileUplo.current.upload();
    }

    async showGaleriaDanios(rowData:any,orderId: String, location: any) {
        this.setState({
            blocking: true,

        });
        var path = 'imagenes/' + orderId + '/' + location + '/' + rowData.vin + '/';
        console.log('path bucket', path);
        var numIma = 0;
        var claves:Array<any> = [];
        await Storage.list(path, {
            level: 'public'
        })
            .then(result => {
                console.log('listar bucket valor', result);
                numIma = result.length;
                claves=result;
                console.log('lista tamEncontrados', numIma);
            })
            .catch(err => {
                console.log('error al tratar de listar bucket', err);
            });
        var galeria:Array<any> = [];
        for (let clave of claves) {
            console.log('clave', clave);
            await Storage.get(clave.key, {
                level: 'public',
                download: false
            })
                .then(result => {
                    console.log('valor imagen', result);
                    galeria.push(result);
                })
                .catch(err => {
                    console.log('error al tratar obtner imagen bucket', err);
                });
        }
        this.setState({
            chasisSeleccionado: rowData,
            images: galeria,
            dialogGaleriaVisible: true,
        });
        console.log('valor images',this.state.images)
        this.setState({
            blocking: false,

        });
    }
    async showSubirImagenesDanios(rowData:any) {
        console.log('Subir archivo imagene row',rowData)
        this.setState({
            blocking: true,

        });
        this.setState({
            chasisSeleccionado: rowData,
            dialogFileUploadVisible: true,
        });

        this.setState({
            blocking: false,

        });
    }

    showDialogObservaciones(rowData:any) {
        console.log('row',rowData)
        console.log("viaje seleccionada", this.state.viajeSeleccionado)
        this.setState({
            headerObservaciones: rowData.concesionario + ' - Observaciones',
            visitaTemp: rowData,
            dialogObservaciones: true,
        });
        this.setState({observacionesEntrega: rowData.observaciones});
    }

    enviarObservaciones = () => {
        this.setState({blocking:true});
        //armar json a enviar
        let body = {
            orderId: this.state.viajeSeleccionado?.orderId,
            concesionario: this.state.visitaTemp?.concesionario,
            observaciones: this.state.observacionesEntrega,
        };

        //crear path
        var path = `/${this.state.viajeSeleccionado?.orderId}/${this.state.visitaTemp?.concesionario}`;

        //post
        APIGATEWAY.post(PATHS_API.OBSERVACIONES_ENTREGA + path, body)
          .then(async response => {
            console.log("enviarObservaciones()", response);
            this.guardarObservaciones(body);
          })
          .catch(async (error) => {
            this.setState({blocking:false, dialogObservaciones:false, headerObservaciones:'', 
                visitaTemp:undefined, observacionesEntrega:''});
            this.showError(cadenas.ERROR_TITULO, cadenas.CAPTURA_ONLINE_ERRO_BUSQUEDA);
            console.error("enviarObservaciones()",error);
          });
    }

    guardarObservaciones(dataEnviada:any){
        this.state.viajes.forEach((element: any) => {
            if(element.orderId === this.state.viajeSeleccionado?.orderId){
                element.visits.forEach((visita: any) => {
                    if(visita.concesionario === dataEnviada.concesionario){
                        visita.observaciones = dataEnviada.observaciones;
                    }
                });
            }

        });
        this.state.viajesTemporal.forEach((element: any) => {
            if(element.orderId === this.state.viajeSeleccionado?.orderId){
                element.visits.forEach((visita: any) => {
                    if(visita.concesionario === dataEnviada.concesionario){
                        visita.observaciones = dataEnviada.observaciones;
                    }
                });
            }

        });
        this.setState({blocking:false,dialogObservaciones:false,headerObservaciones:'',
            visitaTemp:undefined, observacionesEntrega:''})
    }

    /**
     * Registrar la llegada de la visita en el servidor.
     * @param {any} rowData Objeto del datatable que fue seleccionado.
     */
    enviarNuevaEvidencia() {
        this.setState({blocking:true});
        this.fileUplo.current.upload();
    }

    /**
     * Registrar la llegada de la visita en el servidor.
     * @param {any} rowData Objeto del datatable que fue seleccionado.
     */
    registrarLlegada(rowData:any) {
        this.setState({blocking:true});
        let vinsViaje: string[] = [];
        this.state.chasis.forEach(v =>{
            vinsViaje.push(v.vin);
        });
        let body = {
            orderId: this.state.viajeSeleccionado?.orderId,
            concesionario: rowData.concesionario,
            fechaLlegada: new Date((this.state.dateLlegadaShow).toString()).toISOString(),//new Date().toISOString(),//convertirDate(new Date()),
            latitud: 0,
            longitud: 0,
            compound: this.state.viajeSeleccionado?.compound,
            econum: this.state.viajeSeleccionado?.numEconomico,
            vins: vinsViaje
        };
        APIGATEWAY.post(PATHS_API.AGREGAR_INFO_LLEGADA, body)
        .then(response => {
            console.log("registrarLlegada()", response);
            var index = this.state.visitas.findIndex(v => v.concesionario === rowData.concesionario);
            this.state.visitas[index].estatusEntrega = 'COMPLETADO';
            this.state.visitas[index].fechaLlegada = new Date(body.fechaLlegada);
            this.state.visitas[index].latitud = '0';
            this.state.visitas[index].longitud = '0';
            //this.setState({visitas:this.state.visitas, blocking:false, dialogLlegada:false});
            this.registrarLlegadaViajesTemp(index, this.state.viajeSeleccionado?.orderId, rowData.concesionario);
            this.fileUplo.current.upload();
        })
        .catch(error => {
            this.setState({blocking:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.CAPTURA_ONLINE_LLEGADA_ERRONEA);
            console.error("registrarLelegada()", error);
        });
    }

    registrarLlegadaViajesTemp(index:any, orderId:any, concesionario:any){
        this.state.viajesTemporal.forEach(v =>{
            if(v.orderId === orderId){
                v.visits?.forEach(vi =>{
                    if(vi.concesionario === concesionario){
                        vi = this.state.visitas[index];
                    }
                })
            }
        })
    }

    /**
     * Se modifica el state para controlar la visibilidad de los
     * datatables.
     * @param {string} vistaActual Nombre de la vista actual que observa el usuario.
     */
    cambiarVista(vistaActual: string) {
        switch (vistaActual) {
            case CONSULTA_VIAJES.VISTA_VISITAS:
                this.setState({
                    viajeSeleccionado: undefined,
                });
            break;
            case CONSULTA_VIAJES.VISTA_CHASIS:
                this.setState({
                    visitaSeleccionada: undefined,
                });
            break;
        }
    }

    validarBotonFinalizado = () => {
        if(this.state.visitaSeleccionada!.estatusEntrega === "FINALIZADO_CONCESIONARIO" 
            && (getUsuarioLogueado()?.rol=== ROLES.INSPECTOR || getUsuarioLogueado()?.rol=== ROLES.ADMINDEALER)){
            return false;
        }
        return true;
    }

    /**
     * Función principal de react para renderizar los componentes html.
     */
    render() {

        const headerCard = <div style={{textAlign:"center"}}><h1>{cadenas.CAPTURA_ONLINE_TITULO}</h1></div>;

        return  (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking}>
                <Growl ref={this.growlError} />
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Card header={headerCard} style={{padding:"1em"}}>
                                <div className="p-grid">
                                    {this.state.viajeSeleccionado === undefined &&
                                        this.crearFormularioBusqueda()
                                    }

                                    <div className="p-col-12">
                                    {this.state.viajeSeleccionado === undefined &&
                                        this.dataTableViajes()
                                    }

                                    {this.state.viajeSeleccionado !== undefined &&
                                    this.state.visitaSeleccionada === undefined &&
                                        this.dataTableVisitas()
                                    }

                                    {this.state.visitaSeleccionada !== undefined &&
                                        this.dataTableChasis()
                                    }
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </BlockUi>
                <Prompt
                when={this.state.isEdited}
                message={`Pudiera haber cambios sin guardar, ¿Desea continuar?`}
            />
            </div>
        );
    }

    /**
     * Crear la ruta que se utiliza para consumir el api de
     * los viajes.
     */
    crearPathViaje() {
        let company = getUsuarioLogueado()?.codigoEmpresa;
        return PATHS_API.DESTINOS+'/'+
          this.state.origen.toUpperCase()+'/'+
          company+'/'
          +this.state.numEconomico;
    }

    /**
     * Crear los objetos de tipo @interface ChasisInterface
     * para ingresarlos en un array que debe tener el json Visita.
     * @param {any} visita Es el json de las visistas que responde el servidor.
     */
    mappingChasisToVisita(visita: any) {
        let chasis: Array<ChasisInterface> = [];
        visita.shipment.forEach((element: any) => {
            chasis.push({
                color: element.color,
                estadoChasis: element.estadoChasis,
                make: element.make,
                model: element.model,
                modelsize: element.modelsize,
                status: element.status,
                vin: element.vin,
                damages: element.damages === undefined ? [] : element.damages,
                missings: element.missings === undefined ? [] : element.missings,
                siniestrado: element.siniestrado === undefined ? false : element.siniestrado,
                opcionSiniestro: element.opcionSiniestro === undefined ? null : element.opcionSiniestro
            });
        });
        return chasis;
    }

    /**
     * Crear los objetos de tipo @interface VisitaInterface
     * para ingresarlos en un array que debe tener el json Viaje.
     * @param {any} response Es el JSOn que devuelve el servidor.
     */
    mappingVisitasToViaje(response :any) {
        let visitas: Array<VisitaInterface> = [];
        response.visits.forEach((element: any) => {
            let chasisVisita = this.mappingChasisToVisita(element);
            visitas.push({
                destination: element.destination,
                estatusEntrega: element.estatusEntrega,
                fechaLlegada: element.fechaLlegada,
                latitud: element.latitud,
                longitud: element.longitud,
                order: element.order,
                shipment: chasisVisita
            });
        });
        this.setState({
            blocking:false,
            viajeSeleccionado: undefined,
            viajes: [{
                driverName: response.driverName,
                carrier: response,
                estatusViaje: response.estatusViaje,
                leftDate: response.leftDate,
                orderId: response.orderID,
                status: response.status,
                totalAmountOfUnits: response.totalAmountOfUnits,
                visits: visitas
            }],
        });
    }

    /**
     * @description Mostrar el componente Growl en la pantalla.
     * @param {string} type Es el tipo de Severities, de acuerdo a
     * {@link https://primefaces.org/primereact/showcase/#/growl}
     * @param {string} title
     * @param {string} message
     */
    showNotificacion(type:string, title:string, message:string) {
        this.growlError.current.show({severity: type, summary: title, detail: message});
    }
    async simularOrderIdIniciodeViaje(response:any){
        console.log('Llegando al metodo de simular orderId');

        this.setState({
            viajeSeleccionado: undefined,
            tipoBusquedaSeleccionado: {label:'', value:CONSULTA_VIAJES.ORDERID_VALUE}
            });
            //console.log("Viajes -> buscarViajes -> this.crearBodyBusqueda()", JSON.stringify(this.crearBodyBusqueda()))
            let body=this.crearBodyBusqueda();
            body.ordersId=response.orderID;
            let n : any = [body.ordersId];
            console.log("Viajes -> buscarViajes -> this.crearBodyBusqueda()", JSON.stringify(body))
            APIGATEWAY.post(PATHS_API.VIAJES_CONSULTA, body )
            .then(response => {
                console.log("Viajes -> buscarViajes -> response", response)
                this.setState({
                    blocking: false,
                    viajes: response.datos,
                    totalElementos: response.totalElementos,
                    pagina: response.pagina*NUM_ROWS_POR_PAGINA, // datatable usa como first, el num del row con cual inicia la pagina seleccionada
                    viajesTemporal: response.datos,
                    numerosFiltro: n,
                });
            })
            .catch(error => {
                this.setState({
                    blocking:false,
                    viajes: [],
                    viajesTemporal: [],
                });
                this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_VIAJES);
            });


    }

}

export default Emergencia;
