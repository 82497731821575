import * as React from 'react';
import {Route, Switch } from 'react-router-dom';
import {ROUTES} from '../Utils/constants';

import LoginContainer from '../Containers/LoginContainer';
import DashboardContainer from '../Containers/Dashboard/DashboardContainer';
import {cadenas} from '../Utils/cadenas';
import PrivateRoute from './PrivateRoute';
import LoginConcesionarios from '../Containers/LoginContainer/LoginConcesionarios';

/**
 * @class
 * Componente para configurar las rutas del sistema.
 */
class AppRouter extends React.Component {

  /**
     * @function
     * Función principal para renderizar el contenido del componente.
     */
  render() {
    /**
     * Componente funcional para generar la página 404.
     */
    const Page404 = () => {
      return (
        <div className="p-grid p-fluid p-align-center">
          <div className="p-col-4"></div>
          <div className="p-col-4">
            <h1>
              {cadenas.PAGINA_404}
            </h1>
          </div>
          <div className="p-col-4"></div>
        </div>
      );
    };

    return (
      // si se agregan rutas al DashboardContainer,
      // es necesario definirlas aqui
      <Switch>
        <Route exact={true} path={ROUTES.START} component={LoginContainer} />
        <Route exact path={ROUTES.LOGIN} component={LoginContainer} />
        <Route exact path={ROUTES.LOGIN + '/concesionario/:email/:state'} component={LoginContainer} />
        <Route exact path={ROUTES.LOGIN_CONCESIONARIOS} component={LoginConcesionarios} />
        <Route exact path={ROUTES.OCTAGON_CALLBACK} component={LoginConcesionarios} />
        <PrivateRoute exact path={ROUTES.DASHBOARD} component={DashboardContainer} />
        {/* catalogos */}
        <PrivateRoute exact path={ROUTES.TRANSPORTISTAS} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.CONCESIONARIOS} component={DashboardContainer} />
        {/* catalogos */}
        <PrivateRoute exact path={ROUTES.VIAJES} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.BITACORA} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.USO_APP} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.ENCURSO} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.ENTREGADOS} component={DashboardContainer} />

        <PrivateRoute exact path={ROUTES.NUEVOCODIGO} component={DashboardContainer} />

        {/* rev */}
        <PrivateRoute exact path={ROUTES.REV_EMERGENCIA} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.REV_CAPTURA_ONLINE} component={DashboardContainer} />
        {/* usuarios */}
        <PrivateRoute exact path={ROUTES.USUARIOS_ADMINSIS} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.USUARIOS_ADMINDEALER} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.USUARIOS_INSPECTOR} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.USUARIOS_ADMINTRANS} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.USUARIOS_CONDUCTOR} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.USUARIOS_ADMINTRANS} component={DashboardContainer} />
        <PrivateRoute exact path={ROUTES.CORREOS} component={DashboardContainer} />
        {/* pagina no encontrada */}
        <Route component={Page404} />
      </Switch>
    );
  }
}

export default AppRouter;
