import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';

import { cadenas } from '../Utils/cadenas';
import { getUsuarioLogueado } from '../Utils/gestionSessionStorage';
import { ROLES } from '../Utils/constants';

/**
 * Definir las propiedades que recibe el componente.
 */
interface Props {
    settearState: (id:string, value:any) => void;
    stateParent: any
}

/**
 * Definir el estado del componente.
 */
interface State {
    [key:string]:any;
}

/**
 * @class
 * Componente que permite generar el formulario de registro 
 * para la pantalla de Usuarios.
 */
class FormularioRegistro extends Component<Props, State> {
    
    /**
     * @function
     * Función principal para renderizar el contenido del componente.
     */
    render() {
        return  (
            <div className="p-fluid">
                {(getUsuarioLogueado()?.rol === ROLES.ADMINUMS) &&
                <div className="p-field p-grid">
                    <label htmlFor="rol" className="p-col-12 p-md-2">{cadenas.ADMINUMS_ROLE}: *</label>
                    <div className="p-col-12 p-md-10">
                        <Dropdown
                            value={this.props.stateParent.rolAdminSysAdminReadSeleccionado} 
                            options={this.props.stateParent.rolAdminSysAdminReadList} 

                            onChange={(e) => {
                                let rolSeleccionado = this.props.stateParent.rolAdminSysAdminReadList.find((ts: { value: any; }) => ts.value === e.value);
                                console.log("rol selccionado: ",rolSeleccionado);
                                this.setState({rolAdminSysAdminReadSeleccionado: rolSeleccionado!})
                                this.props.stateParent.rolAdminSysAdminReadSeleccionado = rolSeleccionado.value;
                                console.log("rolAdminSysAdminReadSeleccionado: ",this.props.stateParent.rolAdminSysAdminReadSeleccionado);
                            }} 
                            required={true}
                            placeholder={cadenas.SELECT_SELECCIONA} 
                        />
                    </div>
               </div> 
               }

                <div className="p-field p-grid">
                    <label htmlFor="nombreCompleto" className="p-col-12 p-md-2">{cadenas.ADMINSIS_DATA_NOMBRE_COMPLETO}: *</label>
                    <div className="p-col-12 p-md-10">
                        <InputText id="nombreCompleto" type="text" required={true} value={this.props.stateParent.nombreCompleto}
                            onChange={(e) => this.props.settearState((e.target as HTMLInputElement).id, (e.target as HTMLInputElement).value)}
                        />
                    </div>
                </div>
                {(getUsuarioLogueado()?.rol !== ROLES.ADMINTRANSPORTISTA && getUsuarioLogueado()?.rol !== ROLES.ADMINDEALER) && 
                <div>
                    <div className="p-field p-grid">
                        <label htmlFor="correo" className="p-col-12 p-md-2">{cadenas.ADMINSIS_DATA_CORREO}: *</label>
                        <div className="p-col-12 p-md-10">
                            <InputText id="correo" type="email" required={true} value={this.props.stateParent.correo}
                                onChange={(e) => {
                                    this.props.settearState((e.target as HTMLInputElement).id, (e.target as HTMLInputElement).value)
                                    if (getUsuarioLogueado()?.rol === ROLES.ADMINUMS ||  getUsuarioLogueado()?.rol === ROLES.ADMINSIS){
                                        this.props.settearState("usuario", (e.target as HTMLInputElement).value)
                                    }
                                }}
                            />
                        </div>
                    </div>  
                    <div className="p-field p-grid">
                        <label htmlFor="usuario" className="p-col-12 p-md-2">{cadenas.ADMINSIS_DATA_USUARIO}: *</label>
                        <div className="p-col-12 p-md-10">
                            <InputText id="usuario" type="text" required={true} value={this.props.stateParent.usuario}
                                disabled={true}
                                onChange={(e) => this.props.settearState((e.target as HTMLInputElement).id, (e.target as HTMLInputElement).value)}
                            />
                        </div>
                    </div>
                </div>
                }

                {(getUsuarioLogueado()?.rol === ROLES.ADMINDEALER) && 
                <div>
                    <div className="p-field p-grid">
                        <label htmlFor="correo" className="p-col-12 p-md-2">{cadenas.ADMINSIS_DATA_CORREO}: *</label>
                        <div className="p-col-12 p-md-10">
                            <InputText id="correo" type="email" required={true} value={this.props.stateParent.correo}
                                onChange={(e) => this.props.settearState((e.target as HTMLInputElement).id, (e.target as HTMLInputElement).value)}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="telefono" className="p-col-12 p-md-2">{cadenas.ADMINSIS_DATA_TELEFONO}: *</label>
                        <div className="p-col-12 p-md-10">
                            <InputMask id="telefono" mask="+999999999999"  required={true} value={this.props.stateParent.telefono}
                                onChange={(e) => this.props.settearState((e.target as HTMLInputElement).id, (e.target as HTMLInputElement).value)}
                            />
                        </div>
                    </div>
                </div>
                }


            </div>
        );
    }
}

export default FormularioRegistro;
