import moment from 'moment';

import {FORMATO_FECHA_HORA, FORMATO_FECHA_ANIO_MES} from '../Utils/constants';

/**
 * Convertir la fecha al formato especificado.
 * @param {Date} date Fecha para convertir.
 * @returns Fecha convertida.
 */
export function convertirDate(date: Date) {
    return moment(date).format(FORMATO_FECHA_HORA);
}

/**
 * Convertir la fecha al formato especificado.
 * @param {Date} date Fecha para convertir.
 * @returns Fecha convertida.
 */
export function convertirDateAnioMEs(date: Date) {
    return moment(date).format(FORMATO_FECHA_ANIO_MES);
}