import Amplify from 'aws-amplify';

import {
  AWS_REGION,
  INT_API_GATEWAY_NAME,
  QAS_API_GATEWAY_NAME,
  PRD_API_GATEWAY_NAME,
  INT_BASE_URL_API,
  QAS_BASE_URL_API,
  PRD_BASE_URL_API
} from '../Utils/constants';

type awsCloudConfig = {
  name: string,
  endpoint: string,
  region: string
}

type awsconfig = {
  aws_project_region: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  aws_cloud_logic_custom: Array<awsCloudConfig>;
  aws_user_files_s3_bucket: string;
  aws_user_files_s3_bucket_region: string;
  aws_cognito_identity_pool_id: string;
}

export const ADMINS: awsconfig = {
  aws_project_region: AWS_REGION,
  aws_cognito_region: AWS_REGION,
  aws_user_pools_id: 'us-west-2_rzFPebrWi',
  aws_user_pools_web_client_id: '2nkon1814tp1dbuih6c84v08a4',
  aws_cloud_logic_custom: [
    {
      name: PRD_API_GATEWAY_NAME,
      endpoint: PRD_BASE_URL_API,
      region: AWS_REGION
    }
  ],
  aws_user_files_s3_bucket: 'int-vwapp',
  aws_user_files_s3_bucket_region: AWS_REGION,
  //aws_cognito_identity_pool_id:'us-west-2:d00a007e-c784-4585-845a-b2ac6f965c9c',
  aws_cognito_identity_pool_id: 'us-west-2:fba07cbf-7318-4f89-813a-d9b5fa166718'
};

const SUB_ADMIN: awsconfig = {
  aws_project_region: AWS_REGION,
  aws_cognito_region: AWS_REGION,
  aws_user_pools_id: 'us-west-2_9nFFiTru1',
  aws_user_pools_web_client_id: '1tqjn94d6pkq1jhni0if21v3f1',
  aws_cloud_logic_custom: [
    {
      name: PRD_API_GATEWAY_NAME,
      endpoint: PRD_BASE_URL_API,
      region: AWS_REGION
    }
  ],
  aws_user_files_s3_bucket: 'int-vwapp',
  aws_user_files_s3_bucket_region: AWS_REGION,
  //aws_cognito_identity_pool_id:'us-west-2:d00a007e-c784-4585-845a-b2ac6f965c9c',
  aws_cognito_identity_pool_id: 'us-west-2:fba07cbf-7318-4f89-813a-d9b5fa166718'
};

type MyMapLikeType = Record<string, awsconfig>;
const CONFIGURACIONES_AWS: MyMapLikeType = {
  'ADMIN_UMS': ADMINS,
  'ADMIN_SISTEMA': ADMINS,
  'ADMIN_READ': ADMINS,
  'ADMIN_TRANSPORTISTA': ADMINS,
  'ADMIN_DEALER': ADMINS,
  'INSPECTOR': SUB_ADMIN,
  'CONDUCTOR': SUB_ADMIN,
  'SEGUROS': ADMINS,
  'ADMIN_NSO': ADMINS
};

type CognitoMap = Record<string, string>;
const ADMIN_COGNITO_POOL: CognitoMap = {
  'INT': 'us-west-2_OZuZJgI2j',
  'QAS': 'us-west-2_rzFPebrWi',
  'PRD': 'us-west-2_MLiRQyGyA',
};

const ADMIN_COGNITO_CLIENT: CognitoMap = {
  'INT': '3qjjsua3nbpu3s6vg83jl5df3e',
  'QAS': '2nkon1814tp1dbuih6c84v08a4',
  'PRD': '5frhgk6acm28vf2t42v4klckop',
};

const SUB_ADMIN_COGNITO_POOL: CognitoMap = {
  'INT': 'us-west-2_1uFDUp7OQ',
  'QAS': 'us-west-2_9nFFiTru1',
  'PRD': 'us-west-2_udwcFg6Pm',
};

const SUB_ADMIN_COGNITO_CLIENT: CognitoMap = {
  'INT': '6atinvsrqqggpr2259l1jlr9ot',
  'QAS': '1tqjn94d6pkq1jhni0if21v3f1',
  'PRD': 'co2jqutcphk0bb3prsj1c8lgm',
};

const ADMIN_IDPOOL: CognitoMap = {
  'INT': 'us-west-2:fba07cbf-7318-4f89-813a-d9b5fa166718',
  'QAS': 'us-west-2:5072e7d8-c932-4f12-86ed-48023b3e33c7',
  'PRD': 'us-west-2:244880d6-d0c2-4be7-b5bb-e0c49c11bb54',
};

const ADMIN_S3: CognitoMap = {
  'INT': 'int-vwapp',
  'QAS': 'qas-vwapp',
  'PRD': 'prd-vwapp',
};

export function configuracionAws(rol: string) {
  let host: string = window.location.hostname;

  if (host === 'localhost' || host === 'int-web.appslogisticavolkswagen.com.mx') {
    //console.log = () => {};
    console.log('Hostname : [' + host + ']');
    console.log('resolved environment INT');
    ADMINS.aws_user_pools_id = ADMIN_COGNITO_POOL['INT'];
    ADMINS.aws_user_pools_web_client_id = ADMIN_COGNITO_CLIENT['INT'];
    ADMINS.aws_cloud_logic_custom[0].name = INT_API_GATEWAY_NAME;
    ADMINS.aws_cloud_logic_custom[0].endpoint = INT_BASE_URL_API;
    ADMINS.aws_cognito_identity_pool_id = ADMIN_IDPOOL['INT'];
    ADMINS.aws_user_files_s3_bucket = ADMIN_S3['INT'];

    SUB_ADMIN.aws_user_pools_id = SUB_ADMIN_COGNITO_POOL['INT'];
    SUB_ADMIN.aws_user_pools_web_client_id = SUB_ADMIN_COGNITO_CLIENT['INT'];
    SUB_ADMIN.aws_cloud_logic_custom[0].name = INT_API_GATEWAY_NAME;
    SUB_ADMIN.aws_cloud_logic_custom[0].endpoint = INT_BASE_URL_API;
    SUB_ADMIN.aws_cognito_identity_pool_id = ADMIN_IDPOOL['INT'];
    SUB_ADMIN.aws_user_files_s3_bucket = ADMIN_S3['INT'];

  } else if (host === 'qas-web.appslogisticavolkswagen.com.mx') {
    //console.log = () => {};
    console.log('Hostname : [' + host + ']');
    console.log('resolved environment QAS');
    ADMINS.aws_user_pools_id = ADMIN_COGNITO_POOL['QAS'];
    ADMINS.aws_user_pools_web_client_id = ADMIN_COGNITO_CLIENT['QAS'];
    ADMINS.aws_cloud_logic_custom[0].name = QAS_API_GATEWAY_NAME;
    ADMINS.aws_cloud_logic_custom[0].endpoint = QAS_BASE_URL_API;
    ADMINS.aws_cognito_identity_pool_id = ADMIN_IDPOOL['QAS'];
    ADMINS.aws_user_files_s3_bucket = ADMIN_S3['QAS'];

    SUB_ADMIN.aws_user_pools_id = SUB_ADMIN_COGNITO_POOL['QAS'];
    SUB_ADMIN.aws_user_pools_web_client_id = SUB_ADMIN_COGNITO_CLIENT['QAS'];
    SUB_ADMIN.aws_cloud_logic_custom[0].name = QAS_API_GATEWAY_NAME;
    SUB_ADMIN.aws_cloud_logic_custom[0].endpoint = QAS_BASE_URL_API;
    SUB_ADMIN.aws_cognito_identity_pool_id = ADMIN_IDPOOL['QAS'];
    SUB_ADMIN.aws_user_files_s3_bucket = ADMIN_S3['QAS'];
  } else if (host === 'web.appslogisticavolkswagen.com.mx' || host === 'web.appslogisticavolkswagen.com.mx.s3-website-us-west-2.amazonaws.com') {
    console.log = () => {};
    console.log('Hostname : [' + host + ']');
    console.log('resolved environment PRD');
    ADMINS.aws_user_pools_id = ADMIN_COGNITO_POOL['PRD'];
    ADMINS.aws_user_pools_web_client_id = ADMIN_COGNITO_CLIENT['PRD'];
    ADMINS.aws_cloud_logic_custom[0].name = PRD_API_GATEWAY_NAME;
    ADMINS.aws_cloud_logic_custom[0].endpoint = PRD_BASE_URL_API;
    ADMINS.aws_cognito_identity_pool_id = ADMIN_IDPOOL['PRD'];
    ADMINS.aws_user_files_s3_bucket = ADMIN_S3['PRD'];

    SUB_ADMIN.aws_user_pools_id = SUB_ADMIN_COGNITO_POOL['PRD'];
    SUB_ADMIN.aws_user_pools_web_client_id = SUB_ADMIN_COGNITO_CLIENT['PRD'];
    SUB_ADMIN.aws_cloud_logic_custom[0].name = PRD_API_GATEWAY_NAME;
    SUB_ADMIN.aws_cloud_logic_custom[0].endpoint = PRD_BASE_URL_API;
    SUB_ADMIN.aws_cognito_identity_pool_id = ADMIN_IDPOOL['PRD'];
    SUB_ADMIN.aws_user_files_s3_bucket = ADMIN_S3['PRD'];
  } else {
    console.log('UNRESOLVED ENVIRONMENT!!!!');
  }

  Amplify.configure(CONFIGURACIONES_AWS[rol]);
}
