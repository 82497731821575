import React, {Component, createRef} from 'react';
import {Dropdown} from 'primereact/dropdown';
import BlockUi from 'react-block-ui';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
// import {Column} from 'primereact/column';
import {Card} from 'primereact/card';
import {Growl} from 'primereact/growl';
import {Calendar} from 'primereact/calendar';
import {Column} from 'primereact/column';
/*import {Dialog} from 'primereact/dialog';
import {TabView,TabPanel} from 'primereact/tabview';
import {DataView, DataViewLayoutOptions} from 'primereact/dataview';*/

import {CONSULTA_USOAPP, CALENDAR_LOCALE, 
    ROLES, NUM_ROWS_POR_PAGINA,
    PATHS_API} from '../../Utils/constants';
import {cadenas} from '../../Utils/cadenas';
import {getUsuarioLogueado } from '../../Utils/gestionSessionStorage';
import { convertirDateAnioMEs} from '../../Utils/fechas';
import {ItemDropDownInterface} from '../../Interfaces/ItemDropDown.interface';
import {APIGATEWAY} from '../../Api/ApiGateway';
import { RANGO_ANIOS } from '../../Utils/constants';
import { BitacoraInterface } from '../../Interfaces/Bitacora.interface';

/**
 * @interface
 * Se define el estado del componente.
 */
interface State {
    tiposBusqueda: Array<ItemDropDownInterface>;
    tipoBusquedaSeleccionado: ItemDropDownInterface;
    numerosFiltro: [];
    blocking: boolean;
    fechaBusquedaInicio: Date|Date[]|undefined;
    fechaBusquedaFin: Date|Date[]|undefined;
    bitacoras: Array<BitacoraInterface>;
    bitacoraSeleccionada: BitacoraInterface | undefined;
    pagina: number;
    tamanioPagina: number;
    totalElementos: number;
    dialogInfoVisible: boolean;
    indexActivo: number | undefined;
}

/**
 * @class
 * Componentes para realizar consultas de la bitácora.
 */
class UsoApp extends Component<{}, State> {
    private dataTableRefBitacora: any;
    private growlError: any;

    /**
     * @constructor
     * @param {any} props Son las propiedades del componente-
     */
    constructor(props:any) {
        super(props);
        this.dataTableRefBitacora = createRef(); 
        this.growlError = createRef();
        this.state = {
            bitacoras: [],
            bitacoraSeleccionada: undefined,
            tiposBusqueda: [],
            tipoBusquedaSeleccionado: {label:'', value:''},
            numerosFiltro: [],
            blocking:false,
            fechaBusquedaInicio: new Date(),
            fechaBusquedaFin: new Date(),
            pagina: 0,
            tamanioPagina: 0,
            totalElementos: 0,
            dialogInfoVisible: false,
            indexActivo: undefined,
        }
        this.export = this.export.bind(this);
        this.buscarBitacora = this.buscarBitacora.bind(this);
        this.crearFormularioBusqueda = this.crearFormularioBusqueda.bind(this);
        this.crearFechasBusqueda = this.crearFechasBusqueda.bind(this);
        //this.cambiarVista = this.cambiarVista.bind(this);
        this.dataTableBitacora = this.dataTableBitacora.bind(this);
        this.organizarOpcionesPorRol = this.organizarOpcionesPorRol.bind(this);
        //this.obtenerPdf = this.obtenerPdf.bind(this);
        this.cargarPagina = this.cargarPagina.bind(this);
        this.resetTable = this.resetTable.bind(this);      
        this.onModelFilterChange=this.onModelFilterChange.bind(this);
        this.generarReporteFull=this.generarReporteFull.bind(this);
    }

    /**
     * Evento que se ejecuta antes de renderizar el componente.
     */
    componentDidMount() {
        this.organizarOpcionesPorRol();
    }

    /**
     * Organizar las opciones de búsqueda de acuerdo al perfil logueado.
     */
    organizarOpcionesPorRol() {
        switch (getUsuarioLogueado()?.rol) {
            case ROLES.ADMINUMS:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_BITACORA_FECHA, value: CONSULTA_USOAPP.RANGO_FECHA_VALUE}
                    ]
                }); 
                break;
            case ROLES.ADMINSIS:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_BITACORA_FECHA, value: CONSULTA_USOAPP.RANGO_FECHA_VALUE}
                    ]
                }); 
                break;
            case ROLES.ADMINREAD:
                    this.setState({
                        tiposBusqueda: [
                            {label: cadenas.CONSULTA_BITACORA_FECHA, value: CONSULTA_USOAPP.RANGO_FECHA_VALUE}
                        ]
                    }); 
                    break;
            case ROLES.ADMINDEALER:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_BITACORA_FECHA, value: CONSULTA_USOAPP.RANGO_FECHA_VALUE}
                    ]
                }); 
                break;
            case ROLES.ADMINTRANSPORTISTA:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_BITACORA_FECHA, value: CONSULTA_USOAPP.RANGO_FECHA_VALUE}
                    ]
                }); 
                
                break;
            case ROLES.INSPECTOR:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_BITACORA_FECHA, value: CONSULTA_USOAPP.RANGO_FECHA_VALUE}
                    ]
                }); 
                break;
        }
    }

    /**
     * Exportar los datos del datatable a formato CSV.
     * @param {any} dataTable Referencia del datatable.
     */
    export(dataTable: any) {
        dataTable.current.exportCSV();
    }
    /**
     * resetear los datos del datatable.
     * @param {any} dataTable Referencia del datatable.
     */
    resetTable(dataTable: any) {
        dataTable.current.reset();
        console.log('Se reseteo la tabla');
    }

    /**
     * Mostrar notificación de error.
     * @param {string} title Título de la notificación. 
     * @param {string} message Mensaje de la notificación.
     */
    showError(title:string, message:string) {
        this.growlError.current.show({severity: 'error', summary: title, detail: message});
    }

    /**
     * Definir las opciones para realizar la búsqueda de la bitácora.
     * @param {any} body Objeto que se envia al servidor.
     */
    definirOpcionesBusqueda(body: any) {
        switch (this.state.tipoBusquedaSeleccionado.value) {
            case CONSULTA_USOAPP.RANGO_FECHA_VALUE:
                body.fechaBusquedaInicio = convertirDateAnioMEs(this.state.fechaBusquedaInicio as Date);
                body.fechaBusquedaFin = convertirDateAnioMEs(this.state.fechaBusquedaFin as Date);
                console.log(body.fechaBusqueda);
                break;
        }
        return body;
    }

    /**
     * Crear el objeto body que se envia al servidor
     * para realizar la búsqueda de la bitácora.
     */
    crearBodyBusqueda() {
        let body = {
            fechaBusquedaInicio: '',
            fechaBusquedaFin: '',
            pagina: 0,
            tamanioPagina: NUM_ROWS_POR_PAGINA,
            filtrosTabla:[]
        };
        body = this.definirOpcionesBusqueda(body);
        return body;
    }

    /**
     * Buscar registros de la bitácora de operaciones de usuario con párametros de búsqueda.
     * @param {React.FormEvent} event Evento del formulario.
     */
    buscarBitacora(event:React.FormEvent) {
        event.preventDefault();
        // validacion
        if (this.state.tipoBusquedaSeleccionado.value !== CONSULTA_USOAPP.RANGO_FECHA_VALUE && this.state.numerosFiltro.length === 0) {
            this.showError(cadenas.ERROR_TITULO_VALIDACION, cadenas.CONSULTA_BITACORA_ERROR_VALIDACION_NUMS+this.state.tipoBusquedaSeleccionado.label);
            return;
        }
        this.setState({
            blocking: true
        });
        console.log("Bitacora -> buscarRegistros -> this.crearBodyBusqueda()", JSON.stringify(this.crearBodyBusqueda()))
        APIGATEWAY.post(PATHS_API.USOAPP_CONSULTA, this.crearBodyBusqueda() )
        .then(response => {
            console.log("Bitacora -> buscarRegistros -> response", response)
            
            this.setState({
                blocking: false,
                bitacoras: response.datos,
                totalElementos: response.totalElementos,
                pagina: response.pagina*NUM_ROWS_POR_PAGINA // datatable usa como first, el num del row con cual inicia la pagina seleccionada
               
            });            
        })
        .catch(error => {
            console.log("Bitacora -> buscarRegistros -> error: ", error)
            this.setState({
                blocking:false,
                bitacoras: []
                
            });
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_BITACORA_BUSQUEDA_ERROR_BITACORA);
        });
    }
    /**
     * Cargar los registros de la página seleccionada en el datatable.
     * @param {any} event Evento de la página seleccionada.
     */
    cargarPagina(event: any) {
        this.setState({
            blocking: true
        });
        const body = this.crearBodyBusqueda();
        body.pagina = event.page;        
        console.log("Bitacora -> cargarPagina -> event", event)
        console.log("Bitacora -> cargarPagina -> body", JSON.stringify(body))
        APIGATEWAY.post(PATHS_API.USOAPP_CONSULTA, body )
        .then(response => {
            console.log("Bitacora -> cargarPagina -> response", response)
            this.setState({
                blocking: false,
                pagina: response.pagina*NUM_ROWS_POR_PAGINA, // datatable usa como first, el num del row con cual inicia la pagina seleccionada
                bitacoras: response.datos
            });
        })
        .catch(error => {
            this.setState({
                blocking:false,
                bitacoras: []
            });
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_BITACORA_BUSQUEDA_ERROR_BITACORA);
        });
    }

    /**
     * Creación de los campos tipo fecha para realizar la búsqueda.
     */
    crearFechasBusqueda() {
        return (
        <div>
            <div className="p-field">
                <label htmlFor="fechaInicio">{cadenas.CONSULTA_USOAPP_FORM_FECHAINICIO_MES_ANIO}: *</label>
                <Calendar id="fechaInicio" value={this.state.fechaBusquedaInicio} 
                    locale={CALENDAR_LOCALE}
                    onChange={(e) => this.setState({fechaBusquedaInicio: e.value})}
                    required={true} showTime={false} 
                    monthNavigator={true} yearNavigator={true} yearRange={RANGO_ANIOS}
                    view="month" dateFormat="mm/yy"
                />
            </div> 
            <div className="p-field">
                <label htmlFor="fechaFin">{cadenas.CONSULTA_USOAPP_FORM_FECHAFIN_MES_ANIO}: *</label>
                <Calendar id="fechaFin" value={this.state.fechaBusquedaFin} 
                    locale={CALENDAR_LOCALE}
                    onChange={(e) => this.setState({fechaBusquedaFin: e.value})}
                    required={true} showTime={false} 
                    monthNavigator={true} yearNavigator={true} yearRange={RANGO_ANIOS}
                    view="month" dateFormat="mm/yy"
                />
            </div>             
            {}
        </div>
        );
    }

    /**
     * Creación del formulario para realizar la búsqueda de registros de búsuqeda.
     */
    crearFormularioBusqueda() {
        return (
        <form onSubmit={this.buscarBitacora}>
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="tipoBusquedaSeleccionado">{cadenas.CONSULTA_BITACORA_FORM_BUSCAR}: *</label>
                    <Dropdown 
                        value={this.state.tipoBusquedaSeleccionado.value} options={this.state.tiposBusqueda} 
                        onChange={(e) => {
                            let tipoSeleccionado = this.state.tiposBusqueda.find(tb => tb.value === e.value);
                            this.setState({tipoBusquedaSeleccionado: tipoSeleccionado!})
                        }} required={true}
                        placeholder={cadenas.SELECT_SELECCIONA} 
                    />
                </div>
                {this.state.tipoBusquedaSeleccionado.value === CONSULTA_USOAPP.RANGO_FECHA_VALUE
                    &&
                    this.crearFechasBusqueda()
                }
            </div>
            <Button label={cadenas.CONSULTA_BITACORA_BTN_BUSCAR} 
                className="p-button p-button-success p-button-rounded"
                icon="pi pi-search" 
                iconPos="left"
            />
        </form>
        );
    }

    /**
     * @returns Datatable para mostrar el resultado de la búsqueda.
     */
    dataTableBitacora() {
        const headerDatatable = <div className="p-grid">
            <div className="p-col-6" style={{textAlign:"left"}} >
                <h2>{cadenas.CAPTURA_ONLINE_TITULO_DATATABLE_USOAPP}</h2>
            </div>
            <div className="p-col-6" style={{textAlign:"right"}} >
                <Button type="button" icon="pi pi-external-link" iconPos="left" style={{marginRight: '.5em'}} 
                    label={cadenas.BTN_EXPORTAR_CSV} onClick={() => this.export(this.dataTableRefBitacora)} 
                />    
            </div>           
        </div>;
        
        return (
            <div className="p-col-12">
                <DataTable value={this.state.bitacoras}  paginator={true} 
                    rows={NUM_ROWS_POR_PAGINA}                                    
                    header={headerDatatable}  responsive={true}
                    ref={this.dataTableRefBitacora}                   
                    emptyMessage={cadenas.DATATABLE_VACIO}
                    selection={this.state.bitacoraSeleccionada} 
                   // onSelectionChange={e => this.buscarVisistasPorViaje(e.value)}
                    lazy={false}                                                       
                >
                    <Column field="orderId" header={cadenas.CAPTURA_ONLINE_USOAPP_DATA_ORDENAMIENTO} filter={true} sortable={true} />
                    <Column field="concesionario" header={cadenas.CAPTURA_ONLINE_USOAPP_DATA_CONCESIONARIO} filter={true} sortable={true} />
                    <Column field="principal" header={cadenas.CAPTURA_ONLINE_USOAPP_DATA_PRINCIPAL} filter={true} sortable={true} />
                    <Column field="rol" header={cadenas.CAPTURA_ONLINE_USOAPP_DATA_TIPOUSUARIO} filter={true} sortable={true} />
                    <Column field="fecha"
                        body={(rowData:any, column:any) => 
                        this.regresarFechaLocal(rowData)
                        } header={cadenas.CAPTURA_ONLINE_USOAPP_DATA_FECHAREGISTRO} filter={true} sortable={true} sortField="longDate"/>
                    <Column field="operacion" header={cadenas.CAPTURA_ONLINE_USOAPP_DATA_OPERACION} filter={true} sortable={true} />
                    
                </DataTable>
            </div>
        );
       
    }

    regresarFechaLocal(rowData:any){
        let fechaLocal = new Date(rowData.longDate).toLocaleString("es-MX",{year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', timeZone:'America/Mexico_City'})
        rowData.fecha = fechaLocal;
        return fechaLocal;
    }

    /**
     * Cargar los registros de la página seleccionada en el datatable.
     * @param {any} event Evento de la página seleccionada.
     */
    generarReporteFull(df: any) {
        this.setState({
            blocking: true
        });
        const body = this.crearBodyBusqueda();
        console.log("Bitacora -> ConsultaDF -> body", JSON.stringify(body))
        APIGATEWAY.post(PATHS_API.REPORTE_FULL_MENSUAL, body )
        .then(response => {
            console.log("Bitacora -> cargarPagina -> response", response)
            const link = document.createElement('a');
            link.href = response.urlReporte;
            //link.setAttribute('download', 'reporteEntrega.pdf');
            // 3. Append to html page
            //document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            //document.body.removeChild(link);
            this.setState({
                blocking: false,
               
            });
            console.log("Se descargo csv", response)
        })
        .catch(error => {
            this.setState({
                blocking:false                
            });
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_BITACORA_BUSQUEDA_ERROR_BITACORA);
        });
    } 

    onModelFilterChange(event:any) {
        console.log("On filtros load event",event);
        this.setState({
            blocking: true
        });
        const body = this.crearBodyBusqueda();
        body.filtrosTabla = event.filters;        
        console.log("Bitacora -> cargarPagina -> event", event)
        console.log("Bitacora -> cargarPagina -> body", JSON.stringify(body))
        APIGATEWAY.post(PATHS_API.USOAPP_CONSULTA, body )
        .then(response => {
            console.log("Bitacora -> cargarPagina -> response", response)
            this.setState({
                blocking: false,
                //pagina: response.pagina*NUM_ROWS_POR_PAGINA, // datatable usa como first, el num del row con cual inicia la pagina seleccionada
                bitacoras: response.datos
            });
        })
        .catch(error => {
            this.setState({
                blocking:false,
                bitacoras: []
            });
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_BITACORA_BUSQUEDA_ERROR_BITACORA);
        });
        //implementation goes here
    }

    /**
     * Función principal de react para renderizar los componentes html.
     */
    render() {
        const headerCard = <div style={{textAlign:"center"}}><h1>{cadenas.CONSULTA_USOAPP_VISTA_TITUTLO}</h1></div>;
        console.log("inicia render");
       
        return  (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking}>
                <Growl ref={this.growlError} />
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Card header={headerCard} style={{padding:"1em"}}>
                                <div className="p-grid">
                                    {this.state.bitacoraSeleccionada === undefined &&
                                        this.crearFormularioBusqueda()
                                    }

                                    <div className="p-col-12">
                                        {this.state.bitacoraSeleccionada === undefined && 
                                            this.dataTableBitacora()  
                                        }
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default UsoApp;
