/**
 * Este módulo encapsula las funciones CRUD del Api Gateway.
 * @module APIGATEWAY
 */
import {
  TOKEN_OCT, BASE_URL_OCT, GRP_TOKEN_OCT,
  NOMBRE_APP_OCT, AUTH_OCT, IDCONTROL, AUTH_USER_TOKEN_KEY, 
} from '../Utils/constants';
import { Auth, API } from 'aws-amplify';
import { ORIGINAL_PATH_OCT } from '../Utils/constants';
import { ADMINS } from '../Utils/AWSExports';

/**
 * @async
 * @function configHeaders
 * @description Configuración de las cabeceras que son enviadas
 * al servidor para autenticarse en cada petición.
 * @return {Object}  Contiene los headers y el tokekn de acceso.
 */
async function configHeaders() {
  //let idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  let idToken = sessionStorage.getItem(AUTH_USER_TOKEN_KEY);
  console.log("idToken", idToken);
  if(idToken === null){
    idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  }
  console.log("idToken final", idToken);
  let config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      Authorization: idToken
    },
    body: {}
  };
  return config;
}

async function configHeadersPDF() {
  let idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  let config = {
    headers: {
      'Accept': 'application/pdf',
      'Content-Type': 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Authorization: idToken
    },
    body: {}
  };
  return config;
}

/**
 * @constant
 * Se definen los métodos CRUD para consumir las apis
 * de acuerdo al método HTTP.
 */
export const APIGATEWAY = {
  get: async function(path: string) {
    let config = await configHeaders();
    return API.get(ADMINS.aws_cloud_logic_custom[0].name, path, config);
    // return API.get(API_GATEWAY_NAME, path, config);
  },
  post: async function(path: string, body: {}) {
    console.log("path", path);
    console.log("body", body);
    let config = await configHeaders();
    console.log("config", config);
    config.body = body;
    return API.post(ADMINS.aws_cloud_logic_custom[0].name, path, config);
    // return API.post(API_GATEWAY_NAME, path, config);
  },
  put: async function(path: string, body: {}) {
    let config = await configHeaders();
    config.body = body;
    return API.put(ADMINS.aws_cloud_logic_custom[0].name, path, config);
    // return API.put(API_GATEWAY_NAME, path, config);
  },
  delete: async function(path: string, body: {}) {
    let config = await configHeaders();
    config.body = body;
    return API.del(ADMINS.aws_cloud_logic_custom[0].name, path, config);
    // return API.del(API_GATEWAY_NAME, path, config);
  }
};
/**
 * Definición del APIGATEWAY para generar un documento pdf.
 */
export const APIGATEWAYPDF = {
  get: async function(path: string) {
    let config = await configHeadersPDF();
    return await API.get(ADMINS.aws_cloud_logic_custom[0].name, path, config);
    // return await API.get(API_GATEWAY_NAME, path, config);
  },
  post: async function(path: string, body: {}) {
    let config = await configHeadersPDF();
    config.body = body;
    return await API.post(ADMINS.aws_cloud_logic_custom[0].name, path, config);
    // return await API.post(API_GATEWAY_NAME, path, config);
  },
  getFetch: async function(path: string) {
    let idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    const obj = {
      method: 'GET',
      headers: {
        'Accept': 'application/pdf',
        'Content-Type': 'application/json',
        Authorization: idToken
      }
    };

    //return await API.post(API_GATEWAY_NAME, path, config);
    return await fetch(ADMINS.aws_cloud_logic_custom[0].endpoint + path, obj);
    // return await fetch(BASE_URL_API + path, obj);
  }
};

export const OCTAGON = {
  getFetch: async function(path: string) {
    const obj = {
      method: 'GET',
      headers: {
        Authorization: TOKEN_OCT,
        grp_token: GRP_TOKEN_OCT,
        App: NOMBRE_APP_OCT,
        Auth: AUTH_OCT,
        originalpath: ORIGINAL_PATH_OCT
      }
    };
    console.log('obj', JSON.stringify(obj));
    return await fetch(BASE_URL_OCT + path, obj);
  }
};

export const OCTAGON_SESSION = {
  getFetch: async function(path: string, octEmail: string, octState: string) {
    let formData = new FormData();
    formData.append('email', octEmail);
    formData.append('state', octState);
    const obj = {
      method: 'POST',
      headers: {
        // App: NOMBRE_APP_OCT,
        // email: octEmail,
        // state: octState,
        // grp_token: GRP_TOKEN_OCT,
        Authorization: TOKEN_OCT,
        // idcontrol: IDCONTROL
      },
      body: formData
    }
    console.log('obj', JSON.stringify(obj));
    return await fetch(BASE_URL_OCT + path, obj);
  }
};
