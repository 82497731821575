import React, { Component } from 'react';
import { Card}  from 'primereact/card';
import { Button } from 'primereact/button';

import logoVwm from '../../Themes/assets/images/logo-vwm.png';
import { cadenas } from '../../Utils/cadenas';
import { OCTAGON, OCTAGON_SESSION } from '../../Api/ApiGateway';
import { ROUTES, ROLES, PATHS_API } from '../../Utils/constants';
import History from '../../Navigation/History';
import { configuracionAws } from '../../Utils/AWSExports';
import { setUsuarioLogueado, setTokenUsuario, setTokenOctagonUsuario } from '../../Utils/gestionSessionStorage';
import { APIGATEWAY } from '../../Api/ApiGateway';

class LoginConcesionarios extends Component {

    login() {
        console.log("login");
        OCTAGON.getFetch("/usuarios/opciones/"+1)
        .then(response => {
            console.log("LoginConcesionarios -> login -> response", response);
            console.log('Redirigir a '  + response.headers.get('redireccion'));

            let redirect = response.headers.get('redireccion');

            if (redirect != null) {
                window.location.href = redirect;
            }

        })
        .catch(error => {
            console.log("LoginConcesionarios -> login -> error", error)
        });
    }

    async obtenerInfoUsuario(usuario:string) {
        console.log("entro a obtenerInfoUsuario", usuario);
        const body = {
          usuario: usuario
        };
        APIGATEWAY.post(PATHS_API.USUARIOS_API+"/leer", body)
        .then(response => {
          console.log("response", response);
            if (Array.isArray(response)) {
              setUsuarioLogueado(response[0]);
              History.replace(ROUTES.DASHBOARD);
            } else {
              //this.setState({blocking:false});
              //this.showError(cadenas.LOGIN_ERROR_TITULO_SESION, cadenas.LOGIN_ERROR_NO_CARGAR_DATOS);
            }
        })
        .catch(error => {
            console.log("error obtenerInfoUsuario", error)
          //this.setState({blocking:false});
          //this.showError(cadenas.LOGIN_ERROR_TITULO_SESION, cadenas.LOGIN_ERROR_DATOS_INCORRECTOS);
        });
      }

    render() {
        // let email =  (this.props as any).match.params.email;
        // let state =  (this.props as any).match.params.state;

        const search = window.location.search;
        const params = new URLSearchParams(search);
        let email = params.get('email');
        let state = params.get('state');

        if (email && state) {
            console.log('Octagon callback');

            // TODO: Ir a api octagonauth para recuperar sesion del usuario
            OCTAGON_SESSION.getFetch('/sesiongrp/obtener', email, state)
            .then(response => response.json())
            .then(data => {
                console.log("data", data);
                //let jsonResponse = await response.json();
                let username = data.data.email;
                console.log("username", username);
                let token = data.data.token;
                console.log("token", token);
                let accessToken = data.data.accessToken;
                console.log("accessToken", accessToken);
                let usuario = data.data.user;
                console.log("usuario", JSON.stringify(usuario));

                configuracionAws(ROLES.ADMINTRANSPORTISTA);
                setTokenOctagonUsuario(token);
                this.obtenerInfoUsuario(username);
            })
            .catch(error => {
                console.log('error ' + error);
            });
              /*.then(async(response) => {
                  console.log('response ' + response);
                  console.log('response', JSON.stringify(response))
                  //var res = JSON.parse(response);
                  //console.log("res.data", res.data);
                  let jsonResponse = await response.json();
                  let username = jsonResponse[0].data.email;
                  console.log("username", username);
                  let token = jsonResponse[0].data.token;
                  console.log("token", token);
                  let accessToken = jsonResponse[0].data.accessToken;
                  console.log("accessToken", accessToken);
                  let usuario = jsonResponse[0].data.user;
                  console.log("usuario", JSON.stringify(usuario));

                  configuracionAws(ROLES.ADMINTRANSPORTISTA);
                  setTokenUsuario(token);
                  this.obtenerInfoUsuario(username);
                  

                  // TODO
                  // TODO 0. ¿Es un usuario que AUN NO Existe en USUARIOS?
                  //    Guardar información en Usuarios
                  // TODO 0.5 Recuperar informacion de ROL y PERMISOS
                  // TODO 1. Ir a dashboard
                  // TODO 2. Recuperar y Guardar el Token JWT (que se empleará en llamadas al API Gateway)
              })
              .catch(error => {
                  console.log('error ' + error);
              });*/


            // History.replace(ROUTES.LOGIN+'/concesionario/'+email+'/'+state);
            return (
              <div>
                  <Card header='Bienvenido'>
                      <div className="p-col-12" style={{ textAlign: 'right' }}>
                      </div>
                  </Card>
              </div>
            );

        } else {
            console.log('Email = ' + email + ' , State = ' + state);

            const estiloContainer = { height: '90vh', overflow: 'auto' };
            const header = <div style={{ textAlign: "center" }}><img src={logoVwm} alt="VWM" className="imgVwmLogin"/>
            </div>;
            return (
              <div>
                  <div className="p-grid p-fluid p-align-center" style={estiloContainer}>
                      <div className="p-col-4"></div>
                      <div className="p-col-4">
                          <div className="p-col-12">
                              <Card header={header}>
                                  <div className="p-col-12" style={{ textAlign: 'right' }}>
                                      <Button label={cadenas.LOGIN_INICIAR_CONCE}
                                              className="p-button p-button-rounded p-button-primary"
                                              onClick={this.login}/>
                                  </div>
                              </Card>
                          </div>
                      </div>
                      <div className="p-col-4"></div>
                  </div>
              </div>
            );
        }
    }
}

export default LoginConcesionarios;
